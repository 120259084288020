// src/components/UnassignedParcelsModal.jsx

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Checkbox,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { getUnassignedParcels, allPercels } from '../../../Apis/parcel';
import { getAllActiveRoutes, addParcelToRoute } from '../../../Apis/routes';

const UnassignedParcelsModal = ({ open, onClose, onAssignParcels }) => {
  const [parcels, setParcels] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedParcels, setSelectedParcels] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState('');
  const [filter, setFilter] = useState({
    status: '',
  });

  const getParcelStatusLabel = (status) => {
    switch (status) {
      case 'toCollect':
        return 'Listo para Recoger';
      case 'collecting':
        return 'En Ruta para Recoger';
      case 'inWarehouse':
        return 'En Almacén';
      case 'toDeliver':
        return 'Listo para Entregar';
      case 'delivering':
        return 'En Ruta para Entregar';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return 'Desconocido';
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
    setPage(1);
  };

  const fetchParcels = async (page = 1) => {
    setLoading(true);
    try {
      let response;
      if (filter.status) {
        response = await allPercels({ status: filter.status }, page);
      } else {
        response = await getUnassignedParcels(page);
      }

      setParcels(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching parcels:', error);
      setParcels([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  const fetchActiveRoutes = async () => {
    try {
      const response = await getAllActiveRoutes();
      const responseData = response.data;
      if (responseData?.success && Array.isArray(responseData.data)) {
        setRoutes(responseData.data);
      } else {
        setRoutes([]);
      }
    } catch (error) {
      console.error('Error fetching active routes:', error);
      setRoutes([]);
    }
  };

  useEffect(() => {
    if (open) {
      fetchParcels(page);
      fetchActiveRoutes();
    }
  }, [open, page, filter]);

  const handleParcelSelection = (parcelId) => {
    setSelectedParcels((prev) =>
      prev.includes(parcelId)
        ? prev.filter((id) => id !== parcelId)
        : [...prev, parcelId]
    );
  };

  const handleAssign = async () => {
    if (!selectedRoute) {
      alert('Por favor, seleccione una ruta para asignar los paquetes.');
      return;
    }

    if (selectedParcels.length === 0) {
      alert('Por favor, seleccione al menos un paquete para asignar.');
      return;
    }

    try {
      const assignedParcels = [];

      for (const parcelId of selectedParcels) {
        const response = await addParcelToRoute(selectedRoute, parcelId);
        console.log('Respuesta de addParcelToRoute:', response);
        if (response.data.success && response.data.data) {
          assignedParcels.push(response.data.data); // Asegúrate de que response.data.data tiene todos los campos necesarios
        }
      }

      // Log de depuración
      console.log('Paquetes asignados:', assignedParcels);
      console.log('Ruta seleccionada:', selectedRoute);

      // Pasar el ID de la ruta y los paquetes asignados al componente padre
      onAssignParcels(selectedRoute, assignedParcels);
      setSelectedParcels([]);
      setSelectedRoute('');
      onClose();
    } catch (error) {
      console.error('Error assigning parcels to the route:', error);
      alert('Ocurrió un error al asignar los paquetes. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Asignar Paquetes a Ruta</DialogTitle>
      <DialogContent>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Filtro por estado de paquete */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="parcel-status-label">Estado del Paquete</InputLabel>
              <Select
                labelId="parcel-status-label"
                name="status"
                value={filter.status}
                onChange={handleFilterChange}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value="toCollect">Listo para Recoger</MenuItem>
                <MenuItem value="collecting">En Ruta para Recoger</MenuItem>
                <MenuItem value="inWarehouse">En Almacén</MenuItem>
                <MenuItem value="toDeliver">Listo para Entregar</MenuItem>
                <MenuItem value="delivering">En Ruta para Entregar</MenuItem>
                <MenuItem value="delivered">Entregado</MenuItem>
                <MenuItem value="cancelled">Cancelado</MenuItem>
              </Select>
            </FormControl>

            {/* Selección de ruta */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Seleccione Ruta</InputLabel>
              <Select
                value={selectedRoute}
                onChange={(e) => setSelectedRoute(e.target.value)}
                fullWidth
              >
                {routes.map((route) => (
                  <MenuItem key={route._id} value={route._id}>
                    {route.name || `Ruta ${route.routeID}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Tabla de paquetes */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Seleccionar</TableCell>
                  <TableCell>ID del Paquete</TableCell>
                  <TableCell>Dirección de Recogida</TableCell>
                  <TableCell>Dirección de Entrega</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parcels.map((parcel) => (
                  <TableRow key={parcel._id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedParcels.includes(parcel._id)}
                        onChange={() => handleParcelSelection(parcel._id)}
                      />
                    </TableCell>
                    <TableCell>{parcel._id}</TableCell>
                    <TableCell>{parcel.pickup_address}</TableCell>
                    <TableCell>{parcel.dropoff_address}</TableCell>
                    <TableCell>{getParcelStatusLabel(parcel.status)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Paginación */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(e, value) => setPage(value)}
                color="primary"
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleAssign} color="primary" variant="contained">
          Asignar Paquetes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnassignedParcelsModal;
