// src/components/Modals/CreateShipment/CashPaymentFields.js

import { Grid, TextField, FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";

const CashPaymentFields = ({ formData, handleChange, errors }) => (
  <>
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            name="changeNeeded"
            checked={formData.changeNeeded || false}
            onChange={handleChange}
          />
        }
        label="¿El mensajero necesita llevar vuelto?"
      />
    </Grid>
    {formData.changeNeeded && (
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Vuelto total a llevar"
          name="totalChange"
          type="number"
          variant="outlined"
          onChange={handleChange}
          value={formData.totalChange || ""}
          required
          error={Boolean(errors.totalChange)}
          helperText={errors.totalChange}
        />
      </Grid>
    )}
  </>
);

CashPaymentFields.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

CashPaymentFields.defaultProps = {
  errors: {},
};

export default CashPaymentFields;
