import React, { useEffect, useState } from 'react';
import { getWarehousePickupsAdvanced } from '../Apis/inventoryAPI';
import { Box, List, ListItem, ListItemText, Pagination, TextField, Select, MenuItem } from '@mui/material';

function AllPickupsTab() {
  const [pickups, setPickups] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchAllPickups();
  }, [page, search, status, startDate, endDate]);

  const fetchAllPickups = async () => {
    try {
      const response = await getWarehousePickupsAdvanced(page, 10, search, status, startDate, endDate);
      setPickups(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error al obtener todas las recolecciones:', error);
    }
  };

  return (
    <Box>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="Buscar"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value="pending">Pendiente</MenuItem>
          <MenuItem value="completed">Completado</MenuItem>
        </Select>
        <TextField
          label="Fecha Inicio"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="Fecha Fin"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </Box>

      <List>
        {pickups.map((pickup) => (
          <ListItem key={pickup._id}>
            <ListItemText
              primary={`Producto: ${pickup.product.productTitle}`}
              secondary={`Cantidad: ${pickup.quantity} | Estado: ${pickup.status}`}
            />
          </ListItem>
        ))}
      </List>

      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>
    </Box>
  );
}

export default AllPickupsTab;
