import "./Featured.scss";
import DVZ from "../../assets/imgaes/DVZ.png";
import FAZ from "../../assets/imgaes/FAZ.png";
import HB from "../../assets/imgaes/HB.png";
import SZ1 from "../../assets/imgaes/SZ1.png";
import wLogo from "../../assets/imgaes/wLogo.png";
import zdf from "../../assets/imgaes/zdf.png";
import { motion } from "framer-motion"; // Add this import

const logos = [
  { src: DVZ, alt: 'DVZ' },
  { src: FAZ, alt: 'FAZ' },
  { src: HB, alt: 'HB' },
  { src: SZ1, alt: 'SZ1' },
  { src: wLogo, alt: 'WIWO' },
  { src: zdf, alt: 'ZDF' },
];

const Featured = () => {
  return (
    <div className="Featured">
      <div className="container">
        <div className="Featured_content">
          <span>Destacado en:</span>
          <motion.div
            className="logos"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: { staggerChildren: 0.2 },
              },
            }}
          >
            {logos.map((logo, index) => (
              <motion.div
                key={index}
                className="logo-item"
                whileHover={{ scale: 1.05 }}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <img src={logo.src} alt={logo.alt} />
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
