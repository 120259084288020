// src/pages/adminPanel/Components/desembolsos.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import {
  getAllDisbursements,
  processDisbursement,
  getDisbursementById,
} from '../../../Apis/disbursementApi';
import {
  getAllOrganizations,
  getOrganizationBalance,
  getPendingParcels,
} from '../../../Apis/organizationApi';
import { useForm, Controller } from 'react-hook-form';
import { Visibility } from '@mui/icons-material';

const Desembolsos = () => {
  // Estados para los desembolsos y la paginación
  const [disbursements, setDisbursements] = useState([]);
  const [filters, setFilters] = useState({ organizationId: '', status: '', dateFrom: '', dateTo: '' });
  const [page, setPage] = useState(0); // Material-UI usa índices basados en 0
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDisbursements, setTotalDisbursements] = useState(0);

  // Estados para balance y paquetes pendientes
  const [balance, setBalance] = useState(0);
  const [pendingParcels, setPendingParcels] = useState([]);

  // Estados para diálogos y notificaciones
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [currentDisbursement, setCurrentDisbursement] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [selectedParcels, setSelectedParcels] = useState([]);

  // Formularios con react-hook-form
  const { control, handleSubmit, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      organizationId: '',
      description: '',
    }
  });

  const selectedOrganizationId = watch('organizationId');

  // Fetch organizaciones para el select de filtros y formularios
  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      // Obtener todas las organizaciones (puedes ajustar el límite si es necesario)
      const response = await getAllOrganizations({}, 1, 1000);
      setOrganizations(response.data.data);
    } catch (error) {
      console.error('Error al obtener organizaciones:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al obtener organizaciones', severity: 'error' });
    }
  };

  // Fetch desembolsos cuando cambian los filtros o la paginación
  useEffect(() => {
    fetchDisbursements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, rowsPerPage]);

  const fetchDisbursements = async () => {
    setLoading(true);
    try {
      const response = await getAllDisbursements(filters, page + 1, rowsPerPage);
      setDisbursements(response.data.data);
      setTotalDisbursements(response.data.pagination ? response.data.pagination.total : response.data.data.length);
    } catch (error) {
      console.error('Error al obtener desembolsos:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al obtener desembolsos', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Fetch balance y paquetes pendientes cuando cambia la organización
  useEffect(() => {
    if (selectedOrganizationId) {
      fetchBalance(selectedOrganizationId);
      fetchPendingParcels(selectedOrganizationId);
    } else {
      setBalance(0);
      setPendingParcels([]);
      setSelectedParcels([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  const fetchBalance = async (organizationId) => {
    try {
      const response = await getOrganizationBalance(organizationId);
      setBalance(response.data.balance);
    } catch (error) {
      console.error('Error al obtener balance:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al obtener balance', severity: 'error' });
    }
  };

  const fetchPendingParcels = async (organizationId) => {
    try {
      const response = await getPendingParcels(organizationId);
      setPendingParcels(response.data.data);
    } catch (error) {
      console.error('Error al obtener paquetes pendientes:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al obtener paquetes pendientes', severity: 'error' });
    }
  };

  // Manejar cambios en los filtros
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
    setPage(0); // Reiniciar a la primera página al cambiar filtros
  };

  // Manejar cambios en la paginación
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Manejar cambios en las filas por página
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Abrir diálogo para procesar desembolso
  const handleOpenProcessDialog = () => {
    reset({ organizationId: '', description: '' });
    setPendingParcels([]);
    setSelectedParcels([]);
    setOpenProcessDialog(true);
  };

  // Cerrar diálogo para procesar desembolso
  const handleCloseProcessDialog = () => {
    setOpenProcessDialog(false);
  };

  // Abrir diálogo de detalles de desembolso
  const handleOpenDetailsDialog = (disbursement) => {
    setCurrentDisbursement(disbursement);
    setOpenDetailsDialog(true);
  };

  // Cerrar diálogo de detalles de desembolso
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setCurrentDisbursement(null);
  };

  // Manejar selección de paquetes
  const handleSelectParcel = (parcelId) => {
    if (selectedParcels.includes(parcelId)) {
      setSelectedParcels(selectedParcels.filter(id => id !== parcelId));
    } else {
      setSelectedParcels([...selectedParcels, parcelId]);
    }
  };

  // Manejar selección de todos los paquetes
  const handleSelectAllParcels = (event) => {
    if (event.target.checked) {
      const allParcelIds = pendingParcels.map(parcel => parcel._id);
      setSelectedParcels(allParcelIds);
    } else {
      setSelectedParcels([]);
    }
  };

  // Manejar envío del formulario para procesar desembolso
  const onProcessDisbursement = async (data) => {
    if (selectedParcels.length === 0) {
      setSnackbar({ open: true, message: 'Debe seleccionar al menos un paquete para el desembolso.', severity: 'warning' });
      return;
    }

    const selectedParcelsData = pendingParcels.filter(parcel => selectedParcels.includes(parcel._id));
    const totalAmount = selectedParcelsData.reduce((sum, parcel) => sum + parcel.amount, 0);

    if (totalAmount > balance) {
      setSnackbar({ open: true, message: 'El monto total seleccionado supera el balance de la organización.', severity: 'error' });
      return;
    }

    try {
      await processDisbursement({
        organizationId: data.organizationId,
        description: data.description,
        parcelIds: selectedParcels,
      });
      setSnackbar({ open: true, message: 'Desembolso procesado exitosamente', severity: 'success' });
      handleCloseProcessDialog();
      fetchDisbursements();
      if (data.organizationId) {
        fetchBalance(data.organizationId);
        fetchPendingParcels(data.organizationId);
      }
    } catch (error) {
      console.error('Error al procesar desembolso:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al procesar desembolso', severity: 'error' });
    }
  };

  // Cerrar snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>Gestión de Desembolsos</Typography>

      {/* Barra de filtros */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem', alignItems: 'center' }}>
        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Organización</InputLabel>
          <Controller
            name="organizationId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                label="Organización"
                onChange={(e) => {
                  field.onChange(e);
                  handleFilterChange(e); // Actualizar filtros
                }}
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {organizations.map(org => (
                  <MenuItem key={org._id} value={org._id}>{org.name}</MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Controller
            name="status"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                label="Status"
                onChange={(e) => {
                  field.onChange(e);
                  handleFilterChange(e);
                }}
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                <MenuItem value="pending">Pendiente</MenuItem>
                <MenuItem value="completed">Completado</MenuItem>
                <MenuItem value="cancelled">Cancelado</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <TextField
          label="Fecha Desde"
          name="dateFrom"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.dateFrom}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
        />
        <TextField
          label="Fecha Hasta"
          name="dateTo"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.dateTo}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
        />
        <Button variant="contained" color="primary" onClick={handleOpenProcessDialog}>
          Registrar Desembolso
        </Button>
      </div>

      {/* Información adicional: Balance y Paquetes Pendientes */}
      {filters.organizationId && (
        <div style={{ marginBottom: '1rem' }}>
          <Typography variant="subtitle1">Balance de la Organización: ${balance.toFixed(2)}</Typography>
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>Paquetes Pendientes de Desembolso:</Typography>
          {pendingParcels.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID del Paquete</TableCell>
                    <TableCell>Monto</TableCell>
                    <TableCell>Fecha de Recolección</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pendingParcels.map((parcel) => (
                    <TableRow key={parcel._id}>
                      <TableCell>{parcel._id}</TableCell>
                      <TableCell>${parcel.amount.toFixed(2)}</TableCell>
                      <TableCell>{new Date(parcel.cashCollectedDate).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No hay paquetes pendientes de desembolso.
            </Typography>
          )}
        </div>
      )}

      {/* Tabla de desembolsos */}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Organización</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Paquetes</TableCell>
                <TableCell>Detalles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {disbursements.length > 0 ? (
                disbursements.map((disb) => (
                  <TableRow key={disb._id}>
                    <TableCell>{disb.organization.name}</TableCell>
                    <TableCell>${disb.amount.toFixed(2)}</TableCell>
                    <TableCell>{disb.description}</TableCell>
                    <TableCell>{new Date(disb.date).toLocaleDateString()}</TableCell>
                    <TableCell>{disb.status.charAt(0).toUpperCase() + disb.status.slice(1)}</TableCell>
                    <TableCell>{disb.parcels.length}</TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleOpenDetailsDialog(disb)}>
                        <Visibility />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No se encontraron desembolsos.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Paginación */}
      <TablePagination
        component="div"
        count={totalDisbursements}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      {/* Diálogo para registrar desembolso */}
      <Dialog open={openProcessDialog} onClose={handleCloseProcessDialog} fullWidth maxWidth="md">
        <DialogTitle>Registrar Desembolso</DialogTitle>
        <DialogContent>
          <form id="process-disbursement-form" onSubmit={handleSubmit(onProcessDisbursement)}>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>Organización</InputLabel>
              <Controller
                name="organizationId"
                control={control}
                rules={{ required: 'La organización es obligatoria' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Organización"
                    onChange={(e) => {
                      field.onChange(e);
                      // Cuando cambia la organización, se actualizarán los paquetes pendientes automáticamente
                    }}
                    error={!!errors.organizationId}
                  >
                    <MenuItem value="">
                      <em>Seleccione una organización</em>
                    </MenuItem>
                    {organizations.map(org => (
                      <MenuItem key={org._id} value={org._id}>{org.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.organizationId && (
                <Typography variant="caption" color="error">
                  {errors.organizationId.message}
                </Typography>
              )}
            </FormControl>

            <Controller
              name="description"
              control={control}
              rules={{ required: 'La descripción es obligatoria' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descripción"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.description}
                  helperText={errors.description ? errors.description.message : ''}
                />
              )}
            />

            {/* Lista de paquetes pendientes con checkboxes */}
            {selectedOrganizationId && (
              <Box mt={2}>
                <Typography variant="h6">Seleccionar Paquetes para Desembolso</Typography>
                {pendingParcels.length > 0 ? (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              indeterminate={selectedParcels.length > 0 && selectedParcels.length < pendingParcels.length}
                              checked={pendingParcels.length > 0 && selectedParcels.length === pendingParcels.length}
                              onChange={handleSelectAllParcels}
                            />
                          </TableCell>
                          <TableCell>ID del Paquete</TableCell>
                          <TableCell>Monto</TableCell>
                          <TableCell>Fecha de Recolección</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pendingParcels.map((parcel) => (
                          <TableRow key={parcel._id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedParcels.includes(parcel._id)}
                                onChange={() => handleSelectParcel(parcel._id)}
                              />
                            </TableCell>
                            <TableCell>{parcel._id}</TableCell>
                            <TableCell>${parcel.amount.toFixed(2)}</TableCell>
                            <TableCell>{new Date(parcel.cashCollectedDate).toLocaleDateString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No hay paquetes pendientes para esta organización.
                  </Typography>
                )}
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                  Monto Total Seleccionado: ${pendingParcels
                    .filter(parcel => selectedParcels.includes(parcel._id))
                    .reduce((sum, parcel) => sum + parcel.amount, 0)
                    .toFixed(2)}
                </Typography>
              </Box>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProcessDialog}>Cancelar</Button>
          <Button type="submit" form="process-disbursement-form" variant="contained" color="primary">
            Registrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para ver detalles de desembolso */}
      <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog} fullWidth maxWidth="md">
        <DialogTitle>Detalles del Desembolso</DialogTitle>
        <DialogContent>
          {currentDisbursement ? (
            <>
              <Typography variant="subtitle1"><strong>Organización:</strong> {currentDisbursement.organization.name}</Typography>
              <Typography variant="subtitle1"><strong>Monto:</strong> ${currentDisbursement.amount.toFixed(2)}</Typography>
              <Typography variant="subtitle1"><strong>Descripción:</strong> {currentDisbursement.description}</Typography>
              <Typography variant="subtitle1"><strong>Fecha:</strong> {new Date(currentDisbursement.date).toLocaleDateString()}</Typography>
              <Typography variant="subtitle1"><strong>Status:</strong> {currentDisbursement.status.charAt(0).toUpperCase() + currentDisbursement.status.slice(1)}</Typography>
              <Box mt={2}>
                <Typography variant="h6">Paquetes Asociados</Typography>
                {currentDisbursement.parcels.length > 0 ? (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID del Paquete</TableCell>
                          <TableCell>Monto</TableCell>
                          <TableCell>Fecha de Recolección</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentDisbursement.parcels.map(parcel => (
                          <TableRow key={parcel._id}>
                            <TableCell>{parcel._id}</TableCell>
                            <TableCell>${parcel.amount.toFixed(2)}</TableCell>
                            <TableCell>{new Date(parcel.cashCollectedDate).toLocaleDateString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No hay paquetes asociados a este desembolso.
                  </Typography>
                )}
              </Box>
            </>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Desembolsos;
