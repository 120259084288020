// Register.js
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Navbar } from '../../components';
import './Register.scss';
import { RegisterApi } from '../../Apis/auth';
import { AppContext } from '../../contextApi/AppContext';
import { motion } from 'framer-motion';
import { TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import clientImg from './client.png';
import bikerImg from './biker.png';

const Register = () => {
  const {
    AppData: { isLoading },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const isClient = location.state?.type === 'client';
  const isBiker = location.state?.type === 'biker';

  const [formData, setFormData] = useState({
    isShipper: isClient,
    isBiker: isBiker,
    isOrgAdmin: isClient,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    companyName: '',
    warehouseAddress: '',
    password: '',
  });

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      isShipper: isClient,
      isBiker: isBiker,
      isOrgAdmin: isClient,
    }));
  }, [isClient, isBiker]);

  const handlePassword = () => {
    setShowPass((prevState) => !prevState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: 'LOADING', payload: true });

    try {
      if (!formData.email || !formData.password) {
        return alert('¡El correo y la contraseña son obligatorios!');
      }

      if (
        formData.isShipper &&
        (!formData.companyName || !formData.warehouseAddress)
      ) {
        return alert(
          '¡El nombre de la empresa y la dirección del almacén son obligatorios para clientes!'
        );
      }

      const {
        data: { message },
      } = await RegisterApi(formData);
      alert(message);

      setFormData({
        isShipper: isClient,
        isBiker: isBiker,
        isOrgAdmin: isClient,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        companyName: '',
        warehouseAddress: '',
        password: '',
      });

      dispatch({ type: 'LOADING', payload: false });
      navigate('/login');
    } catch (error) {
      alert(error.response.data.message);
      dispatch({ type: 'LOADING', payload: false });
    }
  };

  return (
    <section className="Register">
      <Navbar />
      <div className="Register_content">
        <div className="Register_content_left">
          <motion.img
            src={isClient ? clientImg : bikerImg}
            alt="RegisterImage"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          />
        </div>
        <div className="Register_content_right">
          <motion.div
            className="Register_content_right_content"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h3 className="Register_content_right_content_subtitle">
              ¡Crea tu cuenta de {isClient ? 'Cliente' : 'Mensajero'}!
            </h3>
            <form
              className="Register_content_right_content_form"
              onSubmit={handleSubmit}
            >
              <TextField
                label="Nombre"
                name="first_name"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={formData.first_name}
                margin="normal"
                required
              />
              <TextField
                label="Apellido"
                name="last_name"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={formData.last_name}
                margin="normal"
                required
              />
              <TextField
                label="Teléfono"
                name="phone"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={formData.phone}
                margin="normal"
                required
              />
              <TextField
                label="Correo Electrónico"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={formData.email}
                margin="normal"
                required
              />

              {formData.isShipper && (
                <>
                  <TextField
                    label="Nombre de la empresa o emprendimiento"
                    name="companyName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={formData.companyName}
                    margin="normal"
                    required
                  />
                  <TextField
                    label="Dirección del almacén"
                    name="warehouseAddress"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={formData.warehouseAddress}
                    margin="normal"
                    required
                  />
                  <motion.p
                    className="warning-message"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    <strong>ALERTA:</strong> Si tu empresa ya tiene una organización, contacta a tu administrador para que te cree un usuario.
                  </motion.p>
                </>
              )}

              <TextField
                label="Contraseña"
                name="password"
                type={showPass ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                value={formData.password}
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePassword} edge="end">
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <motion.div
                className="button-container"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                  className="submit-button"
                >
                  {isLoading ? 'Registrando...' : 'Registrarse'}
                </Button>
              </motion.div>
            </form>
            <div className="Register_content_right_content_underForm">
              <div className="Register_content_right_content_underForm_actions">
                <p>
                  ¿Ya tienes una cuenta?{' '}
                  <span>
                    <Link to="/login">Ingresar</Link>
                  </span>
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Register;
