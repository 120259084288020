import React, { useState } from 'react';
import { Box, Container, Typography, Tabs, Tab, Paper } from '@mui/material';
import PendingPickupsTab from './PendingPickupsTab';
import AllPickupsTab from './AllPickupsTab';

function ManageWarehousePickups() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Gestión de Recolecciones
        </Typography>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Tabs value={tabValue} onChange={handleTabChange} centered>
            <Tab label="Recolecciones Pendientes" />
            <Tab label="Todas las Recolecciones" />
          </Tabs>
          <Box mt={2}>
            {tabValue === 0 && <PendingPickupsTab />}
            {tabValue === 1 && <AllPickupsTab />}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default ManageWarehousePickups;
