import React from 'react';
import { Typography, Paper, Button } from "@mui/material";

const Usuarios = () => {
  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5">Gestión de Usuarios</Typography>
      <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>Agregar Usuario</Button>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        Ver Usuarios: Lista de todos los usuarios, incluyendo administradores, mensajeros y clientes.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        Editar Usuario: Permite modificar la información de los usuarios existentes.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        Asignar Roles y Permisos: Gestiona los roles y permisos de los usuarios.
      </Typography>
    </Paper>
  );
};

export default Usuarios;
