import React, { useState, useEffect } from 'react';
import {
  Typography, Paper, Button, Grid, Card, CardContent, CardActions, Divider,
  Accordion, AccordionSummary, AccordionDetails, TextField, MenuItem, Box, TablePagination,
  Tabs, Tab
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { allPercels } from '../../../Apis/parcel'; // Asegúrate de que la ruta y el nombre sean correctos
import { format } from 'date-fns';
import ParcelDetailModal from '../../../components/Modals/ParcelDetail'; // Asegúrate de que la ruta sea correcta

const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    name: '',
    status: '',
    recipientName: '',
    paymentMethod: '',
    isPaid: '',
    startDate: '',
    endDate: ''
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalParcels, setTotalParcels] = useState(0);
  const [activeTab, setActiveTab] = useState(0); // Tab principal
  const [subTab, setSubTab] = useState(0); // Sub-tab para estados
  const [selectedPedido, setSelectedPedido] = useState(null); // Estado para el pedido seleccionado

  const fetchPedidos = async () => {
    try {
      setLoading(true);
      const response = await allPercels({
        ...filters,
        status: getStatusForTab(),
        page: page + 1,
        limit: rowsPerPage
      });
      setPedidos(response.data.data);
      setTotalParcels(response.data.totalParcels);
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPedidos();
  }, [page, rowsPerPage, filters, activeTab, subTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSubTab(0); // Reiniciar sub-tab al cambiar de tab principal
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusForTab = () => {
    const tabs = [
      '', // Todos
      ['inWarehouse'], // Pedidos en Almacén
      ['collected'], // Pedidos Recolectados
      ['toCollect'], // Pedidos Pendientes
      ['collecting'], // En Ruta para Recolectar
      ['toDeliver'], // Listo para Entregar
      ['delivering'], // En Proceso de Entregar
      ['delivered'], // Pedidos Entregados
      ['cancelled'] // Pedidos Cancelados
    ];
    return tabs[subTab];
  };

  const getParcelStatusLabel = (status) => {
    switch (status) {
      case 'toCollect':
        return 'Listo para Recoger';
      case 'collecting':
        return 'En Ruta para Recoger';
      case 'inWarehouse':
        return 'En Almacén';
      case 'toDeliver':
        return 'Listo para Entregar';
      case 'delivering':
        return 'En Ruta para Entregar';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      case 'collected':
        return 'Recolectado';
      default:
        return 'Desconocido';
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Gestión de Pedidos</Typography>

      {/* Filtros de búsqueda */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Typography variant="h6">Filtros de búsqueda</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={3}>
            <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Nombre"
                  variant="outlined"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Estado"
                  select
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="toCollect">Listo para Recoger</MenuItem>
                  <MenuItem value="collecting">En Ruta para Recoger</MenuItem>
                  <MenuItem value="inWarehouse">En Almacén</MenuItem>
                  <MenuItem value="toDeliver">Listo para Entregar</MenuItem>
                  <MenuItem value="delivering">En Ruta para Entregar</MenuItem>
                  <MenuItem value="delivered">Entregado</MenuItem>
                  <MenuItem value="collected">Recolectado</MenuItem>
                  <MenuItem value="cancelled">Cancelado</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Nombre del destinatario"
                  variant="outlined"
                  name="recipientName"
                  value={filters.recipientName}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Método de Pago"
                  select
                  name="paymentMethod"
                  value={filters.paymentMethod}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="cash">Efectivo</MenuItem>
                  <MenuItem value="sinpe_transferencia">SINPE</MenuItem>
                  <MenuItem value="otro">Otro</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Pagado"
                  select
                  name="isPaid"
                  value={filters.isPaid}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Sí</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Fecha de inicio"
                  type="date"
                  name="startDate"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Fecha de fin"
                  type="date"
                  name="endDate"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button onClick={fetchPedidos} variant="contained" sx={{ mt: 2 }}>
              Aplicar Filtros
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Tabs principales */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ marginBottom: 2 }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab label="Todos los Pedidos" />
        <Tab label="Pedidos Filtrados" />
      </Tabs>

      {/* Sub-Tabs para estados de pedidos */}
      {activeTab === 1 && (
        <Tabs
          value={subTab}
          onChange={handleSubTabChange}
          sx={{ marginBottom: 2, overflowX: 'auto', whiteSpace: 'nowrap' }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab label="En Almacén" />
          <Tab label="Recolectados" />
          <Tab label="Pendientes" />
          <Tab label="En Ruta para Recolectar" />
          <Tab label="Para Entregar" />
          <Tab label="En Proceso de Entregar" />
          <Tab label="Entregados" />
          <Tab label="Cancelados" />
        </Tabs>
      )}

      {/* Modal para detalles del pedido */}
      {selectedPedido && (
        <ParcelDetailModal
          selectedParcel={selectedPedido}
          closeModal={() => setSelectedPedido(null)}
        />
      )}

      {/* Mostramos la lista de pedidos */}
      {loading ? (
        <Typography variant="body1" sx={{ marginTop: 2 }}>Cargando pedidos...</Typography>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {pedidos.length > 0 ? (
            pedidos.map((pedido) => (
              <Grid item xs={12} sm={6} md={4} key={pedido._id}>
                <Card sx={{ maxWidth: '100%', width: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>{pedido.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Estado: {getParcelStatusLabel(pedido.status)}
                    </Typography>
                    <Divider sx={{ marginY: 1 }} />
                    <Typography variant="body2">
                      <strong>Recogida:</strong> {pedido.pickup_address}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Entrega:</strong> {pedido.dropoff_address}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      <strong>Destinatario:</strong> {pedido.recipientName} ({pedido.recipientPhone})
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      <strong>Creado el:</strong> {format(new Date(pedido.createdAt), 'dd/MM/yyyy HH:mm')}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      color="primary" 
                      onClick={() => setSelectedPedido(pedido)}
                    >
                      Ver detalles
                    </Button>
                    <Button size="small" color="secondary">Modificar</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ marginTop: 2 }}>No se encontraron pedidos.</Typography>
          )}
        </Grid>
      )}

      {/* Paginación */}
      <TablePagination
        component="div"
        count={totalParcels}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '.MuiTablePagination-toolbar': {
            flexDirection: { xs: 'column', sm: 'row' },
          },
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            fontSize: { xs: '0.8rem', sm: '1rem' },
          },
        }}
      />
    </Paper>
  );
};

export default Pedidos;
