// TextFieldComponent.js
import { TextField, Grid } from "@mui/material";

const TextFieldComponent = ({ label, name, value, handleChange, required = false, type = "text" }) => (
  <Grid item xs={12}>
    <TextField
      fullWidth
      label={label}
      name={name}
      variant="outlined"
      onChange={handleChange}
      required={required}
      type={type}
      value={value || ""}
    />
  </Grid>
);

export default TextFieldComponent;
