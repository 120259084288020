// src/components/Modals/ViewRouteModal.jsx

import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  Typography, 
  Box 
} from '@mui/material';
import ParcelDetailModal from './ParcelDetail'; // Asegúrate de que la ruta es correcta
import { addParcelToRoute } from '../../Apis/routes'; // Usar la función correcta de la API

const ViewRouteModal = ({ open, onClose, route, refreshRoutes }) => {
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleParcelClick = (parcel) => {
    setSelectedParcel(parcel); // Seleccionar el parcel para ver los detalles
  };

  const handleCloseParcelModal = () => {
    setSelectedParcel(null); // Cerrar el modal del parcel
  };

  // Ordena los parcels para mostrar los `pending` y `intransit` primero
  const sortedParcels = [...route.parcels].sort((a, b) => {
    const statusOrder = ['pending', 'intransit', 'completed', 'cancelled'];
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  });

  const handleAssignRoute = async (parcelId, routeId) => {
    setLoading(true);
    setError(null);
    console.log(`Asignando paquete ${parcelId} a la ruta ${routeId}`);
    try {
      // Usar la función correcta para asignar la ruta
      const response = await addParcelToRoute(routeId, parcelId);
      console.log('Respuesta de addParcelToRoute:', response);

      if (response.data.success) {
        // Refrescar las rutas después de la asignación
        console.log('Asignación exitosa, refrescando rutas.');
        await refreshRoutes(); // Esperar a que se complete la actualización
        onClose();
      } else {
        console.error('Error en la respuesta de la API:', response.data);
        setError('Error al asignar el paquete a la ruta.');
      }
    } catch (err) {
      console.error("Error asignando paquete a la ruta:", err);
      setError('Error al asignar el paquete a la ruta.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Detalles de la Ruta</DialogTitle>
      <DialogContent>
        <Typography variant="h6">{`Ruta: ${route.name}`}</Typography>
        <Typography variant="subtitle1">{`Estado: ${route.status}`}</Typography>
        <List>
          {sortedParcels.map((parcel) => (
            <ListItem 
              key={parcel._id} 
              sx={{ backgroundColor: '#f9f9f9', marginBottom: 2, borderRadius: 2 }}
            >
              <ListItemText
                primary={`Pedido: ${parcel.clientParcelId} - ${parcel.recipientName}`}
                secondary={`Estado: ${parcel.status}`}
              />
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleParcelClick(parcel)}
                >
                  Ver Detalles
                </Button>
                {/* Botón para reasignar ruta */}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleAssignRoute(parcel._id, route._id)}
                  disabled={loading}
                >
                  Asignar Ruta
                </Button>
              </Box>
            </ListItem>
          ))}
        </List>

        {/* Modal para ver los detalles del parcel */}
        {selectedParcel && (
          <ParcelDetailModal
            selectedParcel={selectedParcel}
            onClose={handleCloseParcelModal} // Pasar onClose correctamente
            refreshOrders={refreshRoutes} // Pasar refreshOrders correctamente
          />
        )}

        {loading && (
          <Typography variant="body2" color="textSecondary">
            Asignando ruta...
          </Typography>
        )}
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          variant="contained" 
          color="secondary" 
          disabled={loading}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewRouteModal;
