import { useState, useEffect } from 'react';

const useGoogleMaps = (apiKey) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const scriptId = 'google-maps-script';
    const callbackName = 'initMap';

    // Si ya está cargado Google Maps, no volver a cargar el script
    if (typeof window.google !== 'undefined') {
      setLoaded(true);
      return;
    }

    // Verificar si el script de Google Maps ya está en el documento
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.addEventListener('load', () => setLoaded(true));
      existingScript.addEventListener('error', () => setError('Failed to load Google Maps script'));
      return;
    }

    // Callback que se ejecuta cuando el script de Google Maps se ha cargado
    window[callbackName] = () => {
      setLoaded(true);
    };

    // Crear el script de Google Maps
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=${callbackName}`;
    script.async = true;
    script.defer = true;
    script.onerror = () => setError('Failed to load Google Maps script');

    // Insertar el script en el documento
    document.head.appendChild(script);

    return () => {
      // Eliminar el callback para evitar conflictos en futuras cargas
      delete window[callbackName];
    };
  }, [apiKey]);

  return { loaded, error };
};

export default useGoogleMaps;
