// Home.js
import React, { Suspense, lazy } from 'react';
import { Navbar } from '../../components';
import './Home.scss';

const Hero = lazy(() => import('../../components/Hero/Hero'));
const Featured = lazy(() => import('../../components/Featured/Featured'));

const Home = () => {
  return (
    <section className="Home">
      <Navbar />
      <Suspense fallback={<div>Cargando...</div>}>
        <Hero />
        <Featured />
      </Suspense>
    </section>
  );
};

export default Home;
