// OtherPaymentDetails.js
import { Grid, TextField } from "@mui/material";

const OtherPaymentDetails = ({ handleChange }) => (
  <Grid item xs={12}>
    <TextField
      fullWidth
      label="Detalles adicionales"
      name="otherDetails"
      variant="outlined"
      onChange={handleChange}
      required
    />
  </Grid>
);

export default OtherPaymentDetails;