// src/pages/Login/Login.js

import "./Login.scss";
import { Navbar } from "../../components";
import LoginImg from "../../assets/imgaes/login_image.png";
import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginApi } from "../../Apis/auth";
import Cookies from "universal-cookie";
import { AppContext } from "../../contextApi/AppContext";

const Login = () => {
  const {
    AppData: { isLoading },
    dispatch,
  } = useContext(AppContext);
  const cookies = new Cookies();
  const [formData, setFormData] = useState({});
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "LOADING", payload: false });
  }, [dispatch]);

  const handlePassword = () => {
    setShowPass((prevState) => !prevState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOADING", payload: true });

    try {
      if (!formData.email || !formData.password) {
        return alert("Email and password are required!");
      }
      const { data: { user } } = await LoginApi(formData);
      console.log("Usuario autenticado:", user);
      dispatch({ type: "LOGIN", payload: user });
      console.log("Después de LOGIN dispatch en Login.js:", user);

      cookies.set("token", user.token, { path: "/" });
      cookies.set("user", JSON.stringify(user), { path: "/" });
      console.log("Cookies establecidas: ", cookies.getAll()); // Log adicional para verificar cookies
      navigate("/dashboard");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert("An unexpected error occurred.");
      }
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <section className="Login">
      <Navbar />
      <div className="Login_content">
        <div className="Login_content_left">
          <img src={LoginImg} alt="LoginImage" />
        </div>
        <div className="Login_content_right">
          <div className="Login_content_right_content">
            <h1 className="Login_content_right_content_title">Welcome Back</h1>
            <h3 className="Login_content_right_content_subtitle">
              Log in to continue
            </h3>
            <form className="Login_content_right_content_form" onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Email address"
                className="inpt Login_content_right_content_form_input"
                onChange={handleChange}
                required
              />
              <div className="Login_content_right_content_form_password">
                <input
                  type={`${showPass ? "text" : "password"}`}
                  name="password"
                  placeholder="Password"
                  className="inpt Login_content_right_content_form_input"
                  onChange={handleChange}
                  minLength="6"
                  maxLength="12"
                  required
                />
                <span onClick={handlePassword} style={{ cursor: 'pointer', color: '#007BFF' }}>
                  {showPass ? "hide" : "show"}
                </span>
              </div>
              <span className="Login_content_right_content_form_text">
                Forgot your password?
              </span>
              <button className="btn-primary" disabled={isLoading}>
                {isLoading ? "Logging in..." : "Login"}
              </button>
            </form>
            <div className="Login_content_right_content_underForm">
              <span>Don't have an account?</span>
              <div className="Login_content_right_content_underForm_actions">
                <Link to="/register" state={{ type: "client" }}>
                  <button className="btn-outline">Create client account</button>
                </Link>
                <Link to="/register" state={{ type: "biker" }}>
                  <button className="btn-outline">Create biker account</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
