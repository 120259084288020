import React, { useState } from 'react';
import { Typography, Paper, Button, Box } from "@mui/material";
import AdminCoverageMap from "../../../components/Modals/AdminCoverageMap";

const Configuraciones = () => {
  const [showCoverageMap, setShowCoverageMap] = useState(false);

  const toggleCoverageMap = () => {
    setShowCoverageMap(!showCoverageMap); // Alterna la visualización del mapa de cobertura
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5">Configuraciones del Sistema</Typography>

      <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
        Métodos de Pago
      </Button>

      <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
        Costos de Envío
      </Button>

      <Button variant="contained" color="primary" sx={{ marginTop: 2 }}>
        Parámetros de Rutas
      </Button>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={toggleCoverageMap}
      >
        {showCoverageMap ? "Ocultar Mapa de Cobertura" : "Definir Límites de Cobertura"}
      </Button>

      <Typography variant="body1" sx={{ marginTop: 2 }}>
        Aquí puedes configurar los métodos de pago, los costos de envío y otros parámetros importantes.
      </Typography>

      {showCoverageMap && (
        <Box sx={{ marginTop: 4 }}>
          <AdminCoverageMap />
        </Box>
      )}
    </Paper>
  );
};

export default Configuraciones;
