// src/contextApi/AppReducers.js

export default (state, action) => {
  switch (action.type) {
    case "LOADING":
      if (state.isLoading === action.payload) return state;
      return {
        ...state,
        isLoading: action.payload,
      };
      
    case "LOGIN":
      if (state.user && state.user._id === action.payload._id) return state; // Evita cambios si el usuario es el mismo
      console.log("Reducer LOGIN action:", action.payload);
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
      
    case "GET_PERCELS":
      if (state.percels === action.payload) return state;
      return {
        ...state,
        percels: action.payload,
        isLoading: false,
      };
      
    case "CREATE_SHIPMENT":
      if (state.percels && state.percels.find(percel => percel._id === action.payload._id)) {
        return state; // Evita duplicados
      }
      return {
        ...state,
        percels: [...(state.percels || []), action.payload],
        isLoading: false,
      };
      
    case "UPDATE_PARCEL":
      const updatedPercels = state.percels.filter(
        (percel) => percel._id !== action.payload
      );
      if (updatedPercels.length === state.percels.length) return state;
      return {
        ...state,
        percels: updatedPercels,
      };
      
    case "CARRIER_SHIPMENTS":
      if (state.myShipments === action.payload) return state;
      return {
        ...state,
        myShipments: action.payload,
        isLoading: false,
      };
      
    case "DELIVER_PARCEL":
      const updatedShipments = state.myShipments.map((parcel) =>
        parcel._id === action.payload
          ? { ...parcel, status: "delivered" }
          : parcel
      );
      if (JSON.stringify(updatedShipments) === JSON.stringify(state.myShipments)) return state;
      return {
        ...state,
        myShipments: updatedShipments,
      };
      
    case "GET_ROUTES":
      if (state.routes === action.payload) return state;
      return {
        ...state,
        routes: action.payload,
        isLoading: false,
      };
      
    case "LOG_OUT":
      if (state.user === null && state.percels === null && state.myShipments === null && state.routes === null && state.isLoading === false) {
        return state;
      }
      return {
        user: null,
        percels: null,
        myShipments: null,
        routes: null,
        isLoading: false,
      };
      
    default:
      return state;
  }
};
