import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
} from '@mui/material';
import { getAllBikers } from '../../../Apis/routes';
import { RegisterApi } from '../../../Apis/auth';

const Mensajeros = () => {
  const [bikers, setBikers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newBikerData, setNewBikerData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    isBiker: true,
  });
  const [loading, setLoading] = useState(false);

  const fetchBikers = async () => {
    setLoading(true);
    try {
      const response = await getAllBikers();
      console.log('Respuesta de getAllBikers:', response.data);

      const bikersData = response.data.data; // Ajusta según la estructura real
      if (response.data.success && Array.isArray(bikersData)) {
        setBikers(bikersData);
      } else {
        console.warn('Formato de respuesta inesperado:', bikersData);
        setBikers([]);
      }
    } catch (error) {
      console.error('Error al obtener los mensajeros:', error);
      setBikers([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBikers();
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNewBikerData({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      isBiker: true,
    });
  };

  const handleChange = (e) => {
    setNewBikerData({ ...newBikerData, [e.target.name]: e.target.value });
  };

  const handleRegisterBiker = async () => {
    setLoading(true);
    try {
      await RegisterApi(newBikerData);
      alert('Mensajero registrado exitosamente');
      handleCloseModal();
      fetchBikers();
    } catch (error) {
      console.error('Error al registrar el mensajero:', error);
      alert('Error al registrar el mensajero');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5">Gestión de Mensajeros</Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        onClick={handleOpenModal}
      >
        Registrar Nuevo Mensajero
      </Button>

      {/* Tabla de mensajeros */}
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(bikers) && bikers.length > 0 ? (
              bikers.map((biker) => (
                <TableRow key={biker._id}>
                  <TableCell>{biker.first_name}</TableCell>
                  <TableCell>{biker.last_name}</TableCell>
                  <TableCell>{biker.email}</TableCell>
                  <TableCell>{biker.phone}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No hay mensajeros disponibles.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal para registrar nuevo mensajero */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Paper
          sx={{
            padding: 2,
            width: 400,
            margin: '100px auto',
            outline: 'none',
          }}
        >
          <Typography variant="h6">Registrar Nuevo Mensajero</Typography>
          <TextField
            fullWidth
            label="Nombre"
            name="first_name"
            value={newBikerData.first_name}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            label="Apellido"
            name="last_name"
            value={newBikerData.last_name}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={newBikerData.email}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            label="Teléfono"
            name="phone"
            value={newBikerData.phone}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />
          <TextField
            fullWidth
            label="Contraseña"
            name="password"
            type="password"
            value={newBikerData.password}
            onChange={handleChange}
            sx={{ marginTop: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegisterBiker}
            sx={{ marginTop: 2 }}
            disabled={loading}
          >
            {loading ? 'Registrando...' : 'Registrar'}
          </Button>
        </Paper>
      </Modal>
    </Paper>
  );
};

export default Mensajeros;
