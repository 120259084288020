// src/pages/adminPanel/Components/Organizaciones.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  CircularProgress,
  Box,
} from "@mui/material";
import { Edit, Delete, Visibility } from '@mui/icons-material';
import {
  getAllOrganizations,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  getPendingParcels,
} from '../../../Apis/organizationApi';
import { useForm, Controller } from 'react-hook-form';

const Organizaciones = () => {
  // Estados para las organizaciones y la paginación
  const [organizations, setOrganizations] = useState([]);
  const [filters, setFilters] = useState({ name: '', warehouseAddress: '' });
  const [page, setPage] = useState(0); // Material-UI usa 0-based index
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalOrganizations, setTotalOrganizations] = useState(0);

  // Estados para diálogos y notificaciones
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openBalanceDialog, setOpenBalanceDialog] = useState(false);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [balanceParcels, setBalanceParcels] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);

  // Formularios con react-hook-form
  const { control, handleSubmit, reset, formState: { errors } } = useForm();

  // Fetch organizations cuando cambian los filtros o la paginación
  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, rowsPerPage]);

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const response = await getAllOrganizations(filters, page + 1, rowsPerPage);
      setOrganizations(response.data.data);
      setTotalOrganizations(response.data.pagination.total);
    } catch (error) {
      console.error('Error al obtener organizaciones:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al obtener organizaciones', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Manejar cambios en los filtros
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
    setPage(0); // Reiniciar a la primera página al cambiar filtros
  };

  // Manejar cambios en la paginación
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Manejar cambios en las filas por página
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Abrir diálogo de creación
  const handleOpenCreateDialog = () => {
    reset(); // Resetear el formulario
    setOpenCreateDialog(true);
  };

  // Cerrar diálogo de creación
  const handleCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  // Manejar creación de organización
  const onCreateOrganization = async (data) => {
    try {
      await createOrganization(data);
      setSnackbar({ open: true, message: 'Organización creada exitosamente', severity: 'success' });
      handleCloseCreateDialog();
      fetchOrganizations();
    } catch (error) {
      console.error('Error al crear organización:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al crear organización', severity: 'error' });
    }
  };

  // Abrir diálogo de edición
  const handleOpenEditDialog = (organization) => {
    setCurrentOrg(organization);
    reset({
      name: organization.name,
      warehouseAddress: organization.warehouseAddress,
    });
    setOpenEditDialog(true);
  };

  // Cerrar diálogo de edición
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setCurrentOrg(null);
  };

  // Manejar actualización de organización
  const onUpdateOrganization = async (data) => {
    try {
      await updateOrganization(currentOrg._id, data);
      setSnackbar({ open: true, message: 'Organización actualizada exitosamente', severity: 'success' });
      handleCloseEditDialog();
      fetchOrganizations();
    } catch (error) {
      console.error('Error al actualizar organización:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al actualizar organización', severity: 'error' });
    }
  };

  // Abrir diálogo de eliminación
  const handleOpenDeleteDialog = (organization) => {
    setCurrentOrg(organization);
    setOpenDeleteDialog(true);
  };

  // Cerrar diálogo de eliminación
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setCurrentOrg(null);
  };

  // Manejar eliminación de organización
  const handleDeleteOrganization = async () => {
    try {
      await deleteOrganization(currentOrg._id);
      setSnackbar({ open: true, message: 'Organización eliminada exitosamente', severity: 'success' });
      handleCloseDeleteDialog();
      fetchOrganizations();
    } catch (error) {
      console.error('Error al eliminar organización:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al eliminar organización', severity: 'error' });
    }
  };

  // Abrir diálogo de balance
  const handleOpenBalanceDialog = async (organization) => {
    setCurrentOrg(organization);
    setBalanceLoading(true);
    try {
      const response = await getPendingParcels(organization._id);
      setBalanceParcels(response.data.data);
    } catch (error) {
      console.error('Error al obtener paquetes pendientes:', error.response?.data?.message || error.message);
      setSnackbar({ open: true, message: error.response?.data?.message || 'Error al obtener paquetes pendientes', severity: 'error' });
    } finally {
      setBalanceLoading(false);
      setOpenBalanceDialog(true);
    }
  };

  // Cerrar diálogo de balance
  const handleCloseBalanceDialog = () => {
    setOpenBalanceDialog(false);
    setCurrentOrg(null);
    setBalanceParcels([]);
  };

  // Cerrar snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>Gestión de Organizaciones</Typography>

      {/* Barra de filtros */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <TextField
          label="Nombre"
          name="name"
          value={filters.name}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
        />
        <TextField
          label="Dirección del Almacén"
          name="warehouseAddress"
          value={filters.warehouseAddress}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
        />
        <Button variant="contained" color="primary" onClick={handleOpenCreateDialog}>
          Crear Organización
        </Button>
      </div>

      {/* Tabla de organizaciones */}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Dirección del Almacén</TableCell>
                <TableCell>Administradores</TableCell>
                <TableCell>Usuarios</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizations.length > 0 ? (
                organizations.map((org) => (
                  <TableRow key={org._id}>
                    <TableCell>{org.name}</TableCell>
                    <TableCell>{org.warehouseAddress}</TableCell>
                    <TableCell>{org.admins.map(admin => admin.name).join(', ') || 'N/A'}</TableCell>
                    <TableCell>{org.users.length}</TableCell>
                    <TableCell>
                      <Button variant="text" color="primary" onClick={() => handleOpenBalanceDialog(org)}>
                        ${org.balance.toFixed(2)}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleOpenEditDialog(org)}>
                        <Edit />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleOpenDeleteDialog(org)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No se encontraron organizaciones.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Paginación */}
      <TablePagination
        component="div"
        count={totalOrganizations}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />

      {/* Diálogo para crear organización */}
      <Dialog open={openCreateDialog} onClose={handleCloseCreateDialog} fullWidth maxWidth="sm">
        <DialogTitle>Crear Nueva Organización</DialogTitle>
        <DialogContent>
          <form id="create-organization-form" onSubmit={handleSubmit(onCreateOrganization)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: 'El nombre es obligatorio' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
            />
            <Controller
              name="warehouseAddress"
              control={control}
              rules={{ required: 'La dirección del almacén es obligatoria' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Dirección del Almacén"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.warehouseAddress}
                  helperText={errors.warehouseAddress ? errors.warehouseAddress.message : ''}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancelar</Button>
          <Button type="submit" form="create-organization-form" variant="contained" color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para editar organización */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
        <DialogTitle>Editar Organización</DialogTitle>
        <DialogContent>
          <form id="edit-organization-form" onSubmit={handleSubmit(onUpdateOrganization)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: 'El nombre es obligatorio' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nombre"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                />
              )}
            />
            <Controller
              name="warehouseAddress"
              control={control}
              rules={{ required: 'La dirección del almacén es obligatoria' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Dirección del Almacén"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.warehouseAddress}
                  helperText={errors.warehouseAddress ? errors.warehouseAddress.message : ''}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancelar</Button>
          <Button type="submit" form="edit-organization-form" variant="contained" color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para confirmar eliminación */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Eliminar Organización</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar la organización <strong>{currentOrg?.name}</strong>?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={handleDeleteOrganization} variant="contained" color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para ver balance y paquetes pendientes */}
      <Dialog open={openBalanceDialog} onClose={handleCloseBalanceDialog} fullWidth maxWidth="md">
        <DialogTitle>Balance y Paquetes Pendientes de {currentOrg?.name}</DialogTitle>
        <DialogContent>
          {balanceLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Typography variant="subtitle1">Balance: ${currentOrg?.balance.toFixed(2)}</Typography>
              <Box mt={2}>
                <Typography variant="h6">Paquetes Pendientes de Desembolso:</Typography>
                {balanceParcels.length > 0 ? (
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID del Paquete</TableCell>
                          <TableCell>Monto</TableCell>
                          <TableCell>Fecha de Recolección</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {balanceParcels.map(parcel => (
                          <TableRow key={parcel._id}>
                            <TableCell>{parcel._id}</TableCell>
                            <TableCell>${parcel.amount.toFixed(2)}</TableCell>
                            <TableCell>{new Date(parcel.cashCollectedDate).toLocaleDateString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No hay paquetes pendientes para esta organización.
                  </Typography>
                )}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBalanceDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Organizaciones;
