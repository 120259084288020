// ErrorAlert.js
import { Grid, Alert } from "@mui/material";

const ErrorAlert = ({ errorMsg }) => (
  errorMsg && (
    <Grid item xs={12}>
      <Alert severity="error">{errorMsg}</Alert>
    </Grid>
  )
);

export default ErrorAlert;
