// src/pages/dashboard/components/ShipperTable.js

import React, { useState, useContext } from 'react';
import { AppContext } from '../../../contextApi/AppContext';
import ParcelDetailModal from '../../../components/Modals/ParcelDetail';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Typography, Box, useMediaQuery, useTheme, Stack, TextField, MenuItem, Grid, Accordion, AccordionSummary, AccordionDetails, Pagination
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ShipperTable = ({ onApplyFilters, totalPages, currentPage }) => {
  const { AppData } = useContext(AppContext);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Estado para los filtros
  const [filters, setFilters] = useState({
    name: '',
    status: '',
    recipientName: '',
    paymentMethod: '',
    isPaid: '',
    startDate: '',
    endDate: ''
  });

  // Estado para la paginación
  const [page, setPage] = useState(currentPage || 1);

  // Función para mapear el estado del paquete a una etiqueta amigable
  const getParcelStatusLabel = (status) => {
    switch (status) {
      case 'toCollect':
        return 'Listo para Recoger';
      case 'collecting':
        return 'En Ruta para Recoger';
      case 'inWarehouse':
        return 'En Almacén';
      case 'toDeliver':
        return 'Listo para Entregar';
      case 'delivering':
        return 'En Ruta para Entregar';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return 'Desconocido';
    }
  };

  const handleParcelSelected = (parcel) => {
    setSelectedParcel(parcel);
  };

  // Manejar cambios en los filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  // Aplicar filtros al padre
  const applyFilters = () => {
    setPage(1); // Resetear la página al aplicar nuevos filtros
    onApplyFilters({ ...filters, page: 1 });
  };

  // Manejar cambio de página
  const handlePageChange = (event, value) => {
    setPage(value);
    onApplyFilters({ ...filters, page: value });
  };

  return (
    <>
      {selectedParcel && (
        <ParcelDetailModal 
          selectedParcel={selectedParcel} 
          closeModal={() => setSelectedParcel(null)} 
        />
      )}

      {/* Encapsular los filtros dentro de un Accordion */}
      <Accordion defaultExpanded={!isSmallScreen}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Typography variant="h6">Filtros de búsqueda</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField 
                  label="Nombre" 
                  variant="outlined" 
                  name="name" 
                  value={filters.name} 
                  onChange={handleFilterChange} 
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Estado"
                  select
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="toCollect">Listo para Recoger</MenuItem>
                  <MenuItem value="collecting">En Ruta para Recoger</MenuItem>
                  <MenuItem value="inWarehouse">En Almacén</MenuItem>
                  <MenuItem value="toDeliver">Listo para Entregar</MenuItem>
                  <MenuItem value="delivering">En Ruta para Entregar</MenuItem>
                  <MenuItem value="delivered">Entregado</MenuItem>
                  <MenuItem value="cancelled">Cancelado</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField 
                  label="Nombre del destinatario" 
                  variant="outlined" 
                  name="recipientName" 
                  value={filters.recipientName} 
                  onChange={handleFilterChange} 
                  fullWidth 
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Método de Pago"
                  select
                  name="paymentMethod"
                  value={filters.paymentMethod}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="cash">Efectivo</MenuItem>
                  <MenuItem value="sinpe_transferencia">SINPE</MenuItem>
                  <MenuItem value="otro">Otro</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Pagado"
                  select
                  name="isPaid"
                  value={filters.isPaid}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Sí</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Grid>

              {/* Campos para el rango de fechas */}
              <Grid item xs={12} md={3}>
                <TextField
                  label="Fecha de inicio"
                  type="date"
                  name="startDate"
                  value={filters.startDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Fecha de fin"
                  type="date"
                  name="endDate"
                  value={filters.endDate}
                  onChange={handleFilterChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button onClick={applyFilters} variant="contained" sx={{ mt: 2 }}>
              Aplicar Filtros
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Tabla de resultados */}
      <TableContainer 
        component={Paper} 
        sx={{ 
          mt: 3, 
          mb: 3, 
          borderRadius: 2, 
          boxShadow: 4, 
          overflowX: 'auto',  
          backgroundColor: '#f9f9f9',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: 6,
          }
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: '#e0e0e0' }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444' }}>
                  Detalles del Pedido
                </Typography>
              </TableCell>
              {!isSmallScreen && (
                <>
                  <TableCell>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444' }}>
                      Estado
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444' }}>
                      Acciones
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {AppData.percels && AppData.percels.length > 0 ? (
              AppData.percels.map((parcel, index) => (
                <TableRow 
                  key={parcel._id || index} 
                  sx={{ 
                    backgroundColor: index % 2 === 0 ? '#fafafa' : '#fff', 
                    transition: 'background-color 0.3s ease',
                    '&:hover': { backgroundColor: '#f0f0f0' }
                  }}
                >
                  <TableCell>
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>
                        {parcel.name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#666' }}>
                        <strong>Recolección:</strong> {parcel.pickup_address}
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#666' }}>
                        <strong>Entrega:</strong> {parcel.dropoff_address}
                      </Typography>
                      {isSmallScreen && (
                        <Stack spacing={1} mt={1}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                              color:
                                parcel.status === 'delivered' ? 'green' :
                                parcel.status === 'cancelled' ? 'red' : 'orange',
                            }}
                          >
                            {getParcelStatusLabel(parcel.status)}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleParcelSelected(parcel)}
                            sx={{
                              fontWeight: 'bold',
                              width: '100%',
                              backgroundColor: '#1976d2',
                              '&:hover': {
                                backgroundColor: '#1565c0',
                              },
                              transition: 'all 0.2s ease',
                              padding: '10px',
                            }}
                          >
                            Ver detalles
                          </Button>
                        </Stack>
                      )}
                    </Box>
                  </TableCell>
                  {!isSmallScreen && (
                    <>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 'bold',
                            textTransform: 'capitalize',
                            color:
                              parcel.status === 'delivered' ? 'green' :
                              parcel.status === 'cancelled' ? 'red' : 'orange',
                          }}
                        >
                          {getParcelStatusLabel(parcel.status)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleParcelSelected(parcel)}
                          sx={{
                            fontWeight: 'bold',
                            width: '100%',
                            backgroundColor: '#1976d2',
                            '&:hover': {
                              backgroundColor: '#1565c0',
                            },
                            transition: 'all 0.2s ease',
                            padding: '6px 16px',
                          }}
                        >
                          Ver detalles
                        </Button>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={isSmallScreen ? 1 : 3}>
                  <Typography variant="body1" align="center" sx={{ fontWeight: 'bold', color: '#444' }}>
                    No hay envíos disponibles con los filtros aplicados.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginación */}
      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" mb={2}>
          <Pagination 
            count={totalPages} 
            page={page} 
            onChange={handlePageChange} 
            color="primary" 
            shape="rounded"
            siblingCount={isSmallScreen ? 0 : 1}
            boundaryCount={isSmallScreen ? 1 : 2}
          />
        </Box>
      )}
    </>
  );
};

export default ShipperTable;
