import axios from './axiosConfig';

// API para manejar las categorías de inventario
export const getCategories = async () => {
    return await axios.get('/inventory/category/list'); // Llama a la nueva ruta
};

// API para crear un nuevo ítem en inventario y generar una solicitud de recolección
export const createInventoryItem = async (itemData) => {
    return await axios.post('/inventory/add', itemData);
};

// API para listar todos los pickups pendientes
export const getWarehousePickups = async () => {
    return await axios.get('/warehouse-pickup/list');
};

// API para confirmar una recolección
export const confirmWarehousePickup = async (pickupId) => {
    return await axios.put(`/warehouse-pickup/confirm/${pickupId}`);
};
export const createCategory = async (categoryData) => {
    return await axios.post('/inventory/category', categoryData);
};
// Obtener el inventario con paginación
export const getInventoryItems = async (page = 1, limit = 10) => {
    return await axios.get(`/inventory/list?page=${page}&limit=${limit}`);
};
// Eliminar una categoría existente
export const deleteCategory = async (categoryId) => {
    return await axios.delete(`/inventory/category/${categoryId}`);
};
export const getInventoryDetails = async (inventoryItemIds) => {
    try {
      const response = await axios.post('/api/inventory/details', { inventoryItemIds });
      return response;
    } catch (error) {
      throw error;
    }
  };
// Nueva función para actualizar el ítem de inventario
export const updateInventoryItem = async (itemId, updateData) => {
    return await axios.put(`/inventory/update/${itemId}`, updateData);
  };
  export const getAvailableStockItems = async (page, pageSize, category, search) => {
    try {
      const params = {
        page,
        limit: pageSize,
        status: 'available', // Filtrar StockItems disponibles
      };
  
      if (category) {
        params.category = category;
      }
  
      if (search) {
        params.search = search;
      }
  
      const response = await axios.get('/api/stockitems', { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  // API para listar todas las recolecciones con paginación, búsqueda y filtros
export const getWarehousePickupsAdvanced = async (page = 1, limit = 10, search = '', status = '', startDate = '', endDate = '') => {
  const params = {
    page,
    limit,
    search,
    status,
    startDate,
    endDate,
  };
  return await axios.get('/warehouse-pickup/all', { params });
};
