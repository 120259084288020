// src/api/organizationApi.js

import axios from "./axiosConfig";

// Crear una nueva organización
export const createOrganization = (data) => {
  return axios.post("/organization/create", { ...data });
};

// Obtener una organización por ID
export const getOrganizationById = (id) => {
  return axios.get(`/organization/${id}`);
};

// Actualizar una organización
export const updateOrganization = (id, data) => {
  return axios.put(`/organization/update/${id}`, { ...data });
};

// Eliminar una organización
export const deleteOrganization = (id) => {
  return axios.delete(`/organization/delete/${id}`);
};

// Obtener todas las organizaciones con filtros y paginación
export const getAllOrganizations = (filters = {}, page = 1, limit = 10) => {
  // Crear la query string a partir de los filtros y paginación
  const query = new URLSearchParams({
    ...filters,  // Incluir los filtros que provienen del estado
    page,        // Página actual
    limit        // Límite de resultados por página
  }).toString();
  
  // Hacer la solicitud GET con axios, incluyendo los filtros y paginación en la URL
  return axios.get(`/organization?${query}`);
};

// Obtener los usuarios de una organización
export const getOrganizationUsers = (organizationId, filters = {}, page = 1, limit = 10) => {
  // Crear la query string si necesitas filtros y paginación en esta ruta
  const query = new URLSearchParams({
    ...filters,
    page,
    limit
  }).toString();

  // La ruta para obtener usuarios podría soportar paginación y filtros
  return axios.get(`/organization/${organizationId}/users?${query}`);
};

// Obtener el balance de una organización
export const getOrganizationBalance = (organizationId) => {
  return axios.get(`/organization/${organizationId}/balance`);
};

// Obtener paquetes pendientes de desembolso de una organización con filtros y paginación
export const getPendingParcels = (organizationId, filters = {}, page = 1, limit = 10) => {
  const query = new URLSearchParams({
    ...filters,
    page,
    limit
  }).toString();

  return axios.get(`/organization/${organizationId}/pending-parcels?${query}`);
};
