// src/components/Modals/CreateShipment/ValidationUtils.js

export const validateFormData = (formData, useInventory) => {
  // **Validación Condicional: Requiere pickup_address solo si no se usa inventario (useInventory !== 0)**
  if (useInventory !== 0) {
    if (!formData.pickup_address && (!formData.pickup_lat || !formData.pickup_lng)) {
      return "La dirección de recolección es requerida.";
    }
  }

  // Validación para 'dropoff_*' siempre requerida
  if (!formData.dropoff_address && (!formData.dropoff_lat || !formData.dropoff_lng)) {
    return "La dirección de entrega es requerida.";
  }

  if (!formData.recipientName) {
    return "El nombre del destinatario es requerido.";
  }

  if (!formData.recipientPhone) {
    return "El teléfono del destinatario es requerido.";
  }

  if (!formData.paymentMethod) {
    return "El método de pago es requerido.";
  }

  if (["cash", "sinpe_transferencia", "otro"].includes(formData.paymentMethod)) {
    if (!formData.amount) {
      return "El monto es requerido para el método de pago seleccionado.";
    }

    if (formData.paymentMethod === "cash" && formData.changeNeeded && !formData.totalChange) {
      return "El cambio total es requerido si se necesita cambio.";
    }

    if (formData.paymentMethod === "otro" && !formData.otherDetails) {
      return "Detalles adicionales son requeridos para el método de pago 'otro'.";
    }
    if (formData.changeNeeded && parseFloat(formData.totalChange) > parseFloat(formData.amount)) {
      return "El vuelto no puede ser mayor al monto a cobrar.";
    }
  }

  // Validaciones adicionales según sea necesario

  return null; // No hay errores
};
