// src/components/Modals/CreateShipment/MapAddressPage.js

import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, Autocomplete } from '@react-google-maps/api';
import { Box, TextField, Button, Typography, Paper, Stack } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';
import useGoogleMaps from '../../hooks/useGoogleMaps';

const containerStyle = {
  width: '100%',
  height: '500px',
};

const defaultCenter = {
  lat: 9.9281,
  lng: -84.0907,
};

function MapAddressPage({ addressType, onSaveAddress, currentAddress }) {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [existingZones, setExistingZones] = useState([]);
  const { loaded, error } = useGoogleMaps(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  const [autocomplete, setAutocomplete] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  // Establecer el marcador si currentAddress tiene coordenadas
  useEffect(() => {
    if (currentAddress && currentAddress.latitude && currentAddress.longitude) {
      const pos = {
        lat: currentAddress.latitude,
        lng: currentAddress.longitude,
      };
      setMarker({ position: pos });
      if (map) {
        map.panTo(pos);
      }
    }
  }, [currentAddress, map]);

  useEffect(() => {
    if (map) {
      const geocoderInstance = new window.google.maps.Geocoder();
      setGeocoder(geocoderInstance);
    }
  }, [map]);

  const handleMapClick = (event) => {
    if (!event.latLng) {
      console.warn('Map click event did not return latLng');
      return;
    }
  
    const latLng = event.latLng.toJSON();
    setMarker({ position: latLng });
  
    if (map) {
      map.panTo(latLng);
    }
  
    if (geocoder) {
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const addressData = {
            address: results[0].formatted_address,
            latitude: latLng.lat,
            longitude: latLng.lng,
          };
          console.log('Address selected:', addressData);  // Verificación adicional
          onSaveAddress(addressData);
        } else {
          console.error('Geocoder failed or no results found');
        }
      });
    } else {
      console.log('Geocoder is not available');
    }
  };
  

  const onLoadAutocomplete = (autoC) => {
    setAutocomplete(autoC);
    console.log('Autocomplete loaded');
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const latLng = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setMarker({ position: latLng });
        if (map) {
          map.panTo(latLng);
        }

        onSaveAddress({
          address: place.formatted_address,
          latitude: latLng.lat,
          longitude: latLng.lng,
        });

        console.log('Selected place:', latLng);
      } else {
        console.log('No details available for input: ' + place.name);
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const saveAddress = () => {
    if (!marker) {
      alert('Por favor, coloque un marcador en el mapa.');
      return;
    }

    // Ya se maneja la geocodificación inversa en handleMapClick y onPlaceChanged
    alert('Dirección guardada correctamente.');
  };

  // Geolocalización del usuario
  const handleGeoLocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setMarker({ position: pos });
          if (map) {
            map.panTo(pos);
          }

          // Realizar geocodificación inversa para obtener la dirección
          if (geocoder) {
            geocoder.geocode({ location: pos }, (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  onSaveAddress({
                    address: results[0].formatted_address,
                    latitude: pos.lat,
                    longitude: pos.lng,
                  });
                } else {
                  console.error('No results found');
                }
              } else {
                console.error('Geocoder failed due to: ' + status);
              }
            });
          }
        },
        () => {
          alert('Error al obtener tu ubicación');
        }
      );
    } else {
      alert('La geolocalización no es compatible con este navegador.');
    }
  };

  // Fetch zonas existentes
  const fetchExistingZones = async () => {
    try {
      const response = await axios.get('/coverage-zone/list');
      console.log('Zonas recibidas:', response.data.zones);
      setExistingZones(response.data.zones);
    } catch (error) {
      console.error('Error al cargar las zonas existentes', error);
    }
  };

  // Cargar las zonas de cobertura en el mapa
  const addZonesToMap = () => {
    if (map && existingZones.length > 0) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });

      existingZones.forEach((zone) => {
        const coordinates = zone.geometry.coordinates[0].map((coord) => [coord[0], coord[1]]);
        const firstCoord = coordinates[0];
        const lastCoord = coordinates[coordinates.length - 1];

        if (firstCoord[0] !== lastCoord[0] || firstCoord[1] !== lastCoord[1]) {
          coordinates.push(firstCoord);
        }

        console.log('Añadiendo zona GeoJSON al mapa con coordenadas invertidas:', coordinates);

        try {
          map.data.addGeoJson({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          });
        } catch (e) {
          console.error('Error al añadir el GeoJSON:', e);
        }
      });

      map.data.setStyle({
        fillColor: 'blue',
        fillOpacity: 0.2,
        strokeColor: 'blue',
        strokeWeight: 2,
        clickable: false,
      });

      setTimeout(() => {
        map.panTo(defaultCenter);
        map.setZoom(10);
      }, 1000);
    }
  };

  useEffect(() => {
    fetchExistingZones();
  }, []);

  useEffect(() => {
    if (map) {
      addZonesToMap();
    }
  }, [map, existingZones]);

  if (error) {
    return <Typography color="error">Error cargando Google Maps</Typography>;
  }

  if (!loaded) {
    return <Typography>Cargando...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: '600px', margin: 'auto', padding: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
        Seleccione su Dirección de {addressType === 'pickup' ? 'Recolección' : 'Entrega'}
      </Typography>

      <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
        Use el buscador o haga clic en el mapa para seleccionar su dirección.
      </Typography>

      {/* Barra de búsqueda */}
      <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
        <TextField
          placeholder="Buscar un lugar"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
        />
      </Autocomplete>

      {/* Mapa */}
      <Paper elevation={3} sx={{ mb: 3 }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultCenter}
          zoom={10}
          onClick={handleMapClick}
          onLoad={(mapInstance) => {
            console.log('Map loaded');
            setMap(mapInstance);
          }}
        >
          {marker && <Marker position={marker.position} />}
        </GoogleMap>
      </Paper>

      {/* Botones de acción */}
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleGeoLocate}
          startIcon={<MyLocationIcon />}
        >
          Ubicarme
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={saveAddress}
          startIcon={<SaveIcon />}
          disabled={!marker}
        >
          Guardar Dirección
        </Button>
      </Stack>
    </Box>
  );
}

export default React.memo(MapAddressPage);
