// src/pages/bikerDashboard/components/RouteManagement.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem,
  Card, CardContent, Typography, CircularProgress, FormControl, InputLabel, IconButton, useMediaQuery, Collapse, Pagination
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Edit, Delete, ExpandMore, ExpandLess } from '@mui/icons-material';
import { addRoute, editRoute, deleteRoute, getAllRoutes, assignMessenger, getAllBikers } from '../../../Apis/routes';
import UnassignedParcelsModal from './UnassignedParcelsModal';
import ViewRouteModal from '../../../components/Modals/ViewRouteModal';
import { SocketContext } from '../../../contextApi/SocketContext';
import { AppContext } from '../../../contextApi/AppContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RouteManagement = () => {
  const { AppData, dispatch } = useContext(AppContext);
  const { socket, isConnected } = useContext(SocketContext);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [openUnassignedModal, setOpenUnassignedModal] = useState(false);
  const [openViewRouteModal, setOpenViewRouteModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [bikers, setBikers] = useState([]);
  const [selectedBiker, setSelectedBiker] = useState('');
  const [selectedParcels, setSelectedParcels] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [routeData, setRouteData] = useState({
    name: '',
    timeSlot: '',
    parcels: [],
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [filter, setFilter] = useState({
    name: '',
    status: '',
    biker: '',
    timeSlot: '',
  });

  // Estados para la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
    setCurrentPage(1); // Reiniciar a la primera página cuando cambian los filtros
  };

  const fetchRoutes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllRoutes(currentPage, filter);
      const responseData = response.data;

      console.log("Respuesta de la API:", responseData); // Para depuración

      if (responseData?.success && Array.isArray(responseData.data)) {
        setRoutes(responseData.data); // Establecer rutas
        setTotalPages(responseData.totalPages || 1); // Establecer total de páginas
      } else {
        setRoutes([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching routes:', error);
      setRoutes([]);
      setTotalPages(1);
      toast.error("Error al obtener las rutas.");
    } finally {
      setLoading(false);
    }
  }, [filter, currentPage]);

  const fetchBikers = useCallback(async () => {
    try {
      const response = await getAllBikers();
      const bikersData = response.data.data;
      if (response.data.success && Array.isArray(bikersData)) {
        setBikers(bikersData);
      } else {
        setBikers([]);
      }
    } catch (error) {
      console.error('Error fetching bikers:', error);
      setBikers([]);
      toast.error("Error al obtener los mensajeros.");
    }
  }, []);

  useEffect(() => {
    fetchRoutes();
    fetchBikers();
  }, [fetchRoutes, fetchBikers]);

  const handleAssignParcels = (parcels) => {
    setSelectedParcels(parcels);
    setOpenUnassignedModal(false);
  };

  const handleAddRoute = async () => {
    try {
      const newRouteData = {
        ...routeData,
        parcels: selectedParcels,
        ...(routeData.name ? { name: routeData.name } : {}),
      };
      await addRoute(newRouteData);
      toast.success("Ruta agregada exitosamente.");
      setOpenAddDialog(false);
      setRouteData({ name: '', timeSlot: '', parcels: [] });
      setSelectedParcels([]);
      fetchRoutes();
    } catch (error) {
      console.error('Error adding route:', error);
      toast.error("Error al agregar la ruta.");
    }
  };

  const handleEditRoute = async () => {
    if (!selectedRoute || !selectedRoute._id) {
      toast.error("Ruta no seleccionada.");
      return;
    }
    try {
      await editRoute(selectedRoute._id, routeData);
      toast.success("Ruta editada exitosamente.");
      setOpenEditDialog(false);
      setSelectedRoute(null);
      setRouteData({ name: '', timeSlot: '', parcels: [] });
      fetchRoutes();
    } catch (error) {
      console.error('Error editing route:', error);
      toast.error("Error al editar la ruta.");
    }
  };

  const handleDeleteRoute = async (routeID) => {
    try {
      await deleteRoute(routeID);
      toast.success("Ruta eliminada exitosamente.");
      fetchRoutes();
    } catch (error) {
      console.error('Error deleting route:', error);
      toast.error("Error al eliminar la ruta.");
    }
  };

  const handleAssignMessenger = async () => {
    if (!selectedRoute || !selectedRoute._id) {
      toast.error("Ruta no seleccionada.");
      return;
    }
    try {
      await assignMessenger(selectedRoute._id, selectedBiker);
      toast.success("Mensajero asignado exitosamente.");
      setOpenAssignDialog(false);
      setSelectedRoute(null);
      setSelectedBiker('');
      fetchRoutes();
    } catch (error) {
      console.error('Error assigning messenger:', error);
      toast.error("Error al asignar el mensajero.");
    }
  };

  const getParcelStatusLabel = (status) => {
    switch (status) {
      case 'toCollect':
        return 'Listo para Recoger';
      case 'collecting':
        return 'En Ruta para Recoger';
      case 'inWarehouse':
        return 'En Almacén';
      case 'toDeliver':
        return 'Listo para Entregar';
      case 'delivering':
        return 'En Ruta para Entregar';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return 'Desconocido';
    }
  };

  const handleExpandClick = (routeId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [routeId]: !prevExpanded[routeId],
    }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Integración con Socket.io para actualizaciones en tiempo real
  useEffect(() => {
    if (!socket || !isConnected) {
      console.warn("Socket no está disponible o no está conectado en RouteManagement.");
      return;
    }

    // Evento: routeCreated
    const handleRouteCreated = (data) => {
      console.log('Nueva ruta creada:', data);
      setRoutes((prevRoutes) => [data.route, ...prevRoutes]);
      toast.info("Nueva ruta creada.");
    };

    // Evento: routeUpdated
    const handleRouteUpdated = (data) => {
      console.log('Ruta actualizada:', data);
      setRoutes((prevRoutes) => prevRoutes.map(route => route._id === data.route._id ? data.route : route));
      toast.info("Ruta actualizada.");
    };

    // Evento: routeDeleted
    const handleRouteDeleted = (data) => {
      console.log('Ruta eliminada:', data);
      setRoutes((prevRoutes) => prevRoutes.filter(route => route._id !== data.routeId));
      toast.info("Ruta eliminada.");
    };

    // Registrar eventos
    socket.on('routeCreated', handleRouteCreated);
    socket.on('routeUpdated', handleRouteUpdated);
    socket.on('routeDeleted', handleRouteDeleted);

    // Limpiar los listeners al desmontar el componente
    return () => {
      socket.off('routeCreated', handleRouteCreated);
      socket.off('routeUpdated', handleRouteUpdated);
      socket.off('routeDeleted', handleRouteDeleted);
    };
  }, [socket, isConnected]);

  // Asegurarse de que 'routes' sea siempre un arreglo
  const routesToRender = Array.isArray(routes) ? routes : [];

  return (
    <div style={{ padding: '1rem', maxWidth: '100%' }}>
      {/* Filtros de búsqueda */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '1rem' }}>
        <TextField
          label="Buscar por nombre"
          name="name"
          value={filter.name}
          onChange={handleFilterChange}
          fullWidth={isSmallScreen}
        />
        <FormControl fullWidth={isSmallScreen}>
          <InputLabel id="status-label">Estado</InputLabel>
          <Select
            labelId="status-label"
            name="status"
            value={filter.status}
            onChange={handleFilterChange}
            fullWidth={isSmallScreen}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="pending">Pendiente</MenuItem>
            <MenuItem value="inProgress">En Progreso</MenuItem>
            <MenuItem value="completed">Completado</MenuItem>
            <MenuItem value="cancelled">Cancelado</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth={isSmallScreen}>
          <InputLabel id="biker-label">Mensajero</InputLabel>
          <Select
            labelId="biker-label"
            name="biker"
            value={filter.biker}
            onChange={handleFilterChange}
            fullWidth={isSmallScreen}
          >
            <MenuItem value="">Todos</MenuItem>
            {bikers.map((biker) => (
              <MenuItem key={biker._id} value={biker._id}>
                {biker.first_name} {biker.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth={isSmallScreen}>
          <InputLabel id="timeSlot-label">Franja Horaria</InputLabel>
          <Select
            labelId="timeSlot-label"
            name="timeSlot"
            value={filter.timeSlot}
            onChange={handleFilterChange}
            fullWidth={isSmallScreen}
          >
            <MenuItem value="">Todas</MenuItem>
            <MenuItem value="morning">Mañana</MenuItem>
            <MenuItem value="afternoon">Tarde</MenuItem>
            <MenuItem value="evening">Noche</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => setOpenAddDialog(true)}
        sx={{ mr: 2 }}
      >
        Agregar Ruta
      </Button>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={() => setOpenUnassignedModal(true)}
      >
        Asignar Paquetes
      </Button>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          routesToRender.length > 0 ? (
            routesToRender.map(route => (
              <Card key={route._id}>
                <CardContent>
                  <Typography variant="h6">{route.name || 'Sin Nombre'}</Typography>
                  <Typography variant="body1"><strong>Franja Horaria:</strong> {route.timeSlot}</Typography>
                  <Typography variant="body1"><strong>Mensajero:</strong> {route.biker ? `${route.biker.first_name} ${route.biker.last_name}` : 'Sin Asignar'}</Typography>
                  <Typography variant="body1"><strong>Estado:</strong> {route.status}</Typography>
                  <Button
                    onClick={() => handleExpandClick(route._id)}
                    endIcon={expanded[route._id] ? <ExpandLess /> : <ExpandMore />}
                  >
                    Ver Paquetes
                  </Button>
                  <Collapse in={expanded[route._id]} timeout="auto" unmountOnExit>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      <strong>Paquetes:</strong>
                      {route.parcels && Array.isArray(route.parcels) && route.parcels.length > 0 ? route.parcels.map(parcel => (
                        <div key={parcel._id}>{parcel.name}: {getParcelStatusLabel(parcel.status)}</div>
                      )) : 'No hay paquetes'}
                    </Typography>
                  </Collapse>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem', flexWrap: 'wrap', gap: '0.5rem' }}>
                    <IconButton 
                      onClick={() => { 
                        setSelectedRoute(route); 
                        setRouteData({ name: route.name || '', timeSlot: route.timeSlot || '', parcels: route.parcels || [] });
                        setOpenEditDialog(true); 
                      }}
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton 
                      onClick={() => handleDeleteRoute(route._id)}
                      color="secondary"
                    >
                      <Delete />
                    </IconButton>
                    <Button 
                      variant="outlined" 
                      onClick={() => { 
                        setSelectedRoute(route); 
                        setOpenAssignDialog(true); 
                      }}
                    >
                      Asignar Mensajero
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => { 
                        setSelectedRoute(route); 
                        setOpenViewRouteModal(true); 
                      }}
                    >
                      Ver Detalles
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography variant="body1">No hay rutas disponibles.</Typography>
          )
        )}
      </div>

      {/* Controles de Paginación */}
      {!loading && totalPages > 1 && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
          <Pagination 
            count={totalPages} 
            page={currentPage} 
            onChange={handlePageChange} 
            color="primary"
          />
        </div>
      )}

      {/* Diálogo para Agregar Ruta */}
      <Dialog 
        fullWidth={isSmallScreen} 
        maxWidth="sm" 
        open={openAddDialog} 
        onClose={() => { 
          setOpenAddDialog(false); 
          setRouteData({ name: '', timeSlot: '', parcels: [] }); 
          setSelectedParcels([]); 
        }}
      >
        <DialogTitle>Agregar Nueva Ruta</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre (Opcional)"
            value={routeData.name}
            onChange={(e) => setRouteData({ ...routeData, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Franja Horaria</InputLabel>
            <Select
              value={routeData.timeSlot}
              onChange={(e) => setRouteData({ ...routeData, timeSlot: e.target.value })}
            >
              <MenuItem value="morning">Mañana</MenuItem>
              <MenuItem value="afternoon">Tarde</MenuItem>
              <MenuItem value="evening">Noche</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => { 
              setOpenAddDialog(false); 
              setRouteData({ name: '', timeSlot: '', parcels: [] }); 
              setSelectedParcels([]); 
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleAddRoute} 
            color="primary" 
            variant="contained"
            disabled={!routeData.timeSlot}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para Editar Ruta */}
      <Dialog 
        fullWidth={isSmallScreen} 
        maxWidth="sm" 
        open={openEditDialog} 
        onClose={() => { 
          setOpenEditDialog(false); 
          setSelectedRoute(null); 
          setRouteData({ name: '', timeSlot: '', parcels: [] }); 
        }}
      >
        <DialogTitle>Editar Ruta</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre (Opcional)"
            value={routeData.name}
            onChange={(e) => setRouteData({ ...routeData, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Franja Horaria</InputLabel>
            <Select
              value={routeData.timeSlot}
              onChange={(e) => setRouteData({ ...routeData, timeSlot: e.target.value })}
            >
              <MenuItem value="morning">Mañana</MenuItem>
              <MenuItem value="afternoon">Tarde</MenuItem>
              <MenuItem value="evening">Noche</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => { 
              setOpenEditDialog(false); 
              setSelectedRoute(null); 
              setRouteData({ name: '', timeSlot: '', parcels: [] }); 
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleEditRoute} 
            color="primary" 
            variant="contained"
            disabled={!routeData.timeSlot}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para Asignar Mensajero */}
      <Dialog 
        fullWidth={isSmallScreen} 
        maxWidth="sm" 
        open={openAssignDialog} 
        onClose={() => { 
          setOpenAssignDialog(false); 
          setSelectedRoute(null); 
          setSelectedBiker(''); 
        }}
      >
        <DialogTitle>Asignar Mensajero</DialogTitle>
        <DialogContent>
          {bikers.length === 0 ? (
            <CircularProgress />
          ) : (
            <FormControl fullWidth>
              <InputLabel>Seleccione Mensajero</InputLabel>
              <Select
                value={selectedBiker}
                onChange={(e) => setSelectedBiker(e.target.value)}
              >
                {bikers.map(biker => (
                  <MenuItem key={biker._id} value={biker._id}>
                    {biker.first_name} {biker.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => { 
              setOpenAssignDialog(false); 
              setSelectedRoute(null); 
              setSelectedBiker(''); 
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleAssignMessenger} 
            color="primary" 
            variant="contained"
            disabled={!selectedBiker || !selectedRoute}
          >
            Asignar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Paquetes No Asignados */}
      <UnassignedParcelsModal
        open={openUnassignedModal}
        onClose={() => setOpenUnassignedModal(false)}
        onAssignParcels={handleAssignParcels}
      />

      {/* Modal para Ver Detalles de la Ruta */}
      {openViewRouteModal && selectedRoute && (
        <ViewRouteModal
          open={openViewRouteModal}
          onClose={() => { setSelectedRoute(null); setOpenViewRouteModal(false); }}
          route={selectedRoute}
          refreshRoutes={fetchRoutes}
          onEditRoute={handleEditRoute}
        />
      )}
    </div>
  );
};

export default RouteManagement;
