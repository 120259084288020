// src/components/ErrorBoundary.js

import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para renderizar una interfaz alternativa
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Puedes registrar el error en un servicio de reporte de errores
    console.error("ErrorBoundary caught an error", error, info);
  }

  render() {
    if (this.state.hasError) {
      // Renderizar una interfaz alternativa sin desmontar todo el árbol
      return <h1>Ocurrió un error.</h1>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
