// src/contextApi/AppContext.js

import React, { createContext, useReducer, useEffect } from "react";
import AppReducers from "./AppReducers";
import InitState from "./InitState";
import Cookies from "universal-cookie";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const cookies = new Cookies();
  const userCookie = cookies.get("user");

  // Validamos si userCookie es un objeto; si no, intentamos parsearlo
  const initialState = {
    ...InitState,
    user: typeof userCookie === "string" ? JSON.parse(userCookie) : userCookie,
  };

  const [AppData, dispatch] = useReducer(AppReducers, initialState);

  useEffect(() => {
    console.log("Estado actualizado en AppProvider:", AppData);
  }, [AppData]);

  return (
    <AppContext.Provider value={{ AppData, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
