// src/components/Modals/ParcelDetailModal.jsx

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Paper,
  Card,
  CardContent,
  Stack,
  Snackbar,
  Alert,
  DialogContentText,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { updateParcel } from '../../Apis/parcel'; // Ajustar ruta si es necesario
import { getInventoryDetails } from '../../Apis/inventoryAPI'; // Asegúrate de implementar esta función

const containerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = { lat: 9.9281, lng: -84.0907 }; // San José, Costa Rica por defecto

const pickupIcon = {
  url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
};

const dropoffIcon = {
  url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
};

// **1. Añadir 'pagado' a las opciones de métodos de pago**
const paymentMethodsOptions = [
  { value: 'pagado', label: 'Pagado' }, // Nueva opción añadida
  { value: 'cash', label: 'Efectivo' },
  { value: 'sinpe_transferencia', label: 'SINPE Móvil' },
  { value: 'otro', label: 'Otro' },
];

const ParcelDetailModal = ({ selectedParcel, onClose, refreshOrders }) => {
  const [pickupMarker, setPickupMarker] = useState(null);
  const [dropoffMarker, setDropoffMarker] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [paymentDetails, setPaymentDetails] = useState([]); // Detalles de pago
  const [paymentError, setPaymentError] = useState(''); // Error en pagos
  const [inventoryItemsDetails, setInventoryItemsDetails] = useState([]); // Detalles de inventario

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (isLoaded && selectedParcel) {
      // Manejar Pickup Address si existe
      if (selectedParcel.pickup_address) {
        const pickupCoordsMatch = selectedParcel.pickup_address.match(/Lat:\s*(-?\d+\.\d+),\s*Lng:\s*(-?\d+\.\d+)/);
        if (pickupCoordsMatch) {
          setPickupMarker({
            lat: parseFloat(pickupCoordsMatch[1]),
            lng: parseFloat(pickupCoordsMatch[2]),
          });
          console.log('Pickup Marker:', {
            lat: parseFloat(pickupCoordsMatch[1]),
            lng: parseFloat(pickupCoordsMatch[2]),
          });
        }
      }

      // Manejar Dropoff Address si existe
      if (selectedParcel.dropoff_address) {
        const dropoffCoordsMatch = selectedParcel.dropoff_address.match(/Lat:\s*(-?\d+\.\d+),\s*Lng:\s*(-?\d+\.\d+)/);
        if (dropoffCoordsMatch) {
          setDropoffMarker({
            lat: parseFloat(dropoffCoordsMatch[1]),
            lng: parseFloat(dropoffCoordsMatch[2]),
          });
          console.log('Dropoff Marker:', {
            lat: parseFloat(dropoffCoordsMatch[1]),
            lng: parseFloat(dropoffCoordsMatch[2]),
          });
        }
      }

      // Si el envío se creó desde el inventario, obtener detalles de los productos
      if (selectedParcel.inventoryItemIds && selectedParcel.inventoryItemIds.length > 0) {
        fetchInventoryDetails(selectedParcel.inventoryItemIds);
      }
    }
  }, [isLoaded, selectedParcel]);

  /**
   * Función para obtener los detalles de los ítems de inventario seleccionados.
   * @param {Array} inventoryItemIds - Array de IDs de inventario.
   */
  const fetchInventoryDetails = async (inventoryItemIds) => {
    try {
      const response = await getInventoryDetails(inventoryItemIds); // Debes implementar esta función en tu API
      setInventoryItemsDetails(response.data.data);
      console.log('Detalles de inventario:', response.data.data);
    } catch (error) {
      console.error("Error fetching inventory details:", error);
    }
  };

  const handleActionClick = (type) => {
    setActionType(type);
    setConfirmationDialogOpen(true);
    // Resetear valores al abrir el diálogo de confirmación
    setPaymentDetails([]);
    setPaymentError('');
    console.log(`Acción seleccionada: ${type}`);
  };

  const handleAddPaymentMethod = () => {
    setPaymentDetails([...paymentDetails, { method: '', amount: 0 }]);
    console.log('Añadido método de pago:', { method: '', amount: 0 });
  };

  const handleRemovePaymentMethod = (index) => {
    const newPayments = [...paymentDetails];
    console.log('Removiendo método de pago en el índice:', index);
    newPayments.splice(index, 1);
    setPaymentDetails(newPayments);
  };

  const handlePaymentChange = (index, field, value) => {
    const newPayments = [...paymentDetails];
    newPayments[index][field] = value;
    setPaymentDetails(newPayments);
    console.log(`Actualizado método de pago ${index}:`, newPayments[index]);
  };

  const handleConfirmAction = async () => {
    let status;

    if (actionType === 'delivered') {
      if (selectedParcel.paymentMethod === 'pagado') {
        console.log('Método de pago es "pagado", no se requieren detalles de pago.');
      } else {
        if (paymentDetails.length === 0) {
          setPaymentError("Debes proporcionar al menos un método de pago.");
          console.log('Error: Debes proporcionar al menos un método de pago.');
          return;
        }

        const totalPayments = paymentDetails.reduce((acc, payment) => acc + parseFloat(payment.amount || 0), 0);
        console.log(`Total de pagos: ${totalPayments}, Monto del pedido: ${selectedParcel.amount}`);

        if (totalPayments < parseFloat(selectedParcel.amount)) {
          setPaymentError("La suma de los pagos debe ser al menos igual al monto del pedido.");
          console.log('Error: La suma de los pagos es menor al monto del pedido.');
          return;
        }

        for (let i = 0; i < paymentDetails.length; i++) {
          if (!paymentDetails[i].method) {
            setPaymentError(`Selecciona el método de pago para el pago ${i + 1}.`);
            console.log(`Error: Selecciona el método de pago para el pago ${i + 1}.`);
            return;
          }
          if (paymentDetails[i].amount <= 0) {
            setPaymentError(`El monto debe ser mayor a 0 para el pago ${i + 1}.`);
            console.log(`Error: El monto debe ser mayor a 0 para el pago ${i + 1}.`);
            return;
          }
        }
      }
    }

    switch (actionType) {
      case 'collected':
        status = 'collected';
        break;
      case 'inWarehouse':
        status = 'inWarehouse';
        break;
      case 'delivered':
        status = 'delivered';
        break;
      case 'cancelled':
        status = 'cancelled';
        break;
      default:
        status = selectedParcel.status;
    }

    console.log(`Actualizando el estado del paquete a: ${status}`);

    try {
      const updateData = { status };
      if (actionType === 'delivered' && selectedParcel.paymentMethod !== 'pagado') {
        updateData.payments = paymentDetails;
        console.log('Asignando pagos:', paymentDetails);
      }

      const response = await updateParcel(selectedParcel._id, updateData);
      console.log('Respuesta de updateParcel:', response);

      if (response.status === 200 && response.data.success) { // Verificar que la respuesta es exitosa
        setSnackbarMessage(`Pedido marcado como ${getActionLabel(actionType)}`);
        setSnackbarOpen(true);
        console.log('Pedido actualizado exitosamente.');
        onClose(); // Cambiado de closeModal a onClose
        // Refrescar los pedidos en el componente padre
        await refreshOrders(); // Esperar a que se refresquen los pedidos
      } else {
        setSnackbarMessage('Error al actualizar el estado del pedido');
        setSnackbarOpen(true);
        console.error('Respuesta inesperada de la API:', response.data);
      }
    } catch (error) {
      setSnackbarMessage('Error al actualizar el estado del pedido');
      setSnackbarOpen(true);
      console.error('Error al actualizar el estado del pedido:', error);
    }
    setConfirmationDialogOpen(false);
  };

  const getActionLabel = (type) => {
    switch (type) {
      case 'collected':
        return 'Recolectado';
      case 'inWarehouse':
        return 'En Almacén';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return '';
    }
  };

  const getParcelStatusLabel = (status) => {
    switch (status) {
      case 'toCollect':
        return 'Listo para Recoger';
      case 'collecting':
        return 'En Ruta para Recoger';
      case 'inWarehouse':
        return 'En Almacén';
      case 'delivering':
        return 'En Ruta para Entregar';
      case 'delivered':
        return 'Entregado';
      case 'cancelled':
        return 'Cancelado';
      case 'collected':
        return 'Recolectado';
      default:
        return 'Desconocido';
    }
  };

  const getGoogleMapsLink = (lat, lng) => `https://www.google.com/maps?q=${lat},${lng}`;
  const getWazeLink = (lat, lng) => `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;
  const getWhatsAppLink = (phone) => `https://wa.me/${phone.replace(/[^0-9]/g, '')}`;
  const getPhoneLink = (phone) => `tel:${phone.replace(/[^0-9]/g, '')}`;

  if (!selectedParcel) {
    return null;
  }

  if (loadError) {
    return <Typography color="error">Error al cargar Google Maps</Typography>;
  }

  return (
    <Dialog open={Boolean(selectedParcel)} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Detalles del Pedido</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="body1">
                <strong>Identificador del pedido:</strong> {selectedParcel.clientParcelId}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="body1">
                <strong>Estado:</strong> {getParcelStatusLabel(selectedParcel.status)}
              </Typography>
            </Paper>
          </Grid>

          {selectedParcel.pickup_address || (selectedParcel.inventoryItemIds && selectedParcel.inventoryItemIds.length > 0) ? (
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1"><strong>Origen del Envío</strong></Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedParcel.pickup_address ? (
                    <>
                      <Typography variant="body1">{selectedParcel.pickup_address}</Typography>
                      {pickupMarker && (
                        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                          <Button variant="contained" color="primary" href={getGoogleMapsLink(pickupMarker.lat, pickupMarker.lng)} target="_blank">
                            Abrir en Google Maps
                          </Button>
                          <Button variant="contained" color="secondary" href={getWazeLink(pickupMarker.lat, pickupMarker.lng)} target="_blank">
                            Abrir en Waze
                          </Button>
                        </Stack>
                      )}
                    </>
                  ) : (
                    // Mostrar los productos seleccionados desde el inventario
                    <>
                      <Typography variant="body1"><strong>Productos Seleccionados:</strong></Typography>
                      <ul>
                        {inventoryItemsDetails.map((item) => (
                          <li key={item._id}>
                            {item.productTitle} (SKU: {item.sku}) - Cantidad Disponible: {item.quantityAvailable}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body1"><strong>Dirección de Entrega</strong></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{selectedParcel.dropoff_address}</Typography>
                {dropoffMarker && (
                  <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                    <Button variant="contained" color="primary" href={getGoogleMapsLink(dropoffMarker.lat, dropoffMarker.lng)} target="_blank">
                      Abrir en Google Maps
                    </Button>
                    <Button variant="contained" color="secondary" href={getWazeLink(dropoffMarker.lat, dropoffMarker.lng)} target="_blank">
                      Abrir en Waze
                    </Button>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            {isLoaded ? (
              <Box sx={{ height: '400px', width: '100%', borderRadius: 2, overflow: 'hidden', boxShadow: 3 }}>
                <GoogleMap mapContainerStyle={containerStyle} center={pickupMarker || dropoffMarker || defaultCenter} zoom={12}>
                  {pickupMarker && <Marker position={pickupMarker} title="Recolección" icon={pickupIcon} />}
                  {dropoffMarker && <Marker position={dropoffMarker} title="Entrega" icon={dropoffIcon} />}
                </GoogleMap>
              </Box>
            ) : (
              <Skeleton variant="rectangular" width="100%" height={400} />
            )}
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="body1">
                  <strong>Nombre del destinatario:</strong> {selectedParcel.recipientName}
                </Typography>
                <Typography variant="body1">
                  <strong>Teléfono del destinatario:</strong> {selectedParcel.recipientPhone}
                </Typography>
                <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                  <Button variant="contained" color="primary" href={getWhatsAppLink(selectedParcel.recipientPhone)} target="_blank">
                    Enviar WhatsApp
                  </Button>
                  <Button variant="contained" color="secondary" href={getPhoneLink(selectedParcel.recipientPhone)}>
                    Llamar
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <strong>Método de Pago:</strong> {selectedParcel.paymentMethod === 'pagado' ? 'Pagado' : selectedParcel.paymentMethod}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <strong>Detalles de Pago:</strong> {selectedParcel.paymentMethod === 'pagado' && selectedParcel.changeNeeded ? `Cambio requerido: $${selectedParcel.totalChange}` : selectedParcel.amount}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1">
              <strong>Fecha de creación:</strong> {new Date(selectedParcel.createdAt).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>

        {/* Botones según el estado */}
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          {selectedParcel.status === 'collecting' && (
            <>
              <Button variant="contained" color="success" onClick={() => handleActionClick('collected')}>
                Marcar como Recolectado
              </Button>
              <Button variant="contained" color="error" onClick={() => handleActionClick('cancelled')}>
                Cancelar Pedido
              </Button>
            </>
          )}

          {selectedParcel.status === 'collected' && (
            <>
              <Button variant="contained" color="warning" onClick={() => handleActionClick('inWarehouse')}>
                Marcar como En Almacén
              </Button>
              <Button variant="contained" color="success" onClick={() => handleActionClick('delivered')}>
                Completar Pedido
              </Button>
              <Button variant="contained" color="error" onClick={() => handleActionClick('cancelled')}>
                Cancelar Pedido
              </Button>
            </>
          )}

          {selectedParcel.status === 'delivering' && (
            <>
              <Button variant="contained" color="success" onClick={() => handleActionClick('delivered')}>
                Completar Pedido
              </Button>
              <Button variant="contained" color="error" onClick={() => handleActionClick('cancelled')}>
                Cancelar Pedido
              </Button>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose} // Cambiado de closeModal a onClose
          fullWidth
          sx={{ backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
        >
          Cerrar
        </Button>
      </DialogActions>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
        <DialogTitle>Confirmar Acción</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Estás a punto de marcar el pedido como ${getActionLabel(actionType)}. ¿Deseas continuar?`}
          </DialogContentText>
          {/* **4. Condicionalmente renderizar detalles de pago solo si el método de pago no es 'pagado'** */}
          {actionType === 'delivered' && selectedParcel.paymentMethod !== 'pagado' && (
            <>
              <Typography sx={{ mt: 2 }}>Monto total del pedido: ${selectedParcel.amount}</Typography>
              <Typography sx={{ mt: 1 }}>Selecciona los métodos de pago y especifica los montos correspondientes:</Typography>
              {paymentDetails.map((payment, index) => (
                <Box key={index} sx={{ mt: 2, border: '1px solid #ccc', borderRadius: 2, p: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <FormControl fullWidth>
                        <InputLabel id={`payment-method-label-${index}`}>Método de Pago</InputLabel>
                        <Select
                          labelId={`payment-method-label-${index}`}
                          id={`payment-method-${index}`}
                          value={payment.method}
                          label="Método de Pago"
                          onChange={(e) => handlePaymentChange(index, 'method', e.target.value)}
                        >
                          {paymentMethodsOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        label="Monto"
                        type="number"
                        fullWidth
                        value={payment.amount}
                        onChange={(e) => handlePaymentChange(index, 'amount', e.target.value)}
                        inputProps={{ min: 0, step: '0.01' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button variant="outlined" color="error" onClick={() => handleRemovePaymentMethod(index)}>
                        Remover
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button variant="outlined" color="primary" onClick={handleAddPaymentMethod} sx={{ mt: 2 }}>
                Añadir Método de Pago
              </Button>
              {paymentError && (
                <Typography color="error" sx={{ mt: 2 }}>
                  {paymentError}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)} color="secondary">Cancelar</Button>
          <Button onClick={handleConfirmAction} color="primary">Confirmar</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarMessage.includes('Error') ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ParcelDetailModal;
