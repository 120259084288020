// src/components/Modals/AssignRoute.jsx

import React, { useContext, useEffect, useState } from "react";
import { getAllRoutes, addParcelToRoute } from "../../Apis/routes";
import "./Modal.scss";
import { AppContext } from "../../contextApi/AppContext";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Typography, CircularProgress, Box } from '@mui/material';

const AssignRoute = ({ parcelID, handleStatus, refreshParcels }) => {
  const { dispatch } = useContext(AppContext);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Llamada a tu API existente para obtener todas las rutas
    const fetchRoutes = async () => {
      setLoading(true);
      try {
        const response = await getAllRoutes();
        if (response.data.success) {
          setRoutes(response.data.data);
        } else {
          setRoutes([]);
        }
      } catch (error) {
        console.error("Error al cargar rutas: ", error);
        setError("Error al cargar rutas.");
      } finally {
        setLoading(false);
      }
    };

    fetchRoutes();
  }, []);

  const assignParcelToRoute = async (routeId) => {
    // Llamada a tu API existente para agregar un paquete a una ruta
    setLoading(true);
    setError(null);
    try {
      const response = await addParcelToRoute(routeId, parcelID);
      if (response.data.success) {
        dispatch({ type: "ASSIGN_ROUTE", payload: response.data });
        // Refrescar los paquetes en el componente padre
        refreshParcels();
        handleStatus(false);
      } else {
        setError('Error al asignar el paquete a la ruta.');
      }
    } catch (error) {
      console.error("Error al asignar paquete: ", error);
      setError('Error al asignar el paquete a la ruta.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={() => handleStatus(false)} fullWidth maxWidth="sm">
      <DialogTitle>Asignar Ruta al Paquete</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : routes.length > 0 ? (
          <>
            <Typography variant="body1">Seleccione una ruta para asignar al paquete.</Typography>
            <List>
              {routes.map(route => (
                <ListItem key={route._id} sx={{ backgroundColor: '#f9f9f9', marginBottom: 1, borderRadius: 2 }}>
                  <ListItemText
                    primary={route.name || `Ruta ${route.routeID}`}
                    secondary={`Provincia: ${route.province}`}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => assignParcelToRoute(route._id)}
                    disabled={loading}
                  >
                    Asignar a esta Ruta
                  </Button>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography>No hay rutas disponibles para asignar.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleStatus(false)} variant="contained" color="secondary" disabled={loading}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignRoute;
