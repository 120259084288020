import React, { useEffect, useState } from 'react';
import { getInventoryItems, getCategories, updateInventoryItem } from '../Apis/inventoryAPI';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

function ViewInventory() {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    fetchInventory(page + 1, rowsPerPage);
    fetchCategories();
  }, [page, rowsPerPage]);

  const fetchInventory = async (currentPage, limit) => {
    setLoading(true);
    try {
      const response = await getInventoryItems(currentPage, limit);
      setInventory(response.data.data);
      setTotalItems(response.data.totalItems);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener el inventario:', error);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      setCategories(response.data.data || []);
    } catch (error) {
      console.error('Error al obtener categorías:', error);
    }
  };

  const handleEditClick = (item) => {
    setCurrentItem({ ...item });
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setCurrentItem(null);
  };

  const handleUpdateItem = async () => {
    try {
      await updateInventoryItem(currentItem._id, {
        productTitle: currentItem.productTitle,
        category: currentItem.category,
      });
      fetchInventory(page + 1, rowsPerPage);
      handleEditDialogClose();
      alert('Ítem actualizado con éxito');
    } catch (error) {
      console.error('Error al actualizar ítem:', error);
      alert('Error al actualizar el ítem');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Inventario Existente
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Título del Producto</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Cantidad Disponible</TableCell>
                  <TableCell>Categoría</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventory.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.productTitle}</TableCell>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>{item.quantityAvailable}</TableCell>
                    <TableCell>{item.category ? item.category.name : 'Sin categoría'}</TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleEditClick(item)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página"
            />
          </TableContainer>
        )}
      </Paper>

      {/* Diálogo de edición */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Editar Ítem de Inventario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cambia el título o la categoría del producto.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Título del Producto"
            fullWidth
            value={currentItem?.productTitle || ''}
            onChange={(e) =>
              setCurrentItem({ ...currentItem, productTitle: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Categoría</InputLabel>
            <Select
              value={currentItem?.category || ''}
              onChange={(e) =>
                setCurrentItem({ ...currentItem, category: e.target.value })
              }
            >
              {categories.map((cat) => (
                <MenuItem key={cat._id} value={cat._id}>
                  {cat.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleUpdateItem} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ViewInventory;
