// src/components/Modals/CreateShipment/MapAddressSelection.js

import {
  Box,
  Typography,
} from "@mui/material";
import MapAddressPage from "../MapAddressPage";
import axios from "axios";
import { useState } from "react";

const MapAddressSelection = ({ addressType, handleMapSave, onAddressComplete, currentAddress }) => {
  const [loading, setLoading] = useState(false);

  // Función para verificar si la dirección está dentro de la zona de cobertura
  const checkCoverage = async (latitude, longitude) => {
    try {
      const response = await axios.post("/coverage-zone/check", {
        latitude,
        longitude,
      });
      return response.data.message === "Ubicación dentro de la zona de cobertura";
    } catch (error) {
      console.error("Error verificando cobertura:", error);
      return false;
    }
  };

  // Manejamos el guardado de la dirección y mostramos un mensaje en cada paso
  const handleSaveAddress = async (address) => {
    setLoading(true);
    const isInCoverage = await checkCoverage(address.latitude, address.longitude);

    if (isInCoverage) {
      // Guardar la dirección en el estado
      handleMapSave(addressType, {
        address: address.address || '',
        latitude: address.latitude || 0,
        longitude: address.longitude || 0,
      });

      setLoading(false);

      // Notificar al componente padre que el proceso de guardado de dirección ha completado
      if (onAddressComplete) {
        onAddressComplete();
      }
    } else {
      // Mostrar alerta si está fuera de la zona de cobertura
      alert("Esta dirección está fuera de la zona de cobertura.");
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h6" align="center" gutterBottom>
        {addressType === "pickup"
          ? "Selecciona la dirección de recolección en el mapa"
          : "Selecciona la dirección de entrega en el mapa"}
      </Typography>

      {/* Componente del mapa */}
      <MapAddressPage
        key={addressType} // Reinicia el mapa al cambiar de tipo
        addressType={addressType}
        onSaveAddress={handleSaveAddress}
        currentAddress={currentAddress}
      />
    </Box>
  );
};

export default MapAddressSelection;
