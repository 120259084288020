// src/components/Modals/CreateShipment.js

import { useState, useContext, useEffect } from "react";
import {
  useTheme,
  Box,
  Grid,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  Card,
  CardContent,
  Chip,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { LocalShipping, AccessTime } from "@mui/icons-material";
import { createParcel } from "../../Apis/parcel"; // Asegúrate de que la función esté correctamente importada
import { getInventoryItems, getCategories } from "../../Apis/inventoryAPI"; // Importamos desde inventoryAPI.js
import { AppContext } from "../../contextApi/AppContext";
import TextFieldComponent from "./CreateShipment/TextFieldComponent";
import PaymentMethodSelector from "./CreateShipment/PaymentMethodSelector";
import MapAddressSelection from "./CreateShipment/MapAddressSelection";
import CashPaymentFields from "./CreateShipment/CashPaymentFields";
import OtherPaymentDetails from "./CreateShipment/OtherPaymentDetails";
import ErrorAlert from "./CreateShipment/ErrorAlert";
import { validateFormData } from "./CreateShipment/ValidationUtils";

const steps = [
  "Datos del destinatario",
  "Método de pago",
  "Origen del envío",
  "Dirección de entrega",
  "Confirmación del envío",
];

const CreateShipment = ({ Handlestatus }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { dispatch } = useContext(AppContext);

  const [formData, setFormData] = useState({ changeNeeded: false });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isBeforeCutoff, setIsBeforeCutoff] = useState(true);
  const [scheduleInfo, setScheduleInfo] = useState({
    collectionRoute: "",
    deliveryRoute: "",
  });
  const [timeLeft, setTimeLeft] = useState("");
  const [useInventory, setUseInventory] = useState(1); // 0: Inventario, 1: Dirección (Dirección es el predeterminado)
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]); // Array de objetos { inventoryId, quantity }
  const [inventoryPage, setInventoryPage] = useState(0); // Cambiado a base 0 para TablePagination
  const [inventoryPageSize, setInventoryPageSize] = useState(10);
  const [inventoryTotalCount, setInventoryTotalCount] = useState(0);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Para depuración: Monitorea cambios en formData
  useEffect(() => {
    console.log("formData updated:", formData);
  }, [formData]);

  useEffect(() => {
    const updateTimeAndSchedule = () => {
      const now = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: "America/Costa_Rica",
        })
      );
      const cutoff = new Date(now);
      cutoff.setHours(7, 30, 0, 0); // 7:30 AM
      let diff;

      if (now < cutoff) {
        setIsBeforeCutoff(true);
        setScheduleInfo({ collectionRoute: "Mañana", deliveryRoute: "Tarde" });
        diff = cutoff - now;
      } else {
        setIsBeforeCutoff(false);
        setScheduleInfo({
          collectionRoute: "Tarde",
          deliveryRoute: "Mañana (Día siguiente)",
        });
        const nextCutoff = new Date(now);
        nextCutoff.setDate(now.getDate() + 1);
        nextCutoff.setHours(7, 30, 0, 0);
        diff = nextCutoff - now;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      setTimeLeft(`${hours}h ${minutes}m`);
    };

    updateTimeAndSchedule();
    const timer = setInterval(updateTimeAndSchedule, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (useInventory === 0) {
      fetchCategories();
      fetchInventory(
        inventoryPage,
        inventoryPageSize,
        categoryFilter,
        searchQuery
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useInventory, inventoryPage, inventoryPageSize, categoryFilter, searchQuery]);

  const HandleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  /**
   * Función para manejar el guardado de direcciones desde el componente de mapa.
   * @param {string} addressType - Tipo de dirección ('pickup' o 'dropoff').
   * @param {object} address - Objeto que contiene 'address', 'latitude' y 'longitude'.
   */
  const HandleMapSave = (addressType, address) => {
    console.log(`Guardando dirección de ${addressType}:`, address);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`${addressType}_address`]: address.address,
      [`${addressType}_lat`]: address.latitude,
      [`${addressType}_lng`]: address.longitude,
    }));
    setSnackbarMsg(
      `Dirección de ${
        addressType === "pickup" ? "recolección" : "entrega"
      } guardada${addressType === "dropoff" ? ". ¡Proceso completado!" : "."}`
    );
    setSnackbarOpen(true);
    handleNext();
  };

  /**
   * Función para obtener los ítems de inventario desde la API.
   */
  const fetchInventory = async (page, pageSize, category, search) => {
    setLoadingInventory(true);
    try {
      const response = await getInventoryItems(
        page + 1,
        pageSize,
        category,
        search
      );
      setInventoryItems(response.data.data);
      setInventoryTotalCount(response.data.total);
    } catch (error) {
      console.error("Error fetching inventory items:", error);
    } finally {
      setLoadingInventory(false);
    }
  };

  /**
   * Función para obtener las categorías desde la API.
   */
  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      setCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  /**
   * Maneja el cambio en la selección de ítems del inventario.
   */
  const handleInventorySelectionChange = (inventoryId, field, value) => {
    setSelectedInventoryItems((prevItems) => {
      const existingItem = prevItems.find(
        (item) => item.inventoryId === inventoryId
      );
      if (field === "selected") {
        if (value) {
          // Añadir item si no existe
          if (!existingItem) {
            return [...prevItems, { inventoryId, quantity: 1 }];
          }
        } else {
          // Remover item si existe
          return prevItems.filter((item) => item.inventoryId !== inventoryId);
        }
      } else if (field === "quantity" && existingItem) {
        // Actualizar cantidad
        return prevItems.map((item) =>
          item.inventoryId === inventoryId
            ? { ...item, quantity: parseInt(value) }
            : item
        );
      }
      return prevItems;
    });
  };

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  /**
   * Función para manejar el envío del formulario.
   */
  const HandleSubmit = async (e) => {
    e.preventDefault();

    // **Validación Condicional: Omite pickup_address si se usa inventario**
    const validationError = validateFormData(formData, useInventory);
    if (validationError) {
      setErrorMsg(validationError);
      return;
    }

    const totalToPay =
      paymentMethod === "cash" ? parseFloat(formData.amount) || 0 : null;

    const dataToSend = {
      ...formData,
      inventoryItems:
        useInventory === 0 && selectedInventoryItems.length > 0
          ? selectedInventoryItems
          : undefined,
      pickup_address:
        useInventory === 1 ? formData.pickup_address : undefined,
      pickup_latitude:
        useInventory === 1 ? parseFloat(formData.pickup_lat) : undefined,
      pickup_longitude:
        useInventory === 1 ? parseFloat(formData.pickup_lng) : undefined,
      dropoff_address: formData.dropoff_address,
      dropoff_latitude: parseFloat(formData.dropoff_lat) || undefined,
      dropoff_longitude: parseFloat(formData.dropoff_lng) || undefined,
      totalToPay,
      name: formData.recipientName,
      schedule: scheduleInfo,
      changeNeeded: !!formData.changeNeeded,
      totalChange: formData.changeNeeded
        ? parseFloat(formData.totalChange)
        : undefined,
      amount: parseFloat(formData.amount) || undefined,
      paymentMethod,
    };

    console.log("Data to send:", dataToSend); // Verificación antes del envío

    try {
      const response = await createParcel(dataToSend); // Ajusta si createParcel retorna directamente
      if (response.data.success) {
        dispatch({ type: "CREATE_SHIPMENT", payload: response.data.data });
        setConfirmationDialogOpen(true);
      } else {
        setErrorMsg(response.data.message || "Error al crear el envío.");
      }
    } catch (error) {
      setErrorMsg(error.response?.data?.message || "Error al crear el envío.");
    }
  };

  /**
   * Función para renderizar el contenido de cada paso del Stepper.
   */
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                label="ID del paquete del cliente (clientParcelId)"
                name="clientParcelId"
                value={formData.clientParcelId || ""}
                handleChange={HandleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                label="Nombre del destinatario"
                name="recipientName"
                value={formData.recipientName || ""}
                handleChange={HandleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                label="Teléfono principal del destinatario"
                name="recipientPhone"
                value={formData.recipientPhone || ""}
                handleChange={HandleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldComponent
                label="Teléfono opcional del destinatario"
                name="recipientPhone2"
                value={formData.recipientPhone2 || ""}
                handleChange={HandleChange}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PaymentMethodSelector
                paymentMethod={paymentMethod}
                handleChange={(e) => {
                  HandleChange(e);
                  setPaymentMethod(e.target.value);
                }}
              />
            </Grid>
            {["cash", "sinpe_transferencia", "otro"].includes(
              paymentMethod
            ) && (
              <Grid item xs={12}>
                <TextFieldComponent
                  label="Monto a cobrar"
                  name="amount"
                  type="number"
                  value={formData.amount || ""}
                  handleChange={HandleChange}
                  required
                />
              </Grid>
            )}
            {paymentMethod === "cash" && (
              <Grid item xs={12}>
                <CashPaymentFields
                  formData={formData}
                  handleChange={HandleChange}
                  errors={errorMsg ? { totalChange: errorMsg } : {}}
                />
              </Grid>
            )}
            {paymentMethod === "otro" && (
              <Grid item xs={12}>
                <OtherPaymentDetails handleChange={HandleChange} />
              </Grid>
            )}
          </Grid>
        );
      case 2:
        return (
          <Box>
            <Tabs
              value={useInventory}
              onChange={(e, newValue) => setUseInventory(newValue)}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="fullWidth"
              sx={{ mb: 2 }}
            >
              <Tab label="Dirección de Recolección" value={1} />
              <Tab label="Desde Inventario" value={0} />
            </Tabs>
            {useInventory === 1 && (
              <Box mt={2}>
                <MapAddressSelection
                  addressType="pickup"
                  handleMapSave={HandleMapSave}
                  currentAddress={{
                    address: formData.pickup_address,
                    latitude: formData.pickup_lat,
                    longitude: formData.pickup_lng,
                  }}
                />
              </Box>
            )}
            {useInventory === 0 && (
              <Box mt={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Buscar Producto"
                      variant="outlined"
                      fullWidth
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Categoría</InputLabel>
                      <Select
                        value={categoryFilter}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        label="Categoría"
                      >
                        <MenuItem value="">
                          <em>Todas</em>
                        </MenuItem>
                        {categories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Selecciona productos del inventario:
                  </Typography>
                  <TableContainer component={Box} sx={{ maxHeight: 400 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              indeterminate={
                                selectedInventoryItems.length > 0 &&
                                selectedInventoryItems.length <
                                  inventoryItems.length
                              }
                              checked={
                                selectedInventoryItems.length ===
                                  inventoryItems.length &&
                                inventoryItems.length > 0
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const allItems = inventoryItems.map((item) => ({
                                    inventoryId: item._id,
                                    quantity: 1,
                                  }));
                                  setSelectedInventoryItems(allItems);
                                } else {
                                  setSelectedInventoryItems([]);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>Título del Producto</TableCell>
                          <TableCell>SKU</TableCell>
                          <TableCell>Categoría</TableCell>
                          <TableCell>Cantidad Disponible</TableCell>
                          <TableCell>Cantidad a Enviar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inventoryItems.map((item) => {
                          const selectedItem = selectedInventoryItems.find(
                            (invItem) => invItem.inventoryId === item._id
                          );
                          return (
                            <TableRow key={item._id} hover>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={!!selectedItem}
                                  onChange={(e) => {
                                    handleInventorySelectionChange(
                                      item._id,
                                      "selected",
                                      e.target.checked
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell>{item.productTitle}</TableCell>
                              <TableCell>{item.sku}</TableCell>
                              <TableCell>
                                {item.category ? item.category.name : "Sin categoría"}
                              </TableCell>
                              <TableCell>{item.quantityAvailable}</TableCell>
                              <TableCell>
                                {selectedItem ? (
                                  <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    value={selectedItem.quantity}
                                    onChange={(e) => {
                                      handleInventorySelectionChange(
                                        item._id,
                                        "quantity",
                                        e.target.value
                                      );
                                    }}
                                    inputProps={{
                                      min: 1,
                                      max: item.quantityAvailable,
                                    }}
                                  />
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={inventoryTotalCount}
                    page={inventoryPage}
                    onPageChange={(event, newPage) => setInventoryPage(newPage)}
                    rowsPerPage={inventoryPageSize}
                    onRowsPerPageChange={(event) => {
                      setInventoryPageSize(parseInt(event.target.value, 10));
                      setInventoryPage(0);
                    }}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    labelRowsPerPage="Filas por página"
                  />
                </Box>
              </Box>
            )}
          </Box>
        );
      case 3:
        return (
          <MapAddressSelection
            addressType="dropoff" // Cambiado de 'delivery' a 'dropoff'
            handleMapSave={HandleMapSave}
            currentAddress={{
              address: formData.dropoff_address,
              latitude: formData.dropoff_lat,
              longitude: formData.dropoff_lng,
            }}
          />
        );
      case 4:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Resumen del envío:
              </Typography>
              {[
                {
                  label: "Nombre del destinatario",
                  value: formData.recipientName,
                },
                { label: "Teléfono", value: formData.recipientPhone },
                { label: "Método de pago", value: paymentMethod },
                useInventory === 1 && {
                  label: "Dirección de recolección",
                  value: formData.pickup_address,
                },
                useInventory === 0 && {
                  label: "Productos seleccionados",
                  value:
                    selectedInventoryItems
                      .map((selectedItem) => {
                        const item = inventoryItems.find(
                          (invItem) => invItem._id === selectedItem.inventoryId
                        );
                        return item
                          ? `${item.productTitle} (Cantidad: ${selectedItem.quantity})`
                          : "";
                      })
                      .join(", ") || "Ninguno",
                },
                {
                  label: "Dirección de entrega",
                  value: formData.dropoff_address,
                },
                // Mostrar coordenadas si están disponibles
                useInventory === 1 &&
                  formData.pickup_lat &&
                  formData.pickup_lng && {
                    label: "Coordenadas de recolección",
                    value: `Lat: ${formData.pickup_lat}, Lng: ${formData.pickup_lng}`,
                  },
                formData.dropoff_lat &&
                  formData.dropoff_lng && {
                    label: "Coordenadas de entrega",
                    value: `Lat: ${formData.dropoff_lat}, Lng: ${formData.dropoff_lng}`,
                  },
              ]
                .filter(Boolean)
                .map(({ label, value }) => (
                  <Typography variant="body1" key={label}>
                    <strong>{label}:</strong> {value}
                  </Typography>
                ))}
              {useInventory === 0 && selectedInventoryItems.length > 0 && (
                <Box mt={2}>
                  <Typography variant="body1">
                    <strong>Detalles de los productos seleccionados:</strong>
                  </Typography>
                  <ul>
                    {selectedInventoryItems.map((selectedItem) => {
                      const item = inventoryItems.find(
                        (invItem) => invItem._id === selectedItem.inventoryId
                      );
                      return item ? (
                        <li key={item._id}>
                          {item.productTitle} (SKU: {item.sku}) - Cantidad a Enviar:{" "}
                          {selectedItem.quantity}
                        </li>
                      ) : null;
                    })}
                  </ul>
                </Box>
              )}
              <Box mt={2}>
                <Card variant="outlined">
                  <CardContent>
                    {[
                      {
                        label: "Horario de Recolección",
                        value: scheduleInfo.collectionRoute,
                      },
                      {
                        label: "Horario de Entrega",
                        value: scheduleInfo.deliveryRoute,
                      },
                    ].map(({ label, value }) => (
                      <div key={label}>
                        <Typography variant="subtitle1" gutterBottom>
                          <strong>{label}:</strong>
                        </Typography>
                        <Chip
                          label={value}
                          color={isBeforeCutoff ? "primary" : "secondary"}
                          size="medium"
                          sx={{ mr: 1, mb: 1 }}
                        />
                      </div>
                    ))}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return "Paso desconocido";
    }
  };

  const handleCloseSnackbar = () => setSnackbarOpen(false);
  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
    Handlestatus(false);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "white",
        borderRadius: 2,
        maxWidth: "100%",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <Box mb={2}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#f0f4f8",
            display: "flex",
            alignItems: "center",
            padding: 2,
          }}
        >
          <LocalShipping color="primary" sx={{ mr: 2, fontSize: 40 }} />
          <Box>
            <Typography variant="body1" color="textPrimary">
              {isBeforeCutoff
                ? "¡Crea tu pedido antes de las 7:30 AM y lo recolectaremos esta mañana para entregarlo esta tarde!"
                : "Crea tu pedido después de las 7:30 AM y lo recolectaremos esta tarde para entregarlo mañana."}
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <AccessTime color="action" sx={{ mr: 1 }} />
              <Typography variant="body2" color="textSecondary">
                Tiempo restante: {timeLeft}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>

      <Typography
        variant="h4"
        sx={{
          mb: 3,
          fontSize: { xs: "1.5rem", sm: "2rem" },
          wordWrap: "break-word",
        }}
      >
        Crear nuevo envío
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="horizontal"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          mb: 3,
          "& .MuiStep-root": { width: { xs: "100%", sm: "auto" } },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": {
                  fontSize: { xs: "0.75rem", sm: "1rem" },
                  whiteSpace: { xs: "normal", sm: "nowrap" },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <form onSubmit={HandleSubmit}>
        <Grid container spacing={2}>
          {renderStepContent(activeStep)}
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
            >
              {activeStep !== 0 && (
                <Button
                  variant="outlined"
                  onClick={handleBack}
                  fullWidth={isSmallScreen}
                >
                  Atrás
                </Button>
              )}
              {activeStep < steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext()}
                  fullWidth={isSmallScreen}
                >
                  Siguiente
                </Button>
              ) : (
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={2}
                  width="100%"
                >
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    fullWidth={isSmallScreen}
                  >
                    Atrás
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth={isSmallScreen}
                  >
                    Crear Envío
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMsg}
        </Alert>
      </Snackbar>

      <Dialog
        open={confirmationDialogOpen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">¡Envío Creado!</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            El pedido ha sido creado exitosamente. Lo encontrarás en tu lista de
            pedidos. Nuestro equipo se está movilizando para realizar la entrega.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} autoFocus>
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      {errorMsg && <ErrorAlert errorMsg={errorMsg} />}
    </Box>
  );
};

export default CreateShipment;
