// src/Apis/routes.js

import axios from "./axiosConfig";

// Añadir una nueva ruta
export const addRoute = (data) => axios.post("/route/add", { ...data });

// Editar una ruta existente
export const editRoute = (routeID, data) => axios.put(`/route/edit/${routeID}`, { ...data });

// Eliminar una ruta
export const deleteRoute = (routeID) => axios.delete(`/route/delete/${routeID}`);

// Obtener todas las rutas
export const getAllRoutes = async (page = 1, filter = {}) => {
  const params = {
    page,
    limit: 10,  // Puedes ajustar este valor según tus necesidades
    ...filter,  // Agregar los filtros como parámetros de consulta
  };

  return await axios.get('/route/list', { params });
};

// Obtener todas las rutas activas
export const getAllActiveRoutes = () => axios.get("/route/active-routes");

// Agregar un pedido a una ruta existente
export const addParcelToRoute = (routeID, parcelID) => axios.put(`/route/add-parcel/${routeID}`, { parcelId: parcelID });

// Asignar un mensajero a una ruta
export const assignMessenger = (routeID, messengerID) => axios.put(`/route/assign-messenger/${routeID}`, { biker: messengerID });

// Obtener todos los mensajeros
export const getAllBikers = () => axios.get("/route/all-bikers");

// Obtener una ruta por su ID
export const getRouteByID = (routeID) => axios.get(`/route/${routeID}`);

// Obtener todas las rutas asignadas a un mensajero con paginación y filtrado
export const getMessengerRoutes = (status, page = 1, limit = 10) => 
  axios.get(`/route/messenger/routes?status=${status}&page=${page}&limit=${limit}`);
