// src/pages/myShipments/MyShipment.js

import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { FaBoxOpen, FaPlus } from 'react-icons/fa';
import { AppContext } from '../../contextApi/AppContext';
import { SocketContext } from '../../contextApi/SocketContext';
import CreateShipment from '../../components/Modals/CreateShipment';
import ShipperTable from '../dashboard/components/ShipperTable';
import { percelsShipper } from '../../Apis/parcel';
import { toast } from 'react-toastify';
import InventoryCreation from '../../components/InventoryCreation';
import ViewInventory from '../../components/ViewInventory';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
    ...(open && {
      [theme.breakpoints.up('sm')]: {
        marginLeft: `${drawerWidth}px`,
        width: `calc(100% - ${drawerWidth}px)`,
      },
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MyShipment = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(!isSmallScreen);
  const [activeComponent, setActiveComponent] = useState('shipments');
  const { AppData, dispatch } = useContext(AppContext);
  const socket = useContext(SocketContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async (filters = {}, page = 1, limit = 10) => {
    dispatch({ type: 'LOADING', payload: true });
    try {
      const response = await percelsShipper(filters, page, limit);
      const { data, totalPages: tp, currentPage: cp } = response.data;
      dispatch({ type: 'GET_PERCELS', payload: data });
      setTotalPages(tp);
      setCurrentPage(cp);
    } catch (error) {
      console.error('Error en la llamada a la API percelsShipper:', error);
      dispatch({ type: 'LOADING', payload: false });
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleApplyFilters = (filters) => {
    const { page, ...restFilters } = filters;
    const newPage = page || 1;
    setCurrentPage(newPage);
    fetchData(restFilters, newPage);
  };

  const menuItems = [
    { label: 'Ver Envíos', Icon: FaBoxOpen, Component: 'shipments' },
    { label: 'Crear Envío', Icon: FaPlus, Component: 'create' },
    { label: 'Crear Inventario', Icon: FaPlus, Component: 'inventory' },
    { label: 'Ver Inventario', Icon: FaPlus, Component: 'viewInventory' },
  ];

  // Escuchar eventos de Socket.io para actualizaciones en tiempo real
  useEffect(() => {
    if (!socket) return;

    const handleParcelCreated = (data) => {
      console.log('Nuevo envío creado:', data);
      dispatch({ type: 'ADD_PARCEL', payload: data.parcel });
      toast.info(data.message);
    };

    const handleParcelUpdated = (data) => {
      console.log('Envío actualizado:', data);
      dispatch({ type: 'UPDATE_PARCEL', payload: data.parcel });
      toast.info(data.message);
    };

    // Registrar eventos solo si el socket está definido
    socket.on('parcelCreated', handleParcelCreated);
    socket.on('parcelUpdated', handleParcelUpdated);

    // Limpiar los listeners al desmontar el componente
    return () => {
      if (socket) {
        socket.off('parcelCreated', handleParcelCreated);
        socket.off('parcelUpdated', handleParcelUpdated);
      }
    };
  }, [socket, dispatch]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2, display: { xs: 'block', sm: 'block' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Gestión de Envíos
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isSmallScreen ? 'temporary' : 'persistent'}
        open={open}
        onClose={toggleDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
          {menuItems.map(({ label, Icon, Component }) => (
            <ListItem button key={label} onClick={() => setActiveComponent(Component)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Main open={open}>
        <DrawerHeader />

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {activeComponent === 'shipments' && (
            <Grid item xs={12}>
              {AppData.isLoading ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <ShipperTable
                  onApplyFilters={handleApplyFilters}
                  totalPages={totalPages}
                  currentPage={currentPage}
                />
              )}
              {!AppData.isLoading && AppData.percels && AppData.percels.length === 0 && (
                <Typography>No hay envíos disponibles.</Typography>
              )}
            </Grid>
          )}

          {activeComponent === 'create' && (
            <Grid item xs={12}>
              <CreateShipment Handlestatus={() => setActiveComponent('shipments')} />
            </Grid>
          )}
          {activeComponent === 'inventory' && (
            <Grid item xs={12}>
              <InventoryCreation />
            </Grid>
          )}
          {activeComponent === 'viewInventory' && (
            <Grid item xs={12}>
              <ViewInventory />
            </Grid>
          )}
        </Grid>
      </Main>
    </Box>
  );
};

export default MyShipment;
