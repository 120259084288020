import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Configuración dinámica del baseURL
axios.defaults.baseURL = 
    window.location.hostname === "localhost"
        ? "http://localhost:5002/api/v1"  // Cambia a 5002 para que coincida con el backend en localhost
        : "https://entregadirecto.com/api"; // Dominio de producción con SSL

// Configura el header de autorización usando el token de cookies
const token = cookies.get("token");

if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default axios;
