import { MapContainer, TileLayer, Polygon, Circle, FeatureGroup } from "react-leaflet";
import { useState, useEffect } from "react";
import axios from "../../Apis/axiosConfig"; // Usar axiosConfig
import Cookies from "universal-cookie";
import { Typography, Button, Box, Container, Select, MenuItem } from "@mui/material"; // Agregar Select y MenuItem
import { EditControl } from "react-leaflet-draw"; // Agregar herramienta de dibujo
import "leaflet/dist/leaflet.css"; // Asegurarse de importar los estilos de leaflet
import "leaflet-draw/dist/leaflet.draw.css"; // Importar estilos de leaflet-draw

const AdminCoverageMap = () => {
  const [coordinates, setCoordinates] = useState([]); // Coordenadas para nuevas zonas
  const [circleData, setCircleData] = useState(null); // Datos del círculo para nuevas zonas
  const [existingZones, setExistingZones] = useState([]); // Zonas de cobertura existentes
  const [selectedZone, setSelectedZone] = useState(null); // Zona seleccionada desde el dropdown
  const [zonesToDelete, setZonesToDelete] = useState([]); // Zonas a eliminar
  const [canDraw, setCanDraw] = useState(true); // Controlar si se puede dibujar una nueva zona
  const cookies = new Cookies();
  const user = cookies.get("user");

  // Llamar a la API para obtener las zonas existentes
  const fetchExistingZones = async () => {
    try {
      const response = await axios.get("/coverage-zone/list");
      setExistingZones(response.data.zones); // Asignar las zonas al estado
    } catch (error) {
      console.error("Error al cargar las zonas existentes", error);
    }
  };

  useEffect(() => {
    fetchExistingZones(); // Cargar zonas al montar el componente
  }, []);

// Convertir círculo en un polígono con coordenadas en el formato [longitud, latitud]
const convertCircleToPolygon = (center, radius, points = 32) => {
  const polygonPoints = [];
  for (let i = 0; i < points; i++) {
    const angle = (i * 360) / points;
    const latOffset = radius * Math.cos((angle * Math.PI) / 180) / 111320; 
    const lngOffset = radius * Math.sin((angle * Math.PI) / 180) / (111320 * Math.cos(center.lat * (Math.PI / 180))); 
    polygonPoints.push([center.lng + lngOffset, center.lat + latOffset]); // Asegúrate de que [lng, lat]
  }
  return polygonPoints;
};

  // Manejar la creación de un polígono o círculo desde la herramienta de dibujo
  const handlePolygonDraw = (e) => {
    if (canDraw) {
      if (e.layerType === "polygon") {
        const drawnCoordinates = e.layer.getLatLngs()[0].map((latlng) => [latlng.lat, latlng.lng]);
        setCoordinates(drawnCoordinates); // Guardar las coordenadas del polígono dibujado
      }

      if (e.layerType === "circle") {
        const center = e.layer.getLatLng();
        const radius = e.layer.getRadius();
        setCircleData({ center, radius }); // Guardar los datos del círculo
      }

      // Desactivar la posibilidad de dibujar más hasta que se guarde
      setCanDraw(false);
    } else {
      alert("Por favor, guarda la zona antes de dibujar una nueva.");
    }
  };

  // Manejar la eliminación de zonas existentes
  const handlePolygonDeleted = (e) => {
    const layers = e.layers.getLayers();
    layers.forEach((layer) => {
      const zoneId = layer.options.id; // Supone que el ID de la zona está almacenado en las opciones del layer
      if (zoneId) {
        setZonesToDelete((prev) => [...prev, zoneId]); // Agregar a la lista de eliminados
      }
    });
  };

 // Guardar la zona de cobertura con coordenadas en el formato correcto [longitud, latitud]
const handleSaveCoverageZone = async () => {
  try {
    let geoJsonData;

    if (circleData) {
      const circlePolygon = convertCircleToPolygon(circleData.center, circleData.radius);
      geoJsonData = {
        name: "Nueva Zona de Cobertura (Círculo como Polígono)",
        geometry: {
          type: "Polygon",
          coordinates: [circlePolygon], 
        },
      };
    } else {
      geoJsonData = {
        name: "Nueva Zona de Cobertura (Polígono)",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates], 
        },
      };
    }

    await axios.post("/coverage-zone/create", geoJsonData); 
    alert("Zona de cobertura guardada exitosamente");
    fetchExistingZones(); 
  } catch (error) {
    alert("Error al guardar la zona de cobertura");
  }
};

  // Sincronizar las zonas eliminadas
  const handleDeleteZones = async () => {
    try {
      await axios.post("/coverage-zone/delete", { zonesToDelete });
      setZonesToDelete([]); // Limpiar la lista de eliminados
      fetchExistingZones(); // Refrescar las zonas
    } catch (error) {
      console.error("Error al eliminar las zonas", error);
    }
  };

  // Manejar la selección de una zona desde el dropdown
  const handleZoneSelect = (event) => {
    const selectedId = event.target.value;
    const zone = existingZones.find((z) => z._id === selectedId);
    setSelectedZone(zone);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Crear Zonas de Cobertura
      </Typography>

      {/* Dropdown para seleccionar zonas */}
      <Select value={selectedZone?._id || ""} onChange={handleZoneSelect}>
        <MenuItem value="">Mostrar todas las zonas</MenuItem>
        {existingZones.map((zone) => (
          <MenuItem key={zone._id} value={zone._id}>
            {zone.name}
          </MenuItem>
        ))}
      </Select>

      <Box
        sx={{
          height: "500px",
          border: "2px solid #1976d2",
          borderRadius: "8px",
          overflow: "hidden",
          marginTop: "16px",
        }}
      >
        <MapContainer center={[9.748917, -83.753428]} zoom={13} style={{ height: "100%", width: "100%" }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={handlePolygonDraw}
              onDeleted={handlePolygonDeleted} // Manejar eliminación
              draw={{
                rectangle: false,
                marker: false,
                polyline: false,
                polygon: canDraw, // Solo permitir si se puede dibujar
                circle: canDraw, // Solo permitir si se puede dibujar
              }}
            />
            {coordinates.length > 0 && <Polygon positions={coordinates} />}
            {circleData && <Circle center={circleData.center} radius={circleData.radius} />}

            {/* Renderizar zonas existentes o seleccionada */}
            {(selectedZone ? [selectedZone] : existingZones).map((zone, index) => {
              // Asegurarse de que coordinates esté bien estructurado
              if (zone.geometry?.type === "Polygon" && Array.isArray(zone.geometry.coordinates) && zone.geometry.coordinates.length > 0) {
                return (
                  <Polygon
                    key={index}
                    positions={zone.geometry.coordinates[0]} // Asegurarse de usar el array correcto
                    id={zone._id} // Almacenar el ID en el layer para eliminarlo
                  />
                );
              }
              return null;
            })}
          </FeatureGroup>
        </MapContainer>
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleSaveCoverageZone}>
          Guardar Zona de Cobertura
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleDeleteZones} sx={{ ml: 2 }}>
          Sincronizar Eliminaciones
        </Button>
      </Box>
    </Container>
  );
};

export default AdminCoverageMap;
