// src/pages/bikerDashboard/components/Pedidos.js

import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Box,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  TextField,
  MenuItem,
  Pagination,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider
} from "@mui/material";
import { AppContext } from "../../../contextApi/AppContext";
import { getCarrierParcels } from "../../../Apis/parcel";
import ParcelDetailModal from "../../../components/Modals/ParcelDetail";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';

const Pedidos = () => {
  const { AppData } = useContext(AppContext); // Si AppData no se utiliza, considerar eliminar esta línea
  const [activeTab, setActiveTab] = useState('active');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [filters, setFilters] = useState({
    name: '',
    status: '',
    recipientName: '',
    startDate: '',
    endDate: ''
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [actionLoading, setActionLoading] = useState({}); // Para manejar el estado de acciones individuales

  // Función para mapear el estado del paquete a una etiqueta amigable
  const getParcelStatusLabel = (status) => {
    const statusMap = {
      toCollect: 'Listo para Recoger',
      collecting: 'En Ruta para Recoger',
      inWarehouse: 'En Almacén',
      delivering: 'En Ruta para Entregar',
      delivered: 'Entregado',
      cancelled: 'Cancelado',
      collected: 'Recolectado'
    };
    return statusMap[status] || 'Desconocido';
  };

  // Función para obtener los estados según la pestaña activa
  const getStatusesForTab = (tab) => {
    if (tab === 'completed') {
      return ['delivered'];
    } else if (tab === 'collected') {
      return ['collected'];
    } else {
      return ['collecting', 'delivering'];
    }
  };
  
  // Función para obtener los pedidos desde la API
  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const statuses = getStatusesForTab(activeTab);
      const appliedFilters = {
        ...filters,
        status: filters.status || statuses,
        page: page,
        limit: 10 // Puedes ajustar el límite según tus necesidades
      };
      const response = await getCarrierParcels(appliedFilters);
      setOrders(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setOrders([]);
      setTotalPages(1);
      toast.error("Error al obtener los pedidos.");
    } finally {
      setLoading(false);
    }
  }, [activeTab, filters, page]);

  // Obtener pedidos al cambiar la pestaña, filtros o página
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setFilters({
      name: '',
      status: '',
      recipientName: '',
      startDate: '',
      endDate: ''
    }); // Reiniciar filtros al cambiar de pestaña
    setPage(1); // Reiniciar a la primera página
  };

  const handleViewOrder = (order) => {
    setSelectedParcel(order);
  };

  const closeModal = () => {
    setSelectedParcel(null);
  };

  // Función para manejar cambios en los filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleApplyFilters = () => {
    setPage(1); // Reiniciar a la primera página al aplicar filtros
    fetchOrders();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Función para manejar acciones sobre pedidos (si aplica)
  const handleOrderAction = async (action, data, successMessage, errorMessage) => {
    setActionLoading(prev => ({ ...prev, [data]: true }));
    try {
      await action(data);
      toast.success(successMessage);
      fetchOrders();
    } catch (error) {
      console.error("Error en la acción del pedido:", error);
      toast.error(errorMessage);
    } finally {
      setActionLoading(prev => ({ ...prev, [data]: false }));
    }
  };

  return (
    <Box>
      {/* Filtros de búsqueda */}
      <Box mb={3}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            label="Nombre del Pedido"
            variant="outlined"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            sx={{ flex: '1 1 200px' }}
          />
          <TextField
            label="Nombre del Destinatario"
            variant="outlined"
            name="recipientName"
            value={filters.recipientName}
            onChange={handleFilterChange}
            sx={{ flex: '1 1 200px' }}
          />
          <TextField
            label="Estado"
            select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            variant="outlined"
            sx={{ flex: '1 1 200px' }}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="toCollect">Listo para Recoger</MenuItem>
            <MenuItem value="collecting">En Ruta para Recoger</MenuItem>
            <MenuItem value="inWarehouse">En Almacén</MenuItem>
            <MenuItem value="delivering">En Ruta para Entregar</MenuItem>
            <MenuItem value="delivered">Entregado</MenuItem>
            <MenuItem value="collected">Recolectado</MenuItem>
            <MenuItem value="cancelled">Cancelado</MenuItem>
          </TextField>
          <TextField
            label="Fecha de inicio"
            type="date"
            name="startDate"
            value={filters.startDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={{ flex: '1 1 200px' }}
          />
          <TextField
            label="Fecha de fin"
            type="date"
            name="endDate"
            value={filters.endDate}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={{ flex: '1 1 200px' }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplyFilters}
          sx={{ mt: 2 }}
          disabled={loading || actionLoading}
        >
          Aplicar Filtros
        </Button>
      </Box>

      {/* Tabs principales */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ marginBottom: 2 }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab label="Todos los Pedidos" value="all" />
        <Tab label="Pedidos Activos" value="active" />
        <Tab label="Pedidos Completados" value="completed" />
      </Tabs>

      {/* Modal para detalles del pedido */}
      {selectedParcel && (
        <ParcelDetailModal
          selectedParcel={selectedParcel}
          closeModal={closeModal}
          // Puedes pasar más props si es necesario
        />
      )}

      {/* Mostramos la lista de pedidos */}
      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {orders.length > 0 ? (
            orders.map((pedido) => (
              <Grid item xs={12} sm={6} md={4} key={pedido._id}>
                <Card sx={{ maxWidth: '100%', width: '100%' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {pedido.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Estado: {getParcelStatusLabel(pedido.status)}
                    </Typography>
                    <Divider sx={{ marginY: 1 }} />
                    <Typography variant="body2">
                      <strong>Recogida:</strong> {pedido.pickup_address}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Entrega:</strong> {pedido.dropoff_address}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      <strong>Destinatario:</strong> {pedido.recipientName} ({pedido.recipientPhone})
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      <strong>Creado el:</strong> {format(new Date(pedido.createdAt), 'dd/MM/yyyy HH:mm')}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      color="primary" 
                      onClick={() => setSelectedParcel(pedido)}
                      disabled={actionLoading[pedido._id]}
                    >
                      Ver detalles
                    </Button>
                    <Button 
                      size="small" 
                      color="secondary" 
                      // Puedes añadir onClick para modificar el pedido
                      disabled={actionLoading[pedido._id]}
                    >
                      Modificar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body1" sx={{ marginTop: 2 }}>No se encontraron pedidos.</Typography>
          )}
        </Grid>
      )}

      {/* Paginación */}
      {totalPages > 1 && (
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
};

export default Pedidos;
