import React, { useState, useEffect } from 'react';
import { createInventoryItem, getCategories, createCategory, deleteCategory } from '../Apis/inventoryAPI';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

function InventoryCreation() {
  const [productTitle, setProductTitle] = useState('');
  const [sku, setSku] = useState('');
  const [quantity, setQuantity] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  async function fetchCategories() {
    try {
      const response = await getCategories();
      setCategories(Array.isArray(response.data.data) ? response.data.data : []);
    } catch (error) {
      console.error('Error al obtener categorías:', error);
      setCategories([]);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createInventoryItem({
        productTitle,
        sku,
        quantityAvailable: quantity,
        category,
      });
      alert(response.data.message || 'Inventario creado con éxito y solicitud de recolección generada');
      setProductTitle('');
      setSku('');
      setQuantity('');
      setCategory('');
    } catch (error) {
      console.error('Error al crear inventario:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setNewCategory('');
  };

  const handleCreateCategory = async () => {
    if (newCategory.trim() === '') {
      alert('Por favor, ingrese un nombre de categoría.');
      return;
    }
    try {
      const response = await createCategory({ name: newCategory });
      alert(response.data.message || 'Categoría creada con éxito');
      setCategories([...categories, response.data.data]);
      setCategory(response.data.data._id);
      handleCloseDialog();
    } catch (error) {
      console.error('Error al crear categoría:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      setCategories(categories.filter((cat) => cat._id !== categoryId));
      alert('Categoría eliminada con éxito');
    } catch (error) {
      console.error('Error al eliminar categoría:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Crear Inventario y Solicitud de Recolección
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box my={2}>
            <TextField
              label="Título del Producto"
              value={productTitle}
              onChange={(e) => setProductTitle(e.target.value)}
              fullWidth
              required
            />
          </Box>
          <Box my={2}>
            <TextField
              label="SKU"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              fullWidth
              required
            />
          </Box>
          <Box my={2}>
            <TextField
              label="Cantidad"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              required
            />
          </Box>
          <Box my={2} display="flex" alignItems="center" gap={2}>
            <FormControl fullWidth required>
              <InputLabel>Categoría</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Categoría"
              >
                <MenuItem value="">
                  <em>Seleccione una categoría</em>
                </MenuItem>
                {categories.map((cat) => (
                  <MenuItem key={cat._id} value={cat._id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="outlined" color="primary" onClick={handleOpenDialog}>
              Administrar Categorías
            </Button>
          </Box>
          <Box my={3} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" type="submit">
              Crear Inventario
            </Button>
          </Box>
        </form>
      </Paper>

      {/* Diálogo para administrar categorías */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Administrar Categorías</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aquí puedes crear una nueva categoría o eliminar las existentes.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre de la Nueva Categoría"
            fullWidth
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
          />
          <Button onClick={handleCreateCategory} color="primary" fullWidth sx={{ mt: 2 }}>
            Crear Nueva Categoría
          </Button>
          <List sx={{ mt: 2 }}>
            {categories.map((cat) => (
              <ListItem
                key={cat._id}
                secondaryAction={
                  <IconButton edge="end" onClick={() => handleDeleteCategory(cat._id)}>
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={cat.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default InventoryCreation;
