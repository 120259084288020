// src/pages/bikerDashboard/components/Rutas.js

import React, { useState, useEffect, useContext, useCallback } from "react";
import { 
  Box, 
  Tabs, 
  Tab, 
  List, 
  ListItem, 
  ListItemText, 
  Button, 
  Typography, 
  CircularProgress, 
  IconButton,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import { Edit, Delete, ExpandLess, ExpandMore, LocalShipping } from '@mui/icons-material';
import { SocketContext } from "../../../contextApi/SocketContext";
import { getMessengerRoutes, editRoute, deleteRoute } from "../../../Apis/routes"; // Asegúrate de que editRoute está exportado
import ViewRouteModal from "../../../components/Modals/ViewRouteModal";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Rutas = () => {
  const { socket, isConnected } = useContext(SocketContext);
  const [activeTab, setActiveTab] = useState('pending');
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [actionLoading, setActionLoading] = useState({}); // Manejar el estado de acciones individuales por ID de ruta
  const [openRoutes, setOpenRoutes] = useState({}); // Manejar la expansión de rutas para mostrar paquetes

  // Función para determinar si una ruta es visible en el tab actual
  const isRouteVisible = (route, status) => {
    if (status === 'pending') {
      // Define qué significa 'pending' según tu lógica de negocio
      // Por ejemplo, status !== 'completed'
      return route.status !== 'completed';
    } else if (status === 'completed') {
      return route.status === 'completed';
    }
    return false;
  };

  // Función para obtener rutas según el estado
  const fetchRoutes = useCallback(async (status) => {
    setLoading(true);
    try {
      const response = await getMessengerRoutes(status);
      setRoutes(response.data.data);
    } catch (error) {
      console.error("Error al obtener las rutas:", error);
      setRoutes([]);
      toast.error("Error al obtener las rutas.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Obtener rutas al cambiar la pestaña
  useEffect(() => {
    fetchRoutes(activeTab);
  }, [fetchRoutes, activeTab]);

  const handleTabChange = (_, newValue) => setActiveTab(newValue);
  const handleViewRoute = (route) => setSelectedRoute(route);
  const closeModal = () => setSelectedRoute(null);

  // Manejar acciones de rutas (eliminar, editar, etc.)
  const handleRouteAction = async (action, data, successMessage, errorMessage) => {
    setActionLoading(prev => ({ ...prev, [data]: true }));
    try {
      await action(data);
      toast.success(successMessage);
      fetchRoutes(activeTab);
    } catch (error) {
      console.error("Error en la acción de ruta:", error);
      toast.error(errorMessage);
    } finally {
      setActionLoading(prev => ({ ...prev, [data]: false }));
    }
  };

  // Manejar la expansión de rutas para mostrar paquetes
  const handleToggleRoute = (routeId) => {
    setOpenRoutes(prev => ({ ...prev, [routeId]: !prev[routeId] }));
  };

  // Manejar eventos de Socket.io
  useEffect(() => {
    if (!socket || !isConnected) return;

    // Escucha general para depuración
    const handleAnyEvent = (event, ...args) => {
      console.log(`Socket recibido evento: '${event}'`, args);
    };
    socket.onAny(handleAnyEvent);
    console.log("Rutas: Escucha general de eventos registrada.");

    const handleRouteEvents = [
      { 
        event: 'parcelsAssigned', 
        handler: async (data) => {
          const { routeId, parcels, message } = data;
          toast.info(message || "Se han asignado nuevos paquetes a una ruta.");

          // Después de recibir el evento, refrescar las rutas para obtener los datos actualizados
          await fetchRoutes(activeTab);
        } 
      },
      { 
        event: 'routeUpdated', 
        handler: (data) => {
          const updatedRoute = data.route;
          const isVisible = isRouteVisible(updatedRoute, activeTab);
          setRoutes((prev) => {
            const exists = prev.find(route => route._id === updatedRoute._id);
            if (exists) {
              if (isVisible) {
                // Actualizar la ruta en la lista
                return prev.map(route => route._id === updatedRoute._id ? updatedRoute : route);
              } else {
                // Remover la ruta si ya no es visible
                return prev.filter(route => route._id !== updatedRoute._id);
              }
            } else {
              if (isVisible) {
                // Añadir la ruta si ahora es visible
                return [updatedRoute, ...prev];
              }
              return prev;
            }
          });
          toast.info("Ruta actualizada.");
        } 
      },
      { 
        event: 'routeDeleted', 
        handler: (data) => {
          setRoutes((prev) => prev.filter(route => route._id !== data.routeId));
          toast.info("Ruta eliminada.");
        } 
      },
    ];

    handleRouteEvents.forEach(({ event, handler }) => socket.on(event, handler));
    handleRouteEvents.forEach(({ event }) => {
      console.log(`Rutas: Evento '${event}' registrado.`);
    });

    return () => {
      handleRouteEvents.forEach(({ event, handler }) => socket.off(event, handler));
      socket.offAny(handleAnyEvent);
      console.log("Rutas: Escucha general de eventos desregistrada.");
    };
  }, [socket, isConnected, activeTab, fetchRoutes]);

  return (
    <Box>
      <Tabs 
        value={activeTab} 
        onChange={handleTabChange} 
        variant="fullWidth" 
        sx={{ mb: 2 }}
      >
        <Tab label="Rutas Pendientes" value="pending" />
        <Tab label="Rutas Completadas" value="completed" />
      </Tabs>

      {loading ? (
        <Box 
          sx={{ display: 'flex', justifyContent: 'center', p: 2 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {routes.length ? (
            routes.map((route) => (
              <React.Fragment key={route._id}>
                <ListItem 
                  sx={{ 
                    backgroundColor: "#f9f9f9", 
                    mb: 2, 
                    borderRadius: 2, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'stretch' 
                  }}
                  divider
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <ListItemText
                      primary={`Ruta: ${route.name}`}
                      secondary={`Fecha de asignación: ${new Date(route.createdAt).toLocaleDateString()}`}
                    />
                    <Box>
                      <IconButton 
                        color="primary" 
                        onClick={() => handleViewRoute(route)} 
                        sx={{ mr: 1 }}
                        aria-label="Editar Ruta"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton 
                        color="secondary" 
                        onClick={() => handleRouteAction(
                          deleteRoute, 
                          route._id, 
                          "Ruta eliminada exitosamente.", 
                          "Error al eliminar la ruta."
                        )}
                        aria-label="Eliminar Ruta"
                        disabled={actionLoading[route._id]} // Usamos el estado de carga específico
                      >
                        <Delete />
                      </IconButton>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleViewRoute(route)} 
                        sx={{ ml: 1 }}
                      >
                        Ver Ruta
                      </Button>
                      <IconButton 
                        onClick={() => handleToggleRoute(route._id)}
                        aria-label="Mostrar Paquetes"
                        sx={{ ml: 1 }}
                      >
                        {openRoutes[route._id] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </Box>
                  </Box>

                  {/* Mostrar lista de paquetes asignados */}
                  <Collapse in={openRoutes[route._id]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ pl: 4 }}>
                      {route.parcels && route.parcels.length > 0 ? (
                        route.parcels.map(parcel => (
                          <ListItem key={parcel._id}>
                            <ListItemIcon>
                              <LocalShipping />
                            </ListItemIcon>
                            <ListItemText
                              primary={`Paquete: ${parcel.name}`}
                              secondary={`Estado: ${parcel.status}`}
                            />
                          </ListItem>
                        ))
                      ) : (
                        <ListItem>
                          <ListItemText primary="No hay paquetes asignados a esta ruta." />
                        </ListItem>
                      )}
                    </List>
                  </Collapse>
                </ListItem>
              </React.Fragment>
            ))
          ) : (
            <Typography>No hay rutas para mostrar.</Typography>
          )}
        </List>
      )}

      {selectedRoute && (
        <ViewRouteModal
          open={Boolean(selectedRoute)}
          onClose={closeModal}
          route={selectedRoute}
          refreshRoutes={() => fetchRoutes(activeTab)}
          onEditRoute={(data) => handleRouteAction(
            editRoute, 
            data, 
            "Ruta editada exitosamente.", 
            "Error al editar la ruta."
          )}
        />
      )}
    </Box>
  );
};

export default Rutas;
