import axios from "./axiosConfig";

// Obtener todos los paquetes con filtros y paginación
// Obtener todos los paquetes con filtros y paginación
// Obtener todos los paquetes con filtros y paginación
export const allPercels = (filters = {}, page = 1, limit = 10) => {
  // Crear la query string a partir de los filtros y paginación
  const query = new URLSearchParams({
    ...filters,  // Incluir los filtros que provienen del estado
    page,        // Página actual
    limit        // Límite de resultados por página
  }).toString();
  
  // Hacer la solicitud GET con axios, incluyendo los filtros y paginación en la URL
  return axios.get(`/parcel/allparcels?${query}`);
};
export const getInventoryItems = async (page, limit) => {
  return await axios.get(`/inventory/list?page=${page}&limit=${limit}`);
};

// Crear un nuevo paquete
export const createParcel = (data) => axios.post("/parcel/create", { ...data });

// Obtener todos los envíos de un remitente con paginación
// Función para obtener los envíos del shipper con filtros y paginación
export const percelsShipper = (filters = {}, page = 1, limit = 10) => {
  // Crear la query string a partir de los filtros y paginación
  const query = new URLSearchParams({
    ...filters,  // Incluir los filtros que provienen del estado
    page,        // Página actual
    limit        // Límite de resultados por página
  }).toString();
  
  // Hacer la solicitud GET con axios, incluyendo los filtros y paginación en la URL
  return axios.get(`/parcel/shipper/shipments?${query}`);
};

// Actualizar el estado de un paquete
export const updateParcel = (parcelID, data) =>
  axios.put(`/parcel/update/${parcelID}`, { ...data });

// Obtener los envíos asignados a un mensajero con paginación
export const carrierShipments = () => axios.get("/parcel/carrier/shipments");
// Obtener todos los pedidos asignados a un mensajero con paginación y filtrado
export const getCarrierParcels = (filters = {}, page = 1, limit = 10) => {
  const params = new URLSearchParams({
    ...filters,  // Incluir los demás filtros en la query string
    page,
    limit
  });

  // Solo añadir el parámetro status si está definido y es un array
  if (filters.status && Array.isArray(filters.status)) {
    // Convertimos el array de status a una cadena CSV
    params.set('status', filters.status.join(','));
  } else if (filters.status && typeof filters.status === 'string') {
    // Si es un string, lo añadimos directamente
    params.set('status', filters.status);
  }

  const query = params.toString();
  return axios.get(`/parcel/carrier/parcels?${query}`);
};



export const getUnassignedParcels = (page = 1, limit = 10) => {
  return axios.get(`/parcel/unassigned?page=${page}&limit=${limit}`);
};