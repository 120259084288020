// src/App.js

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppProvider from "./contextApi/AppContext";
import SocketProvider from "./contextApi/SocketContext";
import {
  Dashboard,
  Home,
  Login,
  MyShipments,
  Register,
  AdminDashboard,
  BikerDashboard
} from "./pages";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  return (
    <>
      <AppProvider>
        {/* SocketProvider está fuera de ErrorBoundary */}
        <SocketProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <Routes>
                <Route element={<PublicRoute />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/shipments" element={<MyShipments />} />
                  <Route path="/logistic" element={<AdminDashboard />} />
                  <Route path="/biker" element={<BikerDashboard />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ErrorBoundary>
        </SocketProvider>
      </AppProvider>
      <ToastContainer />
    </>
  );
}

export default App;
