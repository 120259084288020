// src/Apis/disbursementApi.js

import axios from "./axiosConfig";

// Procesar un nuevo desembolso
export const processDisbursement = (data) => {
  return axios.post("/disbursement/process", { ...data });
};

// Obtener un desembolso por ID
export const getDisbursementById = (id) => {
  return axios.get(`/disbursement/${id}`);
};

// Obtener todos los desembolsos con filtros y paginación
export const getAllDisbursements = (filters = {}, page = 1, limit = 10) => {
  const query = new URLSearchParams({
    ...filters,
    page,
    limit
  }).toString();

  return axios.get(`/disbursement?${query}`);
};
