import React, { useEffect, useState } from 'react';
import { getWarehousePickups, confirmWarehousePickup } from '../Apis/inventoryAPI';
import { List, ListItem, ListItemText, Button, Typography, Box } from '@mui/material';

function PendingPickupsTab() {
  const [pickups, setPickups] = useState([]);

  useEffect(() => {
    fetchPickups();
  }, []);

  const fetchPickups = async () => {
    try {
      const response = await getWarehousePickups();
      setPickups(response.data.data.filter(pickup => pickup.status === 'pending'));
    } catch (error) {
      console.error('Error al obtener recolecciones:', error);
    }
  };

  const handleConfirm = async (pickupId) => {
    try {
      await confirmWarehousePickup(pickupId);
      alert('Recolección confirmada y añadida al inventario.');
      fetchPickups();
    } catch (error) {
      console.error('Error al confirmar recolección:', error);
    }
  };

  return (
    <List>
      {pickups.map((pickup) => (
        <ListItem key={pickup._id} sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2, borderBottom: '1px solid #e0e0e0' }}>
          <Box>
            <Typography variant="h6">
              Producto: {pickup.product.productTitle}
            </Typography>
            <Typography variant="body1">
              Cantidad: {pickup.quantity}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Organización: {pickup.organizationId?.name || 'Organización no disponible'}
            </Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={() => handleConfirm(pickup._id)}>
            Confirmar
          </Button>
        </ListItem>
      ))}
    </List>
  );
}

export default PendingPickupsTab;
