// src/components/Estadisticas.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from '../../../Apis/axiosConfig'; // Asegúrate de que la ruta sea correcta
import Cookies from 'universal-cookie';

const Estadisticas = () => {
  // Estados de carga y errores
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Estados para cada reporte
  // Reportes de Organización
  const [totalOrganizations, setTotalOrganizations] = useState(null);
  const [organizationsActiveInactive, setOrganizationsActiveInactive] = useState([]);
  const [totalBalanceByOrganization, setTotalBalanceByOrganization] = useState([]);
  const [usersCountByOrganization, setUsersCountByOrganization] = useState([]);
  const [disbursementsCountByOrganization, setDisbursementsCountByOrganization] = useState([]);

  // Reportes de Usuario
  const [totalUsers, setTotalUsers] = useState(null);
  const [usersByRole, setUsersByRole] = useState([]);
  const [activeInactiveUsers, setActiveInactiveUsers] = useState([]);
  const [ordersCountByUser, setOrdersCountByUser] = useState([]);
  const [ordersCountByOrganization, setOrdersCountByOrganization] = useState([]);

  // Reportes de Paquete
  const [totalParcels, setTotalParcels] = useState(null);
  const [parcelsByStatus, setParcelsByStatus] = useState([]);
  const [parcelsByRoute, setParcelsByRoute] = useState([]);
  const [parcelsByBiker, setParcelsByBiker] = useState([]);
  const [parcelsByPaymentMethod, setParcelsByPaymentMethod] = useState([]);
  const [totalIncome, setTotalIncome] = useState(null);
  const [parcelDeliveryStats, setParcelDeliveryStats] = useState({});
  const [deliveredOnTimeVsLate, setDeliveredOnTimeVsLate] = useState([]);

  // Reportes de Ruta
  const [totalRoutes, setTotalRoutes] = useState(null);
  const [routesByStatus, setRoutesByStatus] = useState([]);
  const [routesByBiker, setRoutesByBiker] = useState([]);
  const [averageParcelsPerRoute, setAverageParcelsPerRoute] = useState(null);
  const [averageDeliveryTimePerRoute, setAverageDeliveryTimePerRoute] = useState(null);

  // Reportes de Inventario
  const [totalInventoryProducts, setTotalInventoryProducts] = useState(null);
  const [productsByCategory, setProductsByCategory] = useState([]);
  const [availableQuantityPerProduct, setAvailableQuantityPerProduct] = useState([]);
  const [lowStockProducts, setLowStockProducts] = useState([]);
  const [currentStockByProductAndCategory, setCurrentStockByProductAndCategory] = useState([]);
  const [criticalStockProducts, setCriticalStockProducts] = useState([]);

  // Reportes de Desembolso
  const [totalDisbursements, setTotalDisbursements] = useState(null);
  const [disbursementsByStatus, setDisbursementsByStatus] = useState([]);
  const [totalDisbursementAmount, setTotalDisbursementAmount] = useState(null);
  const [disbursementsByOrganization, setDisbursementsByOrganization] = useState([]);
  const [disbursementsByDate, setDisbursementsByDate] = useState([]);
  const [disbursementHistory, setDisbursementHistory] = useState([]);

  // Reportes de Pago
  const [totalPayments, setTotalPayments] = useState(null);
  const [paymentsByStatus, setPaymentsByStatus] = useState([]);
  const [paymentsByMethod, setPaymentsByMethod] = useState([]);
  const [totalReceivedAmount, setTotalReceivedAmount] = useState(null);
  const [failedPayments, setFailedPayments] = useState([]);

  // Reportes de Recogidas en Almacén
  const [totalWarehousePickups, setTotalWarehousePickups] = useState(null);
  const [warehousePickupsByStatus, setWarehousePickupsByStatus] = useState([]);
  const [warehousePickupsByDate, setWarehousePickupsByDate] = useState([]);
  const [warehousePickupsByProduct, setWarehousePickupsByProduct] = useState([]);

  // Reportes de Rendimiento
  const [bikerPerformance, setBikerPerformance] = useState([]);
  const [adminPerformance, setAdminPerformance] = useState([]);

  // Reportes de Crecimiento
  const [userGrowthRate, setUserGrowthRate] = useState([]);

  // Reportes de Expansión
  const [geographicalCoverage, setGeographicalCoverage] = useState([]);

  // Reportes Adicionales
  const [orderCycleTime, setOrderCycleTime] = useState({});
  const [cancellationRate, setCancellationRate] = useState({});
  const [orderRepetitionRate, setOrderRepetitionRate] = useState({});

  // Inicializar Cookies
  const cookies = new Cookies();
  const token = cookies.get('token'); // Obtener el token desde las cookies

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      setError(null);

      try {
        // Las cabeceras ya están configuradas en axiosConfig.js
        // Por lo tanto, no necesitas configurarlas aquí

        // Realizar todas las solicitudes en paralelo
        const [
          totalOrgsRes,
          activeInactiveOrgsRes,
          balanceByOrgRes,
          usersCountByOrgRes,
          disbursementsCountByOrgRes,

          totalUsersRes,
          usersByRoleRes,
          activeInactiveUsersRes,
          ordersByUserRes,
          ordersByOrgRes,

          totalParcelsRes,
          parcelsByStatusRes,
          parcelsByRouteRes,
          parcelsByBikerRes,
          parcelsByPaymentMethodRes,
          totalIncomeRes,
          parcelDeliveryStatsRes,
          deliveredOnTimeVsLateRes,

          totalRoutesRes,
          routesByStatusRes,
          routesByBikerRes,
          averageParcelsPerRouteRes,
          averageDeliveryTimePerRouteRes,

          totalInventoryRes,
          productsByCategoryRes,
          availableQtyPerProductRes,
          lowStockProductsRes,
          currentStockRes,
          criticalStockProductsRes,

          totalDisbursementsRes,
          disbursementsByStatusRes,
          totalDisbursementAmountRes,
          disbursementsByOrgRes,
          disbursementsByDateRes,
          disbursementHistoryRes,

          totalPaymentsRes,
          paymentsByStatusRes,
          paymentsByMethodRes,
          totalReceivedAmountRes,
          failedPaymentsRes,

          totalWarehousePickupsRes,
          warehousePickupsByStatusRes,
          warehousePickupsByDateRes,
          warehousePickupsByProductRes,

          bikerPerformanceRes,
          adminPerformanceRes,

          userGrowthRateRes,

          geographicalCoverageRes,

          orderCycleTimeRes,
          cancellationRateRes,
          orderRepetitionRateRes,
        ] = await Promise.all([
          // Reportes de Organización
          axios.get('/reports/organizations/total'),
          axios.get('/reports/organizations/active-inactive'),
          axios.get('/reports/organizations/balance-total'),
          axios.get('/reports/organizations/users-count'),
          axios.get('/reports/organizations/disbursements-count'),

          // Reportes de Usuario
          axios.get('/reports/users/total'),
          axios.get('/reports/users/roles-distribution'),
          axios.get('/reports/users/active-inactive'),
          axios.get('/reports/users/orders-count'),
          axios.get('/reports/users/orders-by-organization'),

          // Reportes de Paquete
          axios.get('/reports/parcels/total'),
          axios.get('/reports/parcels/status-distribution'),
          axios.get('/reports/parcels/by-route'),
          axios.get('/reports/parcels/by-biker'),
          axios.get('/reports/parcels/by-payment-method'),
          axios.get('/reports/parcels/total-income'),
          axios.get('/reports/parcels/delivery-stats'),
          axios.get('/reports/parcels/delivered-on-time-vs-late'),

          // Reportes de Ruta
          axios.get('/reports/routes/total'),
          axios.get('/reports/routes/status-distribution'),
          axios.get('/reports/routes/by-biker'),
          axios.get('/reports/routes/average-parcels'),
          axios.get('/reports/routes/average-delivery-time'),

          // Reportes de Inventario
          axios.get('/reports/inventory/total-products'),
          axios.get('/reports/inventory/products-by-category'),
          axios.get('/reports/inventory/available-quantity'),
          axios.get('/reports/inventory/low-stock-products'),
          axios.get('/reports/inventory/current-stock'),
          axios.get('/reports/inventory/critical-stock'),

          // Reportes de Desembolso
          axios.get('/reports/disbursements/total'),
          axios.get('/reports/disbursements/status-distribution'),
          axios.get('/reports/disbursements/total-amount'),
          axios.get('/reports/disbursements/by-organization'),
          axios.get('/reports/disbursements/by-date'),
          axios.get('/reports/disbursements/history'),

          // Reportes de Pago
          axios.get('/reports/payments/total'),
          axios.get('/reports/payments/status-distribution'),
          axios.get('/reports/payments/method-distribution'),
          axios.get('/reports/payments/total-received'),
          axios.get('/reports/payments/failed'),

          // Reportes de Recogidas en Almacén
          axios.get('/reports/warehouse-pickups/total'),
          axios.get('/reports/warehouse-pickups/status-distribution'),
          axios.get('/reports/warehouse-pickups/by-date'),
          axios.get('/reports/warehouse-pickups/by-product'),

          // Reportes de Rendimiento
          axios.get('/reports/performance/bikers'),
          axios.get('/reports/performance/admins'),

          // Reportes de Crecimiento
          axios.get('/reports/growth/user-rate'),

          // Reportes de Expansión
          axios.get('/reports/expansion/geographical-coverage'),

          // Reportes Adicionales
          axios.get('/reports/additional/order-cycle-time'),
          axios.get('/reports/additional/cancellation-rate'),
          axios.get('/reports/additional/order-repetition-rate'),
        ]);

        // Asignar datos a los estados correspondientes
        // Reportes de Organización
        setTotalOrganizations(totalOrgsRes.data.data.totalOrganizations);
        setOrganizationsActiveInactive(activeInactiveOrgsRes.data.data);
        setTotalBalanceByOrganization(balanceByOrgRes.data.data);
        setUsersCountByOrganization(usersCountByOrgRes.data.data);
        setDisbursementsCountByOrganization(disbursementsCountByOrgRes.data.data);

        // Reportes de Usuario
        setTotalUsers(totalUsersRes.data.data.totalUsers);
        setUsersByRole(usersByRoleRes.data.data);
        setActiveInactiveUsers(activeInactiveUsersRes.data.data);
        setOrdersCountByUser(ordersByUserRes.data.data);
        setOrdersCountByOrganization(ordersByOrgRes.data.data);

        // Reportes de Paquete
        setTotalParcels(totalParcelsRes.data.data.totalParcels);
        setParcelsByStatus(parcelsByStatusRes.data.data);
        setParcelsByRoute(parcelsByRouteRes.data.data);
        setParcelsByBiker(parcelsByBikerRes.data.data);
        setParcelsByPaymentMethod(parcelsByPaymentMethodRes.data.data);
        setTotalIncome(totalIncomeRes.data.data.totalIncome);
        setParcelDeliveryStats(parcelDeliveryStatsRes.data.data);
        setDeliveredOnTimeVsLate(deliveredOnTimeVsLateRes.data.data);

        // Reportes de Ruta
        setTotalRoutes(totalRoutesRes.data.data.totalRoutes);
        setRoutesByStatus(routesByStatusRes.data.data);
        setRoutesByBiker(routesByBikerRes.data.data);
        setAverageParcelsPerRoute(averageParcelsPerRouteRes.data.data.averageParcelsPerRoute);
        setAverageDeliveryTimePerRoute(averageDeliveryTimePerRouteRes.data.data.averageDeliveryTimeHours);

        // Reportes de Inventario
        setTotalInventoryProducts(totalInventoryRes.data.data.totalInventoryProducts);
        setProductsByCategory(productsByCategoryRes.data.data);
        setAvailableQuantityPerProduct(availableQtyPerProductRes.data.data);
        setLowStockProducts(lowStockProductsRes.data.data);
        setCurrentStockByProductAndCategory(currentStockRes.data.data);
        setCriticalStockProducts(criticalStockProductsRes.data.data);

        // Reportes de Desembolso
        setTotalDisbursements(totalDisbursementsRes.data.data.totalDisbursements);
        setDisbursementsByStatus(disbursementsByStatusRes.data.data);
        setTotalDisbursementAmount(totalDisbursementAmountRes.data.data.totalDisbursed);
        setDisbursementsByOrganization(disbursementsByOrgRes.data.data);
        setDisbursementsByDate(disbursementsByDateRes.data.data);
        setDisbursementHistory(disbursementHistoryRes.data.data);

        // Reportes de Pago
        setTotalPayments(totalPaymentsRes.data.data.totalPayments);
        setPaymentsByStatus(paymentsByStatusRes.data.data);
        setPaymentsByMethod(paymentsByMethodRes.data.data);
        setTotalReceivedAmount(totalReceivedAmountRes.data.data.totalReceived);
        setFailedPayments(failedPaymentsRes.data.data);

        // Reportes de Recogidas en Almacén
        setTotalWarehousePickups(totalWarehousePickupsRes.data.data.totalWarehousePickups);
        setWarehousePickupsByStatus(warehousePickupsByStatusRes.data.data);
        setWarehousePickupsByDate(warehousePickupsByDateRes.data.data);
        setWarehousePickupsByProduct(warehousePickupsByProductRes.data.data);

        // Reportes de Rendimiento
        setBikerPerformance(bikerPerformanceRes.data.data);
        setAdminPerformance(adminPerformanceRes.data.data);

        // Reportes de Crecimiento
        setUserGrowthRate(userGrowthRateRes.data.data);

        // Reportes de Expansión
        setGeographicalCoverage(geographicalCoverageRes.data.data);

        // Reportes Adicionales
        setOrderCycleTime(orderCycleTimeRes.data.data);
        setCancellationRate(cancellationRateRes.data.data);
        setOrderRepetitionRate(orderRepetitionRateRes.data.data);

      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchReports();
    } else {
      setError('No se encontró el token de autenticación. Por favor, inicia sesión.');
      setLoading(false);
    }
  }, [token]);

  // Función auxiliar para renderizar tablas dinámicamente
  const renderTable = (columns, data) => (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.field}>{col.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={idx}>
              {columns.map((col) => (
                <TableCell key={col.field}>
                  {typeof row[col.field] === 'number'
                    ? row[col.field].toLocaleString()
                    : row[col.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '80vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center" sx={{ marginTop: 4 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Grid container spacing={2} padding={2}>
      {/* Reportes de Organización */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Organización
        </Typography>
      </Grid>

      {/* Total de Organizaciones */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Organizaciones
          </Typography>
          <Typography variant="h4">{totalOrganizations}</Typography>
        </Paper>
      </Grid>

      {/* Organizaciones Activas vs Inactivas */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Organizaciones Activas vs Inactivas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {organizationsActiveInactive.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                organizationsActiveInactive
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Balance Total por Organización */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Balance Total por Organización</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {totalBalanceByOrganization.length > 0 ? (
              renderTable(
                [
                  { field: 'organizationName', headerName: 'Organización' },
                  { field: 'totalBalance', headerName: 'Balance Total' },
                ],
                totalBalanceByOrganization.map((org) => ({
                  organizationName: org.organizationName,
                  totalBalance: `$${org.totalBalance.toFixed(2)}`,
                }))
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Número de Usuarios por Organización */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Número de Usuarios por Organización</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {usersCountByOrganization.length > 0 ? (
              renderTable(
                [
                  { field: 'organizationName', headerName: 'Organización' },
                  { field: 'userCount', headerName: 'Cantidad de Usuarios' },
                ],
                usersCountByOrganization
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Número de Desembolsos por Organización */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Número de Desembolsos por Organización</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {disbursementsCountByOrganization.length > 0 ? (
              renderTable(
                [
                  { field: 'organizationName', headerName: 'Organización' },
                  { field: 'disbursementCount', headerName: 'Cantidad de Desembolsos' },
                ],
                disbursementsCountByOrganization
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Usuario */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Usuario
        </Typography>
      </Grid>

      {/* Total de Usuarios */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Usuarios
          </Typography>
          <Typography variant="h4">{totalUsers}</Typography>
        </Paper>
      </Grid>

      {/* Distribución de Roles de Usuarios */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Distribución de Roles de Usuarios</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {usersByRole.length > 0 ? (
              renderTable(
                [
                  { field: 'role', headerName: 'Rol' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                usersByRole
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Usuarios Activos vs Inactivos */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Usuarios Activos vs Inactivos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {activeInactiveUsers.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                activeInactiveUsers
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Número de Pedidos por Usuario */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Número de Pedidos por Usuario</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ordersCountByUser.length > 0 ? (
              renderTable(
                [
                  { field: 'userName', headerName: 'Usuario' },
                  { field: 'orderCount', headerName: 'Cantidad de Pedidos' },
                ],
                ordersCountByUser
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Número de Pedidos por Organización */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Número de Pedidos por Organización</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ordersCountByOrganization.length > 0 ? (
              renderTable(
                [
                  { field: 'organizationName', headerName: 'Organización' },
                  { field: 'orderCount', headerName: 'Cantidad de Pedidos' },
                ],
                ordersCountByOrganization
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Paquete */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Paquete
        </Typography>
      </Grid>

      {/* Total de Paquetes */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Paquetes
          </Typography>
          <Typography variant="h4">{totalParcels}</Typography>
        </Paper>
      </Grid>

      {/* Distribución de Paquetes por Estado */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Distribución de Paquetes por Estado</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcelsByStatus.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                parcelsByStatus
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Paquetes por Ruta */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Paquetes por Ruta</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcelsByRoute.length > 0 ? (
              renderTable(
                [
                  { field: 'routeID', headerName: 'ID de Ruta' },
                  { field: 'name', headerName: 'Nombre de Ruta' },
                  { field: 'parcelCount', headerName: 'Cantidad de Paquetes' },
                ],
                parcelsByRoute
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Paquetes por Biker */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Paquetes por Biker</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcelsByBiker.length > 0 ? (
              renderTable(
                [
                  { field: 'bikerName', headerName: 'Biker' },
                  { field: 'parcelCount', headerName: 'Cantidad de Paquetes' },
                ],
                parcelsByBiker
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Paquetes por Método de Pago */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Paquetes por Método de Pago</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcelsByPaymentMethod.length > 0 ? (
              renderTable(
                [
                  { field: 'paymentMethod', headerName: 'Método de Pago' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                parcelsByPaymentMethod
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Ingresos Totales por Paquete */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Ingresos Totales por Paquete
          </Typography>
          <Typography variant="h4">${totalIncome ? totalIncome.toFixed(2) : '0.00'}</Typography>
        </Paper>
      </Grid>

      {/* Estadísticas de Entrega de Paquetes */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Estadísticas de Entrega de Paquetes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcelDeliveryStats.averageCycleTimeHours ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography>Tiempo Promedio de Ciclo:</Typography>
                  <Typography variant="h6">
                    {parcelDeliveryStats.averageCycleTimeHours} horas
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>Mínimo Tiempo de Ciclo:</Typography>
                  <Typography variant="h6">
                    {parcelDeliveryStats.minCycleTimeDays} días
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>Máximo Tiempo de Ciclo:</Typography>
                  <Typography variant="h6">
                    {parcelDeliveryStats.maxCycleTimeDays} días
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Paquetes Entregados a Tiempo vs. Retrasados */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              Paquetes Entregados a Tiempo vs. Retrasados
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {deliveredOnTimeVsLate.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                deliveredOnTimeVsLate
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Ruta */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Ruta
        </Typography>
      </Grid>

      {/* Total de Rutas */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Rutas
          </Typography>
          <Typography variant="h4">{totalRoutes}</Typography>
        </Paper>
      </Grid>

      {/* Distribución de Rutas por Estado */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Distribución de Rutas por Estado</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {routesByStatus.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                routesByStatus
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Rutas por Biker */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Rutas por Biker</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {routesByBiker.length > 0 ? (
              renderTable(
                [
                  { field: 'bikerName', headerName: 'Biker' },
                  { field: 'routeCount', headerName: 'Cantidad de Rutas' },
                ],
                routesByBiker
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Promedio de Paquetes por Ruta */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Promedio de Paquetes por Ruta
          </Typography>
          <Typography variant="h4">
            {averageParcelsPerRoute ? averageParcelsPerRoute.toFixed(2) : '0.00'}
          </Typography>
        </Paper>
      </Grid>

      {/* Tiempo Promedio de Entrega por Ruta */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Tiempo Promedio de Entrega por Ruta
          </Typography>
          <Typography variant="h4">
            {averageDeliveryTimePerRoute ? `${averageDeliveryTimePerRoute} horas` : '0.00 horas'}
          </Typography>
        </Paper>
      </Grid>

      {/* Reportes de Inventario */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Inventario
        </Typography>
      </Grid>

      {/* Total de Productos en Inventario */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Productos en Inventario
          </Typography>
          <Typography variant="h4">{totalInventoryProducts}</Typography>
        </Paper>
      </Grid>

      {/* Productos por Categoría */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Productos por Categoría</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {productsByCategory.length > 0 ? (
              renderTable(
                [
                  { field: 'categoryName', headerName: 'Categoría' },
                  { field: 'productCount', headerName: 'Cantidad de Productos' },
                ],
                productsByCategory
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Cantidad Disponible por Producto */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Cantidad Disponible por Producto</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {availableQuantityPerProduct.length > 0 ? (
              renderTable(
                [
                  { field: 'productTitle', headerName: 'Producto' },
                  { field: 'sku', headerName: 'SKU' },
                  { field: 'quantityAvailable', headerName: 'Cantidad Disponible' },
                ],
                availableQuantityPerProduct
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Productos con Stock Bajo */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Productos con Stock Bajo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {lowStockProducts.length > 0 ? (
              renderTable(
                [
                  { field: 'productTitle', headerName: 'Producto' },
                  { field: 'sku', headerName: 'SKU' },
                  { field: 'quantityAvailable', headerName: 'Cantidad Disponible' },
                ],
                lowStockProducts
              )
            ) : (
              <Typography>No hay productos con stock bajo.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Stock Actual por Producto y Categoría */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Stock Actual por Producto y Categoría</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {currentStockByProductAndCategory.length > 0 ? (
              renderTable(
                [
                  { field: 'productTitle', headerName: 'Producto' },
                  { field: 'categoryName', headerName: 'Categoría' },
                  { field: 'quantityAvailable', headerName: 'Cantidad Disponible' },
                ],
                currentStockByProductAndCategory
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Productos Críticos (Stock Bajo) */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Productos Críticos (Stock Bajo)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {criticalStockProducts.length > 0 ? (
              renderTable(
                [
                  { field: 'productTitle', headerName: 'Producto' },
                  { field: 'sku', headerName: 'SKU' },
                  { field: 'quantityAvailable', headerName: 'Cantidad Disponible' },
                ],
                criticalStockProducts
              )
            ) : (
              <Typography>No hay productos críticos.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Desembolso */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Desembolso
        </Typography>
      </Grid>

      {/* Total de Desembolsos */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Desembolsos
          </Typography>
          <Typography variant="h4">{totalDisbursements}</Typography>
        </Paper>
      </Grid>

      {/* Desembolsos por Estado */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Desembolsos por Estado</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {disbursementsByStatus.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                  { field: 'totalAmount', headerName: 'Monto Total' },
                ],
                disbursementsByStatus.map((disb) => ({
                  status: disb.status,
                  count: disb.count,
                  totalAmount: `$${disb.totalAmount.toFixed(2)}`,
                }))
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Monto Total Desembolsado */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Monto Total Desembolsado
          </Typography>
          <Typography variant="h4">${totalDisbursementAmount ? totalDisbursementAmount.toFixed(2) : '0.00'}</Typography>
        </Paper>
      </Grid>

      {/* Desembolsos por Organización */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Desembolsos por Organización</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {disbursementsByOrganization.length > 0 ? (
              renderTable(
                [
                  { field: 'organizationName', headerName: 'Organización' },
                  { field: 'disbursementCount', headerName: 'Cantidad de Desembolsos' },
                  { field: 'totalAmount', headerName: 'Monto Total' },
                ],
                disbursementsByOrganization.map((disb) => ({
                  organizationName: disb.organizationName,
                  disbursementCount: disb.disbursementCount,
                  totalAmount: `$${disb.totalAmount.toFixed(2)}`,
                }))
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Desembolsos por Fecha */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Desembolsos por Fecha</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {disbursementsByDate.length > 0 ? (
              renderTable(
                [
                  { field: 'period', headerName: 'Periodo' },
                  { field: 'disbursementCount', headerName: 'Cantidad de Desembolsos' },
                  { field: 'totalAmount', headerName: 'Monto Total' },
                ],
                disbursementsByDate.map((disb) => ({
                  period: disb.period,
                  disbursementCount: disb.disbursementCount,
                  totalAmount: `$${disb.totalAmount.toFixed(2)}`,
                }))
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Historial de Desembolsos */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Historial de Desembolsos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {disbursementHistory.length > 0 ? (
              <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Organización</TableCell>
                      <TableCell>Monto</TableCell>
                      <TableCell>Fecha</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {disbursementHistory.map((disb) => (
                      <TableRow key={disb._id}>
                        <TableCell>{disb._id}</TableCell>
                        <TableCell>{disb.organization.name}</TableCell>
                        <TableCell>${disb.amount.toFixed(2)}</TableCell>
                        <TableCell>{new Date(disb.date).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No hay historial de desembolsos.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Pago */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Pago
        </Typography>
      </Grid>

      {/* Total de Pagos */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Pagos
          </Typography>
          <Typography variant="h4">{totalPayments}</Typography>
        </Paper>
      </Grid>

      {/* Pagos por Estado */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Pagos por Estado</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {paymentsByStatus.length > 0 ? (
              renderTable(
                [
                  { field: 'paymentStatus', headerName: 'Estado de Pago' },
                  { field: 'count', headerName: 'Cantidad' },
                  { field: 'totalAmount', headerName: 'Monto Total' },
                ],
                paymentsByStatus.map((pay) => ({
                  paymentStatus: pay.paymentStatus,
                  count: pay.count,
                  totalAmount: `$${pay.totalAmount.toFixed(2)}`,
                }))
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Pagos por Método */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Pagos por Método</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {paymentsByMethod.length > 0 ? (
              renderTable(
                [
                  { field: 'paymentMethod', headerName: 'Método de Pago' },
                  { field: 'count', headerName: 'Cantidad' },
                  { field: 'totalAmount', headerName: 'Monto Total' },
                ],
                paymentsByMethod.map((pay) => ({
                  paymentMethod: pay.paymentMethod,
                  count: pay.count,
                  totalAmount: `$${pay.totalAmount.toFixed(2)}`,
                }))
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Monto Total Recibido */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Monto Total Recibido
          </Typography>
          <Typography variant="h4">${totalReceivedAmount ? totalReceivedAmount.toFixed(2) : '0.00'}</Typography>
        </Paper>
      </Grid>

      {/* Pagos Fallidos */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Pagos Fallidos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {failedPayments.length > 0 ? (
              <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID de Transacción</TableCell>
                      <TableCell>Monto</TableCell>
                      <TableCell>Método de Pago</TableCell>
                      <TableCell>Fecha</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {failedPayments.map((pay) => (
                      <TableRow key={pay._id}>
                        <TableCell>{pay.transactionId}</TableCell>
                        <TableCell>${pay.amount.toFixed(2)}</TableCell>
                        <TableCell>{pay.paymentMethod}</TableCell>
                        <TableCell>{new Date(pay.createdAt).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No hay pagos fallidos.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Recogidas en Almacén */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Recogidas en Almacén
        </Typography>
      </Grid>

      {/* Total de Recogidas en Almacén */}
      <Grid item xs={12} md={6}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Total de Recogidas en Almacén
          </Typography>
          <Typography variant="h4">{totalWarehousePickups}</Typography>
        </Paper>
      </Grid>

      {/* Recogidas en Almacén por Estado */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Recogidas en Almacén por Estado</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {warehousePickupsByStatus.length > 0 ? (
              renderTable(
                [
                  { field: 'status', headerName: 'Estado' },
                  { field: 'count', headerName: 'Cantidad' },
                ],
                warehousePickupsByStatus
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Recogidas en Almacén por Fecha */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Recogidas en Almacén por Fecha</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {warehousePickupsByDate.length > 0 ? (
              renderTable(
                [
                  { field: 'period', headerName: 'Periodo' },
                  { field: 'pickupCount', headerName: 'Cantidad de Recogidas' },
                ],
                warehousePickupsByDate
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Recogidas en Almacén por Producto */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Recogidas en Almacén por Producto</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {warehousePickupsByProduct.length > 0 ? (
              renderTable(
                [
                  { field: 'productTitle', headerName: 'Producto' },
                  { field: 'pickupCount', headerName: 'Cantidad de Recogidas' },
                ],
                warehousePickupsByProduct
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Rendimiento */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Rendimiento
        </Typography>
      </Grid>

      {/* Desempeño de Bikers */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Desempeño de Bikers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {bikerPerformance.length > 0 ? (
              renderTable(
                [
                  { field: 'bikerName', headerName: 'Biker' },
                  { field: 'totalDeliveredParcels', headerName: 'Paquetes Entregados' },
                  { field: 'totalCompletedRoutes', headerName: 'Rutas Completadas' },
                  { field: 'averageCycleTimeHours', headerName: 'Tiempo Promedio de Ciclo (horas)' },
                ],
                bikerPerformance
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Desempeño de Administradores */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Desempeño de Administradores</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {adminPerformance.length > 0 ? (
              renderTable(
                [
                  { field: 'adminName', headerName: 'Administrador' },
                  { field: 'actionsCount', headerName: 'Cantidad de Acciones' },
                  // Agrega más columnas si tienes más datos
                ],
                adminPerformance
              )
            ) : (
              <Typography>No hay datos disponibles o el reporte aún no está implementado.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Crecimiento */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Crecimiento
        </Typography>
      </Grid>

      {/* Tasa de Crecimiento de Usuarios */}
      <Grid item xs={12} md={6}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Tasa de Crecimiento de Usuarios</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {userGrowthRate.length > 0 ? (
              renderTable(
                [
                  { field: 'period', headerName: 'Periodo' },
                  { field: 'userCount', headerName: 'Cantidad de Usuarios' },
                ],
                userGrowthRate
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes de Expansión */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes de Expansión
        </Typography>
      </Grid>

      {/* Cobertura Geográfica */}
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Cobertura Geográfica</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {geographicalCoverage.length > 0 ? (
              renderTable(
                [
                  { field: 'district', headerName: 'Distrito' },
                  { field: 'parcelCount', headerName: 'Cantidad de Paquetes' },
                ],
                geographicalCoverage
              )
            ) : (
              <Typography>No hay datos disponibles.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {/* Reportes Adicionales */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Reportes Adicionales
        </Typography>
      </Grid>

      {/* Tiempo de Ciclo del Pedido */}
      <Grid item xs={12} md={4}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Tiempo de Ciclo del Pedido
          </Typography>
          {orderCycleTime.averageCycleTimeDays ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>
                  Promedio: {orderCycleTime.averageCycleTimeDays} días
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Mínimo: {orderCycleTime.minCycleTimeDays} días
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Máximo: {orderCycleTime.maxCycleTimeDays} días
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography>No hay datos disponibles.</Typography>
          )}
        </Paper>
      </Grid>

      {/* Tasa de Cancelación */}
      <Grid item xs={12} md={4}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Tasa de Cancelación
          </Typography>
          {cancellationRate.parcelCancellationRate !== undefined ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>
                  Tasa de Cancelación de Paquetes: {cancellationRate.parcelCancellationRate}%
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Tasa de Cancelación de Rutas: {cancellationRate.routeCancellationRate}%
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography>No hay datos disponibles.</Typography>
          )}
        </Paper>
      </Grid>

      {/* Tasa de Repetición de Pedidos */}
      <Grid item xs={12} md={4}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Tasa de Repetición de Pedidos
          </Typography>
          {orderRepetitionRate.repetitionRate !== undefined ? (
            <Typography>
              Tasa de Repetición de Pedidos: {orderRepetitionRate.repetitionRate}%
            </Typography>
          ) : (
            <Typography>No hay datos disponibles.</Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Estadisticas;
