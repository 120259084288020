// src/components/Modals/CreateShipment/PaymentMethodSelector.js

import { FormControl, InputLabel, Select, MenuItem, Grid, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

const PaymentMethodSelector = ({ paymentMethod, handleChange, error, helperText }) => (
  <Grid item xs={12}>
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel id="payment-method-label">Método de pago</InputLabel>
      <Select
        labelId="payment-method-label"
        name="paymentMethod"
        value={paymentMethod}
        onChange={handleChange}
        label="Método de pago"
        required
      >
        <MenuItem value="">Selecciona método de pago</MenuItem>
        <MenuItem value="cash">Efectivo</MenuItem>
        <MenuItem value="sinpe_transferencia">Transferencia SINPE</MenuItem>
        <MenuItem value="otro">Otro</MenuItem>
        <MenuItem value="pagado">Pagado</MenuItem>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  </Grid>
);

PaymentMethodSelector.propTypes = {
  paymentMethod: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

PaymentMethodSelector.defaultProps = {
  error: false,
  helperText: "",
};

export default PaymentMethodSelector;
