import React, { useContext, useEffect, useState } from "react";
import { Navbar } from "../../components";
import "./Dashboard.scss";
import Cookies from "universal-cookie";
import { allPercels, percelsShipper } from "../../Apis/parcel";
import { AppContext } from "../../contextApi/AppContext";
import CarrierTable from "../../pages/dashboard/components/CarrierTable"; // Importa el componente
import ShipperTable from "../../pages/dashboard/components/ShipperTable"; // Importa el componente

const cookies = new Cookies();

const Dashboard = () => {
  const { AppData, dispatch } = useContext(AppContext);

  const fetchData = async (apiCall) => {
    try {
      const {
        data: { data },
      } = await apiCall();
      dispatch({ type: "GET_PERCELS", payload: data });
    } catch (error) {
      console.log(error.response.data.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  useEffect(() => {
    dispatch({ type: "LOADING", payload: true });
    const apiCall = cookies.get("user").isShipper ? percelsShipper : allPercels;
    fetchData(apiCall);
  }, []);

  return (
    <section className="Dashboard">
      <Navbar />
      <div className="container">
        <div className="Dashboard_content">
          {AppData.percels && AppData.percels.length ? (
            cookies.get("user").isShipper ? <ShipperTable /> : <CarrierTable />
          ) : AppData.isLoading ? (
            <p>Cargando....</p>
          ) : (
            <p className="Dashboard_content_empty">
              Click en crear nueva entrega para comenzar
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
