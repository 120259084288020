// src/pages/bikerDashboard/BikerDashboard.js

import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  Badge
} from "@mui/material";
import { FaRoute, FaClipboardList } from "react-icons/fa";
import Rutas from "./components/rutas";
import Pedidos from "./components/pedidos";
import { SocketContext } from "../../contextApi/SocketContext";
import { toast } from 'react-toastify';

const BikerDashboard = () => {
  const [activeTab, setActiveTab] = useState('rutas');
  const [newRouteAssigned, setNewRouteAssigned] = useState(false); 
  const [routeUnassigned, setRouteUnassigned] = useState(false);
  const [parcelsAssignedToBiker, setParcelsAssignedToBiker] = useState(false);
  const [newParcelAssigned, setNewParcelAssigned] = useState(false); 
  const [newParcelsAssigned, setNewParcelsAssigned] = useState(false); // Para 'admin:parcelsAssigned'
  const [refreshRoutes, setRefreshRoutes] = useState(false); 
  const [refreshPedidos, setRefreshPedidos] = useState(false); 
  const { socket, isConnected, user } = useContext(SocketContext); // Ahora incluye user

  useEffect(() => {
    console.log("BikerDashboard useEffect: socket=", socket, "isConnected=", isConnected);
    console.log("BikerDashboard: Usuario actual:", user);

    // Solicitar permiso de notificación al montar el componente
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          console.log("Permiso de notificación concedido.");
        } else {
          console.log("Permiso de notificación denegado.");
        }
      });
    }

    if (!isConnected || !socket) {
      console.warn("Socket no está disponible o no está conectado en BikerDashboard.");
      return;
    }

    // Verifica si el usuario es un mensajero y obtiene su ID
    const bikerId = user && user._id ? user._id : 'Unknown Biker ID';
    console.log(`BikerDashboard: ID del mensajero actual: ${bikerId}`);

    // Función para enviar notificaciones
    const sendNotification = (title, options) => {
      if (Notification.permission === "granted") {
        new Notification(title, options);
      }
    };

    // Escucha general de eventos para depuración
    const handleAnyEvent = (event, ...args) => {
      console.log(`Socket recibió evento: '${event}'`, args);
      // Puedes agregar más lógica aquí si lo deseas
    };

    // Registra la escucha general
    socket.onAny(handleAnyEvent);
    console.log("BikerDashboard: Escucha general de eventos registrada.");

    // Define los eventos específicos que el componente escuchará
    const handleSocketEvents = [
      { 
        event: "biker:routeAssigned", 
        handler: (data) => { 
          console.log("Evento 'biker:routeAssigned' recibido.", data);
          toast.info("Nueva ruta asignada."); 
          sendNotification("Nueva ruta asignada", {
            body: "Se te ha asignado una nueva ruta.",
            icon: "/icono-notificacion.png", // Reemplaza con la ruta a tu icono
          });
          setNewRouteAssigned(true); 
          setRefreshRoutes(prev => !prev); 
        } 
      },
      { 
        event: "biker:routeUnassigned", 
        handler: (data) => { 
          console.log("Evento 'biker:routeUnassigned' recibido.", data);
          toast.warn("Ruta desasignada."); 
          sendNotification("Ruta desasignada", {
            body: "Se ha reasignado o eliminado una de tus rutas.",
            icon: "/icono-notificacion.png",
          });
          setRouteUnassigned(true); 
          setRefreshRoutes(prev => !prev); 
        } 
      },
      { 
        event: "biker:parcelsAssigned", 
        handler: (data) => { 
          console.log("Evento 'biker:parcelsAssigned' recibido.", data);
          toast.info("Nuevos paquetes asignados."); 
          sendNotification("Nuevos paquetes asignados", {
            body: "Se te han asignado nuevos paquetes en una ruta.",
            icon: "/icono-notificacion.png",
          });
          setParcelsAssignedToBiker(true); 
          setRefreshPedidos(prev => !prev); 
        } 
      },
      { 
        event: "biker:parcelAssigned", 
        handler: (data) => { 
          console.log("Evento 'biker:parcelAssigned' recibido.", data);
          toast.info("Nuevo paquete asignado."); 
          sendNotification("Nuevo paquete asignado", {
            body: "Se te ha asignado un nuevo paquete en una ruta.",
            icon: "/icono-notificacion.png",
          });
          setNewParcelAssigned(true); 
          setRefreshPedidos(prev => !prev); 
          console.log(`BikerDashboard: Paquete asignado al mensajero ID: ${bikerId}`);
        } 
      },
      { 
        event: "admin:parcelsAssigned", 
        handler: (data) => { 
          console.log("Evento 'admin:parcelsAssigned' recibido.", data);
          // Verificar si la ruta está asignada al biker actual
          if (data.route && data.route.biker && data.route.biker._id === bikerId) {
            toast.info("Nuevos paquetes asignados a tu ruta.");
            sendNotification("Nuevos paquetes asignados", {
              body: "Se te han asignado nuevos paquetes en una ruta.",
              icon: "/icono-notificacion.png",
            });
            setNewParcelsAssigned(true); 
            setRefreshPedidos(prev => !prev); 
          } else {
            console.log("Evento 'admin:parcelsAssigned' no relevante para el mensajero actual.");
          }
        } 
      },
      { 
        event: "admin:parcelAssigned", 
        handler: (data) => { 
          console.log("Evento 'admin:parcelAssigned' recibido.", data);
          // Verificar si la ruta está asignada al biker actual
          if (data.route && data.route.biker && data.route.biker._id === bikerId) {
            toast.info("Nuevo paquete asignado a tu ruta.");
            sendNotification("Nuevo paquete asignado", {
              body: "Se te ha asignado un nuevo paquete en una ruta.",
              icon: "/icono-notificacion.png",
            });
            setNewParcelAssigned(true); 
            setRefreshPedidos(prev => !prev); 
          } else {
            console.log("Evento 'admin:parcelAssigned' no relevante para el mensajero actual.");
          }
        } 
      },
      // Puedes agregar más eventos aquí siguiendo el mismo patrón
    ];

    console.log("BikerDashboard: Registrando eventos específicos de Socket.io");

    // Registra cada evento con su respectivo manejador
    handleSocketEvents.forEach(({ event, handler }) => {
      socket.on(event, handler);
      console.log(`BikerDashboard: Evento '${event}' registrado para el mensajero ID: ${bikerId}`);
    });

    // Limpia los eventos al desmontar el componente
    return () => {
      console.log("BikerDashboard: Desregistrando eventos específicos de Socket.io");
      handleSocketEvents.forEach(({ event, handler }) => {
        socket.off(event, handler);
        console.log(`BikerDashboard: Evento '${event}' desregistrado para el mensajero ID: ${bikerId}`);
      });
      // Limpia la escucha general
      socket.offAny(handleAnyEvent);
      console.log("BikerDashboard: Escucha general de eventos desregistrada.");
    };
  }, [socket, isConnected, user]);

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
    console.log(`BikerDashboard: Cambiando a la pestaña '${newValue}'`);
    if (newValue === 'rutas') {
      setNewRouteAssigned(false); 
      setRouteUnassigned(false);
      setParcelsAssignedToBiker(false);
      setNewParcelsAssigned(false);
      console.log("BikerDashboard: Reseteando 'newRouteAssigned', 'routeUnassigned', 'parcelsAssignedToBiker' y 'newParcelsAssigned' a false");
    } else if (newValue === 'pedidos') {
      setNewParcelAssigned(false);
      setNewParcelsAssigned(false);
      console.log("BikerDashboard: Reseteando 'newParcelAssigned' y 'newParcelsAssigned' a false");
    }
  };

  if (!isConnected || !socket) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Conectando al servidor de sockets...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <AppBar position="static" sx={{ backgroundColor: "#003366" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ color: "#ffffff" }}>
            Panel de Mensajeros
          </Typography>
          <IconButton sx={{ marginLeft: "auto" }} aria-label="logout">
            <FaClipboardList style={{ color: "#ffffff" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          marginBottom: 2,
          backgroundColor: "#f5f5f5",
          '& .MuiTab-root': {
            minHeight: '48px',
            fontSize: '1rem',
            color: "#333",
          },
          '& .Mui-selected': {
            color: "#003366",
            fontWeight: "bold",
          },
        }}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label={
            <>
              <FaRoute style={{ marginRight: "5px" }} />
              Rutas 
              {newRouteAssigned && <Badge color="success" variant="dot" sx={{ ml: 1 }} />}
              {routeUnassigned && <Badge color="error" variant="dot" sx={{ ml: 1 }} />}
              {parcelsAssignedToBiker && <Badge color="info" variant="dot" sx={{ ml: 1 }} />}
            </>
          }
          value="rutas"
        />
        <Tab
          label={
            <>
              <FaClipboardList style={{ marginRight: "5px" }} />
              Pedidos 
              {newParcelAssigned && <Badge color="success" variant="dot" sx={{ ml: 1 }} />}
              {newParcelsAssigned && <Badge color="info" variant="dot" sx={{ ml: 1 }} />}
            </>
          }
          value="pedidos"
        />
      </Tabs>

      <Box
        sx={{
          padding: 3,
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {activeTab === 'rutas' && <Rutas refresh={refreshRoutes} />}
        {activeTab === 'pedidos' && <Pedidos refresh={refreshPedidos} />}
      </Box>
    </Box>
  );
};

export default BikerDashboard;
